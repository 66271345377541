import React from "react";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import LoadingSpinner from "../LoadingSpinner";
import JobHai from "../../api/JobHai";
import PopupMessage from "../PopupMessage";
import JobSeekerSearch from "./JobSeekerSearch";
import CustomePagination from "../Common/Pagination";
import {
  getErrorMessage,
  checkPermission,
  getSubStr
} from "../../services/utils";
import { getCrmMetaData } from "../../services/metadataApiServices";

function AppliedJobsLink(props) {
  return (
    <Link
      to={"/job-seeker/applied/" + props.uid}
      className="primary"
      rel="noopener"
      target="_blank"
      title={props.no_applied + " - Action"}
    >
      {props.no_applied}
    </Link>
  );
}

function UserActionsLink(props) {
  const { uid, resume = "", audio = "" } = props;
  return (
    <div>
      <Link
        to={`/job-seeker/edit/${uid}`}
        className="link"
        rel="noopener noreferrer"
        target="_blank"
        title={" Edit User"}
      >
        <i className="fas fa-pencil-alt" />
      </Link>
      {resume ? (
        <a
          href={resume}
          className="link"
          rel="noopener noreferrer"
          target="_blank"
          download
          title={" Download Resume"}
        >
          <i className="fas fa-file-download" />
        </a>
      ) : (
        <i className="fas fa-file-download inactive" />
      )}
      {audio ? (
        <a
          href={audio}
          className="link"
          rel="noopener noreferrer"
          target="_blank"
          download
          title={" Download This Audio"}
        >
          <i className="fas fa-volume-up" />
        </a>
      ) : (
        <i className="fas fa-volume-up" />
      )}
    </div>
  );
}

class JobSeeker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      job_seeker: [],
      isLoading: true,
      isButtonDisabled: false,
      categories: [],
      msgData: { msg: "", showMsg: false, msgType: "error" },
      query: "",
      activePage: 1,
      count: 0,
      itemsCountPerPage: 50,
      filters: {},
      showJS: true
    };
  }

  handleClosePopup = () => {
    this.setState({ msgData: [{ msg: "", showMsg: false }] });
  };

  statusMessage = (msg, msgType) => {
    this.setState({
      msgData: {
        msg: msg,
        showMsg: true,
        msgType: !msgType ? "success" : "error"
      }
    });
    window.scrollTo(0, 0);
  };

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
  };

  searchJobSeeker = objData => {
    this.setState({
      job_seeker: [],
      isButtonDisabled: true,
      filters: objData,
      activePage: 1,
      query: "",
      msgData: {
        msg: "",
        showMsg: false,
        msgType: ""
      }
    });
  };

  getJobSeeker = () => {
    const { activePage, itemsCountPerPage, filters } = this.state;
    let queryString = Object.keys(filters)
      .map(key => key + "=" + filters[key])
      .join("&");
    let url = `/job_seeker?page=${activePage - 1}&size=${itemsCountPerPage}`;
    if (queryString) url = `${url}&${queryString}`;

    JobHai.get(url)
      .then(response => {
        let data = response?.data?.data || [];
        let query = response?.data?.query || "";
        this.setState({
          job_seeker: data,
          count: response.data.count,
          isButtonDisabled: false,
          query: queryString ? query : "",
          isLoading: false,
          msgData: { msg: "", showMsg: false, msgType: "error" }
        });
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setState({
          isButtonDisabled: false,
          isLoading: false,
          msgData: { msg: msg, showMsg: true, msgType: "error" }
        });
        window.scrollTo(0, 0);
      });
  };

  async componentDidMount() {
    const showJS = checkPermission("Job Seeker");
    if (showJS) this.getJobSeeker();
    else {
      this.setState({
        showJS,
        isLoading: showJS
      });
    }
    this.getCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    const { filters, activePage } = this.state;
    if (filters !== prevState.filters) {
      this.setState({
        filters: filters
      });
      this.getJobSeeker();
    }
    if (activePage !== prevState.activePage) {
      this.setState({
        activePage: activePage
      });
      this.getJobSeeker();
      window.scroll({ top: 100, behavior: "smooth" });
    }
  }

  getCategories = async () => {
    try {
      const response = await getCrmMetaData();
      const { categories = [] } = response;
      this.setState({
        categoriesList: categories
      });
    } catch (error) {
      const msg = getErrorMessage(error);
      this.setState({
        isLoading: false,
        msgData: { msg: msg, showMsg: true, msgType: "error" }
      });
    }
  };

  render() {
    const {
      job_seeker,
      query,
      isButtonDisabled,

      isLoading,
      msgData,
      activePage,
      itemsCountPerPage,
      count,
      showJS,
      categoriesList
    } = this.state;
    job_seeker.map(d => {
      const { no_applied, user_id, resume, audio_url = "", name = "" } = d;
      d.jsName = getSubStr(name);
      d.applied = <AppliedJobsLink uid={user_id} no_applied={no_applied} />;
      d.editUser = (
        <UserActionsLink uid={user_id} resume={resume} audio={audio_url} />
      );
      return d;
    });

    const columns = [
      {
        text: "USER ID",
        dataField: "user_id",
        sort: true
      },
      {
        text: "NAME",
        dataField: "jsName",
        sort: true
      },
      {
        text: "PHONE",
        dataField: "phone",
        sort: true
      },
      {
        text: "EMAIL",
        dataField: "email",
        sort: true
      },
      {
        text: "Location",
        dataField: "locality",
        sort: true
      },
      {
        text: "ACTIONS ON JOBS",
        dataField: "applied",
        sort: true
      },
      {
        text: "Total Reports by JS",
        dataField: "no_reported",
        sort: true
      },
      {
        text: "Data Carrier",
        dataField: "mobile_data_carrier",
        sort: true
      },
      {
        text: "Manufacturer",
        dataField: "mobile_manufacture",
        sort: true
      },
      {
        text: "Model",
        dataField: "mobile_model",
        sort: true
      },
      {
        text: "Android OS",
        dataField: "android_os",
        sort: true
      },
      {
        text: "App Version",
        dataField: "app_version",
        sort: true
      },

      {
        text: "",
        dataField: "editUser"
      }
    ];

    const NoDataIndication = () => <div className="spinner">No Data Found</div>;

    if (isLoading) {
      return <LoadingSpinner />;
    } else {
      return (
        <div className="job_seeker">
          <div className="gap-20" />
          <PopupMessage data={msgData} click={this.handleClosePopup} />
          <div className="pageHeading row">
            <h2>Job Seeker</h2>
          </div>
          <div className="clr" />
          <div className="gap-20" />{" "}
          {!showJS ? (
            <div className="whiteCard">
              <div className="container-fluid" style={{ textAlign: "center" }}>
                <span className="alert alert-danger" role="alert">
                  <i
                    className="fa fa-exclamation-triangle"
                    aria-hidden="true"
                  />
                  You don't have access to view this page
                </span>
              </div>
            </div>
          ) : null}
          {showJS ? (
            <>
              <JobSeekerSearch
                isButtonDisabled={isButtonDisabled}
                categories={categoriesList}
                query={query}
                statusMessage={this.statusMessage}
                searchJobSeeker={this.searchJobSeeker}
              />

              <div className="clr gap-20" />
              <div className="whitebox scrollX table-responsive">
                <BootstrapTable
                  key="seeker"
                  keyField="user_id"
                  data={job_seeker}
                  columns={columns}
                  hover
                  condensed
                  bordered={false}
                  loading={true}
                  rowClasses="listvalue"
                  headerClasses="listHeding"
                  classes="borderless table"
                  noDataIndication={() => <NoDataIndication />}
                />
                <CustomePagination
                  activePage={activePage}
                  itemsCountPerPage={itemsCountPerPage}
                  totalItemsCount={count}
                  onPageChange={this.handlePageChange}
                  showPagination={count > 0 ? true : false}
                  showLimitedRow={true}
                />
                <div className="clr gap-20" />
              </div>
            </>
          ) : null}
        </div>
      );
    }
  }
}
export default JobSeeker;
