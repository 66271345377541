import React, { useState, useEffect } from "react";
import withRouter from "../../services/withRouter";
import LoadingSpinner from "../LoadingSpinner";
import JobHai from "../../api/JobHai";
import PopupMessage from "../PopupMessage";
import MiniSearch from "./MiniSearch";
import CrawledJobs from "./CrawledJobs";
import AppJobList from "./AppJobList";
import OrganicJobTab from "../Common/OrganicJobTab";
import CrawledJobTab from "./Crawled/CrawledJobTab";
import { getErrorMessage } from "../../services/utils";
import TatSummary from "../TatSummary";
import ProxyModeratorName from "../Common/ProxyModeratorName";

const JobListContainer = ({ params = {} }) => {
  const { page = "organic", role = "QA" } = params;

  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [msgData, setMsgData] = useState({
    msg: "",
    showMsg: false,
    msgType: "error"
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [repeatingRecruiter, setRepeatingRecruiter] = useState("");
  let jobApprovalStatus = role === "QA" ? "VERIFIED" : "NEW";
  if (page === "edited") {
    jobApprovalStatus = "";
  }
  const [filters, setFilters] = useState({
    ...(page !== "edited" && { job_approval_status: jobApprovalStatus }),
    job_live_status: role === "QA" ? "READY_FOR_QA" : "NEW"
  });
  const [isReviewedCompany, setIsReviewedCompany] = useState(true);
  const itemsCountPerPage = 50;

  const handleClosePopup = () => {
    setMsgData({ msg: "", showMsg: false });
  };

  useEffect(() => {
    getJobs();
  }, [filters, activePage, isReviewedCompany]);

  const getJobs = () => {
    let statusFilter = {};
    statusFilter = { ...filters };
    let querStrArr = filters;
    const queryString = Object.keys(querStrArr)
      .map(key => `${key}=${querStrArr[key]}`)
      .filter(el => el != null)
      .join("&");

    const type =
      page === "crawled"
        ? "NAUKRI"
        : page === "agent"
        ? "AGENT"
        : page === "edited"
        ? "EDITED_JOBS"
        : "ORGANIC";

    let url = `/v3/jobs?type=${type}&page=${
      activePage - 1
    }&size=${itemsCountPerPage}`;
    if (role) url = `${url}&role=${role}`;
    if (page === "organic")
      url = `${url}&isReviewedCompany=${isReviewedCompany}`;
    url = queryString ? `${url}&${queryString}` : url;

    JobHai.post(url, {
      filters: statusFilter,
      page: activePage - 1,
      size: itemsCountPerPage
    })
      .then(response => {
        const { data } = response.data;
        const { job, count } = data;
        setJobs(job);
        setCount(count);
        setIsLoading(false);
        setIsButtonDisabled(false);
      })
      .catch(error => {
        const msg = getErrorMessage(error);
        setMsgData({ msg, showMsg: true, msgType: "error" });
        setIsLoading(false);
        setIsButtonDisabled(false);
        setCount(0);
      });
  };

  const statusMessage = (msg, msgType) => {
    setMsgData({
      msg,
      showMsg: true,
      msgType: msgType ? "error" : "success"
    });
    window.scrollTo(0, 0);
  };

  const searchData = objData => {
    setJobs([]);
    setFilters(objData);
    setRepeatingRecruiter(objData.repeating_recruiter || "");
    setIsButtonDisabled(true);
    setActivePage(1);
    setMsgData({ msg: "", showMsg: false, msgType: "" });
  };

  const handleRepeatRecruiter = () => {
    searchData({ ...filters, repeating_recruiter: 1 });
    setRepeatingRecruiter(1);
  };

  const handleReviewdTab = tab => {
    setIsReviewedCompany(tab);
    setActivePage(1);
  };

  const pageTitle = page.charAt(0).toUpperCase() + page.slice(1);
  const showTat = page === "organic";

  if (isLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <ProxyModeratorName />
        <PopupMessage data={msgData} click={handleClosePopup} />
        <div className="admin-pannel">
          <div className="clr gap-20" />
          <div className="pageHeading row">
            <h2>
              {pageTitle} Jobs
              {page === "organic" ? (
                <OrganicJobTab active="verified" role={role} />
              ) : null}
              {page === "crawled" ? (
                <CrawledJobTab active="jobs" role={role} />
              ) : null}
              <div className="float-right">
                <a href={"/jobs/"}>Go Back</a>
              </div>
            </h2>
          </div>
          {page === "organic" ? (
            <div className="tabs">
              <div className="tab-list">
                <span
                  className={`unClickedChip ${
                    !isReviewedCompany ? "clickedChip" : ""
                  }`}
                  onClick={() => handleReviewdTab(false)}
                >
                  Non-Reviewed Companies
                </span>
                <span
                  className={`unClickedChip ${
                    isReviewedCompany ? "clickedChip" : ""
                  }`}
                  onClick={() => handleReviewdTab(true)}
                >
                  Reviewed Companies
                </span>
              </div>
            </div>
          ) : null}
          <div className="clr gap-20" />
          <MiniSearch
            statusMessage={statusMessage}
            searchData={searchData}
            isButtonDisabled={isButtonDisabled}
            page={page}
            role={role}
            repeatingRecruiter={repeatingRecruiter}
          />
          {showTat ? <TatSummary type="verified" /> : null}
          {page === "crawled" ? (
            <CrawledJobs
              jobs={jobs}
              role={role}
              page={page}
              activePage={activePage}
              itemsCountPerPage={itemsCountPerPage}
              totalItemsCount={count}
              onPageChange={setActivePage}
            />
          ) : (
            <AppJobList
              jobs={jobs}
              role={role}
              page={page}
              activePage={activePage}
              itemsCountPerPage={itemsCountPerPage}
              totalItemsCount={count}
              onPageChange={setActivePage}
              handleSearch={handleRepeatRecruiter}
              isSearching={isButtonDisabled}
              showRecruiterTab={isReviewedCompany}
            />
          )}
        </div>
      </>
    );
  }
};

export default withRouter(JobListContainer);
