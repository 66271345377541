import React from "react";
import MetaData from "../../../constants/MetaData";

const AdminPannelTabs = props => {
  const { handleClick, isSelected, isNewCity } = props;
  let tabList = MetaData.adminPannelTabsList.filter(item => !item.is_disabled);
  if (isNewCity) {
    tabList = MetaData.adminPannelTabsList.filter(item => item.newCity);
  }
  return (
    <div className="tabs">
      <div className="tab-list">
        {tabList.map(item => {
          const { is_disabled, id, title } = item;
          return (
            <span
              className={
                is_disabled
                  ? "unClickedChip disabledChip"
                  : id === isSelected
                  ? "unClickedChip clickedChip"
                  : "unClickedChip"
              }
              onClick={() => handleClick(id)}
              key={id}
            >
              {title}
            </span>
          );
        })}
      </div>
      <div className="label">Moderator Volume Distribution</div>
    </div>
  );
};
export default AdminPannelTabs;
