import React, { useRef } from "react";
import Select from "react-select";
import { useController } from "react-hook-form";
import { FormControl, FormHelperText } from "@material-ui/core";
import LcsFormErrorHook from "../../customHooks/LcsFormErrorHook";

const SelectDropdown = ({
  name,
  label,
  control,
  options,
  labelKey = "label",
  idKey = "_id",
  validateArray,
  error,
  customClass,
  helpText,
  fullWidth = true,
  callBack,
  defaultValue,
  rules = {},
  ...rest
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules: {
      ...rules,
      validate: validateArray
    },
    defaultValue
  });
  const inputRef = useRef(null);
  LcsFormErrorHook({
    fieldState,
    inputRef
  });

  return (
    <FormControl
      fullWidth={fullWidth}
      error={fieldState.error}
      classes={{ root: customClass }}
      innerRef={inputRef}
    >
      <label htmlFor={name}>{label}</label>
      <Select
        id={name}
        name={name}
        value={field.value}
        onChange={options => {
          let selectedOptions = options;
          if (callBack) selectedOptions = callBack(options);
          field.onChange(selectedOptions);
        }}
        options={options}
        getOptionLabel={option => option[labelKey]}
        getOptionValue={option => option[idKey]}
        {...rest}
      />
      {fieldState.error && (
        <FormHelperText>{fieldState.error.message}</FormHelperText>
      )}
      {helpText ? (
        <FormHelperText error={false}>{helpText}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default SelectDropdown;
