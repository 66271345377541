import React from "react";
import moment from "moment";
import {
  getErrorMessage,
  replaceTagWithLineBreak,
  setResponse,
  getResponse,
  setObjToNull,
  isRecruiterVerified,
  getOtherMethodInfo,
  isReloadRfd,
  isEmpty,
  getCustomErrorMessage,
  callPremiumSaveOnStateVsProp,
  getIntent,
  checkFreeTextValidation
} from "../../services/utils";
import JobHai from "../../api/JobHai";
import LoadingSpinner from "../LoadingSpinner";
import JobDetails from "./JobDetails";
import SaveChanges from "./SaveChanges";
import JobCalling from "./JobCalling";
import ViewLogs from "./ViewLogs";
import RightSideBar from "./RightSideBar";
import InterviewDetails from "./InterviewDetails";
import JobLiveDate from "./JobLiveDate";
import EditedFieldList from "./EditedFieldList";
import { setLocalStorage, getLocalStorage } from "../../services/localStorage";

class JobVerification extends React.Component {
  state = {
    saveJobClicked: false,
    isLoading: true,
    isConsultant: false,
    isRecruiterVerified: false,
    editedFields: [],
    jobData: [],
    otherJobList: [],
    isSaveDisabled: false,
    isLeftSaved: false,
    isRightSaved: false,
    recName: "",
    recPhone: "",
    recEmail: "",
    isRecEmailVerified: false,
    isRecruiterOnboarded: false,
    errorMessage: "",
    makeJobliveDirectly: false,
    onboardingMethod: "",
    documentType: "",
    rfdFields: {},
    categoryId: "",
    isDifferentAddress: false,
    premiumProductIntent: null,
    initialJobApprovalStatus: "",
    initialRejectedReasonId: "",
    isNewCity: false,
    isPartOfCreditSystem: false,
    jobPostFields: {
      title: { visible: true },
      categories: { visible: true },
      no_of_openings: { visible: true },
      city: { visible: true },
      locality: { visible: true },
      gender: { visible: true, options: ["Any", "Male", "Female"] },
      company_perks_text: { visible: true },
      job_type: {
        visible: true,
        options: ["Full Time", "Part Time"]
      },
      job_timings: { visible: false },
      working_days: { visible: false },
      shift_type: { visible: false },
      skills: { visible: false },
      plus_incentives: { visible: false },
      minimum_qualification: { visible: false },
      company_perks: { visible: false },
      assets_v2: { visible: false },
      english_fluency: { visible: false },
      is_wfh: { visible: true },
      is_contract: { visible: true },
      regional_languages: { visible: false },
      is_additional_fee_applicable: { visible: false },
      reason: { visible: false },
      amount: { visible: false },
      english_fluency_job_posting_form: { visible: false },
      is_per_packet_based: { visible: false },
      is_target_based: { visible: false },
      sales_type: { visible: false },
      prior_experience_type: { visible: false }
    }
  };

  componentDidMount() {
    const { jobId } = this.props;
    const data = getResponse(jobId);
    if (data && data.job_id) {
      const { job_approval_status = "", job_reject_reason_id = "" } = data;
      this.setState({
        initialJobApprovalStatus: job_approval_status,
        initialRejectedReasonId: job_reject_reason_id
      });
      this.setInitialState(data);
    } else this.getJobDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    const { categoryId } = this.state;
    if (categoryId !== prevState.categoryId && categoryId) {
      this.getjobPostFields(categoryId);
    }
  }

  setInitialState = (responseData, afterSave = false) => {
    const { jobId, handleJobDetails } = this.props;
    setResponse(jobId, responseData);

    const {
      moderator_name = "",
      agent_phone = "NA",
      agent_name = "NA",
      other_jobs = [],
      edited_field = [],
      AppRecruiter = {},
      no_of_openings,
      category_id,
      job_approval_status,
      recruiter_edited_field = [],
      can_make_joblive_directly = false,
      job_live_status,
      job_reject_reason_id,
      is_additional_fee_applicable,
      recruiter_status = "",
      recruiter_onboarding_method = "",
      job_contact = "",
      minSal = "",
      maxSal = "",
      job_skills_text = [],
      company_perks_text = [],
      prior_experience_type = [],
      posted_jobs_in_new_city_launch = false,
      reason,
      deposit_reason_id,
      deposit_time_id
    } = responseData;

    const {
      company_id,
      is_consultant = false,
      AppCompany = {},
      full_name = "",
      phone = "",
      email = "",
      is_email_verified = false,
      is_part_of_credit_system = false
    } = AppRecruiter;
    const {
      clients = [],
      document_type = "",
      city_id = "",
      locality_id = ""
    } = AppCompany;
    const isRecEdited = recruiter_edited_field.length;
    const jobEdited = edited_field.length;
    const jobData = responseData || [];
    let hiring_end_date = new Date(jobData.hiring_end_date);
    let hiring_start_date = new Date(jobData.hiring_start_date);

    if (!jobData.hiring_start_date) hiring_start_date = new Date();

    if (!jobData.hiring_end_date) {
      hiring_end_date = new Date();
    }
    const isDifferentAddress =
      jobData.job_city_id !== city_id ||
      jobData.job_locality_id !== locality_id;
    delete jobData.bounce_reason;
    delete jobData.is_email_bounced;
    delete jobData.recuiter_valid_report_count_objs;
    delete jobData.auto_job_live_reject_reasons;
    delete jobData.tags_copy_attempted;
    delete jobData.source_url;
    delete jobData.source_id;
    delete jobData.is_urgent_requirement;
    delete jobData.allow_job_without_document;
    delete jobData.tagging;
    delete jobData.expiry_date;
    delete jobData.walkin_date_to;
    delete jobData.walkin_date_from;
    delete jobData.closing_date;
    delete jobData.interview_start_date;
    delete jobData.interview_end_date;
    delete jobData.called_by;
    delete jobData.cleaned_by;
    delete jobData.recruiter_state_at_live;
    delete jobData.interview_city_id;
    delete jobData.interview_locality_id;
    delete jobData.interview_address_lat;
    delete jobData.interview_address_lng;
    delete jobData.interview_end_time;
    delete jobData.interview_start_time;

    delete jobData.crm_status_updated_at;
    delete jobData.is_walkin;

    jobData.company_id = company_id;
    jobData.hiring_start_date = hiring_start_date;
    jobData.job_skills_text = Array.isArray(job_skills_text)
      ? job_skills_text
      : [];
    jobData.company_perks_text = Array.isArray(company_perks_text)
      ? company_perks_text
      : [];
    jobData.prior_experience_type = Array.isArray(prior_experience_type)
      ? prior_experience_type
      : [];
    jobData.maxExp = jobData.maxExp > 72 ? 73 : jobData.maxExp;
    jobData.jobApprovalStatus = job_approval_status;
    jobData.jobRejectedReason = jobData.jobRejectedReason
      ? jobData.jobRejectedReason
      : job_reject_reason_id;
    jobData.isAadditionalFeeApplicable = is_additional_fee_applicable
      ? "yes"
      : "no";
    jobData.reason = reason;
    jobData.deposit_reason_id = deposit_reason_id;
    jobData.deposit_time_id = deposit_time_id;

    handleJobDetails({
      recruiterStatus: recruiter_status,
      other_jobs,
      isRecEdited,
      jobEdited
    }); // This function is set to update info in parent component
    const enableMakeLiveJob =
      can_make_joblive_directly && job_live_status === "READY_FOR_QA";
    this.setState({
      isLoading: false,
      jobData,
      isConsultant: is_consultant,
      clientList: clients,
      agent_name: agent_name ? agent_name : "NA",
      agent_phone: agent_phone ? agent_phone : "NA",
      moderatorName: moderator_name,
      editedFields: edited_field,
      isRecruiterVerified: isRecruiterVerified(recruiter_status),
      otherJobList: other_jobs,
      recName: full_name ? full_name : "NA",
      recPhone: phone ? phone : "00000000000",
      recEmail: email,
      isRecEmailVerified: is_email_verified,
      makeJobliveDirectly: can_make_joblive_directly,
      onboardingMethod: recruiter_onboarding_method.length
        ? recruiter_onboarding_method[0]
        : recruiter_onboarding_method,
      documentType: document_type,
      rfdFields: {
        job_contact,
        no_of_openings,
        minSal,
        maxSal
      },
      categoryId: category_id,
      isDifferentAddress,
      premiumProductIntent: getIntent(jobData?.interested_in_premium_offering),
      isNewCity: posted_jobs_in_new_city_launch,
      isPartOfCreditSystem: is_part_of_credit_system
    });
    if (!afterSave) {
      this.setState({ isRightSaved: !enableMakeLiveJob });
    }
  };

  updateMessage = (msg, flag) => {
    this.props.statusMessage(msg, flag);
  };

  getJobDetails = () => {
    const { jobId, recId } = this.props;
    JobHai.post(`/job-details`, {
      job_id: jobId,
      recruiter_id: recId
    })
      .then(response => {
        let responseData = response.data.data;
        const { job_approval_status = "", job_reject_reason_id = "" } =
          responseData;
        this.setInitialState(responseData);
        this.setState({
          initialJobApprovalStatus: job_approval_status,
          initialRejectedReasonId: job_reject_reason_id
        });
      })
      .catch(error => {
        let errorMessage = "";
        errorMessage = getErrorMessage(error);
        this.updateMessage(errorMessage, 1);
        this.setState({
          isLoading: false
        });
      });
  };

  getjobPostFields = categoryId => {
    const jobPostFieldKey = `jobPostFieldV5_${categoryId}`;
    let jobPostFields = JSON.parse(getLocalStorage(jobPostFieldKey));
    if (jobPostFields) {
      this.setState({
        jobPostFields
      });
    } else {
      const quetStr = categoryId ? `?category_id=${categoryId}` : "";
      JobHai.get(`../v2/metadata/job_post_fields${quetStr}`)
        .then(response => {
          const { job_post_fields = {} } = response.data.data;
          this.setState({
            jobPostFields: job_post_fields
          });
          setLocalStorage(jobPostFieldKey, JSON.stringify(job_post_fields));
        })
        .catch(error => {
          let msg = getErrorMessage(error);
          this.setState({
            isLoading: false,
            msgData: { msg: msg, showMsg: true, msgType: "error" }
          });
        });
    }
  };

  getFieldData = (fieldName, value) => {
    const { isLeftSaved, jobData, isRightSaved, categoryId } = this.state;
    let tempjobData = jobData;
    if (fieldName === "no_of_openings" && value) {
      var no_of_openings = Number(value);
      value = no_of_openings;
    } else if (fieldName === "category_id") {
      tempjobData.v2_job_skills = [];
      tempjobData.suggested_jds = [];
      tempjobData.job_perks = [];
      tempjobData.assets_v2 = [];
      tempjobData.other_perks = "";
      tempjobData.prior_experience_type = [];
      if (categoryId !== value) this.getjobPostFields(value);
    }
    if (fieldName === "jobApprovalStatus" && value !== "REJECTED") {
      tempjobData.jobRejectedReason = null;
    }
    if (fieldName === "jobApprovalStatus") {
      tempjobData.job_approval_status = value;
    }

    tempjobData[fieldName] = value;

    this.setState({ jobData: tempjobData }, () =>
      this.updateLocalStorage(tempjobData)
    );
    if (isLeftSaved && fieldName !== "jobApprovalStatus") {
      if (fieldName !== "jobRejectedReason")
        this.setState({ isLeftSaved: false });
    }
    if (
      isLeftSaved &&
      fieldName === "jobApprovalStatus" &&
      value === "PENDING"
    ) {
      this.setState({ isLeftSaved: false });
    }
    if (
      isRightSaved &&
      fieldName === "jobApprovalStatus" &&
      value !== "PENDING"
    ) {
      this.setState({ isRightSaved: false });
    }
  };

  updateLocalStorage = data => {
    const { jobId } = this.props;
    setResponse(jobId, data);
  };

  setErrorMessage = errorMessage => {
    this.setState({ errorMessage });
  };

  handleFormValidation = () => {
    const { jobPostFields, jobData, isConsultant } = this.state;
    const objJobData = jobData;
    const {
      category_id,
      are_skills_optional = true,
      job_start_time,
      job_end_time,
      company_name,
      isAadditionalFeeApplicable = ""
    } = jobData;

    /* Validation */

    if (!objJobData.job_title) {
      this.setErrorMessage("Please enter job title");
      return true;
    }
    if (!category_id) {
      this.setErrorMessage("Please select job category");
      return true;
    }
    if (
      (!objJobData.job_contact || objJobData.job_contact.length !== 10) &&
      objJobData.call_allowed
    ) {
      this.setErrorMessage("Please enter a valid job contact number");
      return true;
    }
    // const peType =
    //   !objJobData.prior_experience_type ||
    //   !objJobData.prior_experience_type.length;
    // if (peType && jobPostFields.prior_experience_type.visible) {
    //   this.setErrorMessage(
    //     `Please select ${jobPostFields.prior_experience_type.type_label} type`
    //   );
    //   return true;
    // }
    if (!objJobData.no_of_openings) {
      this.setErrorMessage("Please enter number of openings");
      return true;
    }

    if (!objJobData.job_type && jobPostFields.job_type.visible) {
      this.setErrorMessage("Please select job type");
      return true;
    }
    if (
      !objJobData.minimum_qualification &&
      jobPostFields.minimum_qualification.visible
    ) {
      this.setErrorMessage("Please select minimum qualification");
      return true;
    }
    if (!objJobData.gender_req) {
      this.setErrorMessage("Please select gender");
      return true;
    }

    if (!objJobData.is_fresher_allowed) {
      if (!objJobData.minExp) {
        this.setErrorMessage("Please select min experience");
        return true;
      }
      if (!objJobData.maxExp || Number(objJobData.maxExp) === 0) {
        this.setErrorMessage("Please select max experience");
        return true;
      }
    }

    if (Number(objJobData.minSal) < 999) {
      this.setErrorMessage(
        "Minimum salary should be more than or equal to ₹999"
      );
      return true;
    }
    if (String(objJobData.maxSal).length < 4) {
      this.setErrorMessage("Please enter valid max salary");
      return true;
    }
    if (Number(objJobData.maxSal) < Number(objJobData.minSal)) {
      this.setErrorMessage(
        "Max salary should be greater than or equal to min salary"
      );
      return true;
    }

    if (
      objJobData.working_days_text &&
      !checkFreeTextValidation(objJobData.working_days_text) &&
      jobPostFields.working_days.visible
    ) {
      this.setErrorMessage("Please enter valid working days");
      return true;
    }
    if (
      objJobData.working_days === "Others" &&
      !objJobData.working_days_text &&
      jobPostFields.working_days.visible
    ) {
      this.setErrorMessage("Please select working days");
      return true;
    }
    if (objJobData.working_days === "" && jobPostFields.working_days.visible) {
      this.setErrorMessage("Please select working days");
      return true;
    }
    if (!job_start_time && jobPostFields.job_timings.visible) {
      this.setErrorMessage("Please select job timing from");
      return true;
    }
    if (!job_end_time && jobPostFields.job_timings.visible) {
      this.setErrorMessage("Please select job timing to");
      return true;
    }

    // if (
    //   !objJobData.prior_experience_type?.length &&
    //   jobPostFields.prior_experience_type.visible
    // ) {
    //   this.setErrorMessage(
    //     `Please select ${jobPostFields.prior_experience_type.type_label}`
    //   );
    //   return true;
    // } // removing validation for prior experience type
    if (
      objJobData.prior_experience_text &&
      !checkFreeTextValidation(objJobData.prior_experience_text)
    ) {
      this.setErrorMessage(
        `Please enter valid ${jobPostFields.prior_experience_type.type_label}`
      );
      return true;
    }
    if (
      objJobData.company_perks_text.some(item => !checkFreeTextValidation(item))
    ) {
      this.setErrorMessage("Please enter valid job benefits");
      return true;
    }

    if (!objJobData.shift_type && jobPostFields.shift_type.visible) {
      this.setErrorMessage("Please select shift type");
      return true;
    }

    if (objJobData.suggested_jds.length === 0 && !objJobData.job_description) {
      this.setErrorMessage("Please select job description");
      return true;
    }
    if (
      jobPostFields.skills.visible &&
      !objJobData.v2_job_skills.length &&
      !are_skills_optional
    ) {
      this.setErrorMessage("Please select skills");
      return true;
    }
    if (!company_name && !isConsultant) {
      this.setErrorMessage("Please enter company name.");
      return true;
    }
    if (isConsultant && !company_name) {
      this.setErrorMessage("Please select hiring company.");
      return true;
    }
    if (
      jobPostFields.is_additional_fee_applicable.visible &&
      !isAadditionalFeeApplicable
    ) {
      this.setErrorMessage(
        "Please select Is candidate required to pay any fee"
      );
      return true;
    }

    if (
      jobPostFields.amount.visible &&
      isAadditionalFeeApplicable === "yes" &&
      !objJobData.amount
    ) {
      this.setErrorMessage("Please enter the amount");
      return true;
    }

    // if (jobPostFields.reason.visible && isAadditionalFeeApplicable === "yes") {
    //   if (!objJobData.deposit_time) {
    //     this.setErrorMessage("Please provide time");
    //     return true;
    //   } else if (!objJobData.deposit_reason) {
    //     this.setErrorMessage("Please provide valid reason");
    //     return true;
    //   } else if (
    //     objJobData.deposit_reason === "Others" &&
    //     !objJobData.deposit_reason_text
    //   ) {
    //     this.setErrorMessage("Please provide valid reason");
    //     return true;
    //   }
    // }

    if (Number(objJobData.minExp) > Number(objJobData.maxExp)) {
      this.setErrorMessage(
        "Max experience should be greater than min experience.",
        1
      );
      return true;
    }

    if (!objJobData.job_city_id) {
      this.setErrorMessage("Please select job city");
      return true;
    }
    if (!objJobData.job_locality_id) {
      this.setErrorMessage("Please select job locality");
      return true;
    }
    if (String(objJobData.interview_type) === "SCHEDULE_INTERVIEW") {
      if (!objJobData.interview_address) {
        this.setErrorMessage("Please enter interview address");
        return true;
      }
    }
    if (
      objJobData.interview_address &&
      objJobData.interview_address.length > 255
    ) {
      this.setErrorMessage("Interview address should be 255 character only");
      return true;
    }
    if (objJobData.hiring_start_date > objJobData.hiring_end_date) {
      this.setErrorMessage("Start date should be less than end date");
      return true;
    }
    if (
      objJobData.job_approval_status === "REJECTED" &&
      !objJobData.jobRejectedReason
    ) {
      this.setErrorMessage("Please select rejected reasons");
      return true;
    }
    /* End Validation */
  };

  saveJob = (objJobData, isRightSaved = false) => {
    const { jobPostFields, initialJobApprovalStatus, initialRejectedReasonId } =
      this.state;
    const { jobId, recId } = this.props;
    const data = setObjToNull(objJobData);
    let dataObj = {
      job_id: jobId,
      company_id: objJobData.company_id,
      recruiter_id: recId,
      category_id: objJobData.category_id,
      job_title: objJobData.job_title,
      call_allowed: objJobData.call_allowed,
      no_of_openings: objJobData.no_of_openings,
      company_name: objJobData.company_name,
      job_contact: objJobData.call_allowed ? objJobData.job_contact : "",
      job_type: objJobData.job_type,
      gender_req: objJobData.gender_req,
      minSal: objJobData.minSal,
      maxSal: objJobData.maxSal,
      minExp: !objJobData.is_fresher_allowed
        ? Number(objJobData.minExp)
        : undefined,
      maxExp: !objJobData.is_fresher_allowed
        ? Number(objJobData.maxExp)
        : undefined,
      is_fresher_allowed: objJobData.is_fresher_allowed,

      is_wfh: objJobData.jobTypeLocation === "WORK_FROM_HOME",
      is_contract: jobPostFields.is_contract.visible
        ? objJobData.is_contract
        : false,
      working_days: jobPostFields.working_days.visible
        ? objJobData.working_days
        : null,
      working_days_text: jobPostFields.working_days.visible
        ? objJobData.working_days_text
        : null,

      shift_type: jobPostFields.shift_type.visible
        ? objJobData.shift_type
        : null,
      job_start_time: jobPostFields.job_timings.visible
        ? objJobData.job_start_time
        : null,
      job_end_time: jobPostFields.job_timings.visible
        ? objJobData.job_end_time
        : null,

      is_incentive: jobPostFields.plus_incentives.visible
        ? objJobData.is_incentive
        : false,
      is_per_packet_based: jobPostFields.is_per_packet_based.visible
        ? objJobData.is_per_packet_based
        : false,
      is_target_based: jobPostFields.is_target_based.visible
        ? objJobData.is_target_based
        : false,
      v2_job_skills: jobPostFields.skills.visible
        ? objJobData.v2_job_skills
        : [],
      job_skills_text: jobPostFields.skills.visible
        ? objJobData.job_skills_text
        : [],
      skills_required: jobPostFields.skills.visible
        ? objJobData.skills_required
        : null,
      job_description: objJobData.job_description
        ? replaceTagWithLineBreak(objJobData.job_description)
        : null,
      suggested_jds: objJobData.suggested_jds,
      assets: [],
      assets_v2:
        jobPostFields.assets_v2 && jobPostFields.assets_v2.visible
          ? objJobData.v2_job_assets
          : [],
      job_perks: objJobData.job_perks,
      company_perks_text: objJobData.company_perks_text,
      other_perks: null,

      minimum_qualification: jobPostFields.minimum_qualification.visible
        ? objJobData.minimum_qualification
        : null,
      is_english_required: jobPostFields.english_fluency_job_posting_form
        .visible
        ? objJobData.english_fluency !== "NA"
        : null,
      english_fluency: jobPostFields.english_fluency_job_posting_form.visible
        ? objJobData.english_fluency
        : null,
      regional_languages: jobPostFields.regional_languages.visible
        ? objJobData.regional_languages
        : null,
      prior_experience_type: jobPostFields.prior_experience_type.visible
        ? objJobData.prior_experience_type
        : null,
      prior_experience_text: jobPostFields.prior_experience_type.visible
        ? objJobData.prior_experience_text
        : null,
      is_industry_exp: jobPostFields.prior_experience_type.visible
        ? objJobData.is_industry_exp
        : false,
      interview_address: objJobData.interview_address,
      interview_type: objJobData.interview_type,
      job_status: objJobData.job_status,
      hiring_start_date: moment(objJobData.hiring_start_date).format(
        "YYYY-MM-DD"
      ),
      hiring_end_date: moment(objJobData.hiring_end_date).format("YYYY-MM-DD"),
      job_calling_first_status: objJobData.job_calling_first_status,
      job_calling_second_status: objJobData.job_calling_second_status,
      job_city_id: objJobData.job_city_id,
      job_locality_id: objJobData.job_locality_id,
      crm_status_remarks: objJobData.crm_status_remarks,
      job_live_status: objJobData.job_live_status,
      job_type_location: objJobData.jobTypeLocation
    };
    if (isRightSaved) {
      dataObj.job_approval_status = objJobData.job_approval_status;
      dataObj.job_reject_reason_id =
        objJobData.job_approval_status === "REJECTED"
          ? objJobData.jobRejectedReason
          : null;
    } else {
      dataObj.job_approval_status = initialJobApprovalStatus
        ? initialJobApprovalStatus
        : null;
      dataObj.job_reject_reason_id = initialRejectedReasonId
        ? initialRejectedReasonId
        : null;
    }

    if (data.isAadditionalFeeApplicable === "no") {
      dataObj.is_additional_fee_applicable = false;
      dataObj.amount = null;
      dataObj.deposit_time_id = null;
      dataObj.deposit_reason_id = null;
      dataObj.reason = null;
    } else {
      dataObj.is_additional_fee_applicable = true;
      dataObj.amount = data.amount;
      dataObj.deposit_time_id = data.deposit_time || data.deposit_time_id;
      if (data.deposit_reason === "Others") {
        dataObj.reason = data.deposit_reason_text?.trim();
        dataObj.deposit_reason_id = 6;
      } else {
        dataObj.deposit_reason_id =
          data.deposit_reason || data.deposit_reason_id;
        dataObj.reason = null;
      }
    }

    this.setErrorMessage("");
    callPremiumSaveOnStateVsProp({
      statePremium: this.state.premiumProductIntent,
      propPremium: this.state.jobData?.interested_in_premium_offering,
      recId: this.props.recId,
      setState: this.setState
    });
    JobHai.post("/v4/save_job", dataObj)
      .then(response => {
        const data = response.data.data;
        const {
          isRecruiterOnboarded = false,
          total_live_jobs = 0,
          pending_job_count = 0
        } = data;
        this.setState({
          isSaveDisabled: false,
          isRecruiterOnboarded,
          isLeftSaved: true
        });
        if (isRightSaved) {
          this.setState({
            isRightSaved
          });
        }
        this.updateLiveJobCount({
          total_live_jobs,
          pending_job_count,
          premiumIntent: this.state.premiumProductIntent
        });
        this.reloadRfdAPI();
      })
      .catch(error => {
        let msg = getCustomErrorMessage(error);
        this.updateMessage(msg, 1);
        this.setState({ isSaveDisabled: false });
      });
  };

  jobSaveChanges = async () => {
    const { jobData, isConsultant } = this.state;
    const objJobData = jobData;
    const {
      company_id,
      client_id,
      company_name,
      interview_address,
      interview_type,
      job_id
    } = jobData;

    this.setState(
      {
        saveJobClicked: true
      },
      () => {
        this.setState({
          saveJobClicked: false
        });
        const isError = this.handleFormValidation(); // To Validate Form Feilds
        if (isError) {
          return;
        }

        const clientData = {
          company_id,
          job_id,
          client_id: client_id ? client_id : undefined,
          interview_type,
          client_name: company_name,
          client_address: interview_address
        };
        this.setState({ isSaveDisabled: true });
        if (isConsultant && company_name) {
          let url = client_id ? "/update-client" : "/add-client";
          JobHai.post(url, clientData)
            .then(response => {
              const data = response.data.data || [];
              objJobData.client_id = data.id;
              objJobData.company_name = company_name;
              objJobData.interview_address = interview_address;
              this.saveJob(objJobData);
            })
            .catch(error => {
              let msg = getCustomErrorMessage(error);
              this.setState({ isSaveDisabled: false });
              this.updateMessage(msg, 1);
            });
        } else {
          this.saveJob(objJobData);
        }
      }
    );
  };

  handleJobStatus = async () => {
    const { jobData, makeJobliveDirectly } = this.state;
    const { jobApprovalStatus, jobRejectedReason } = jobData;
    const data = jobData;
    const isError = await this.handleFormValidation(); // To Validate Form Feilds
    if (isError) {
      return;
    }
    if (!jobApprovalStatus) {
      this.setErrorMessage("Please select job approval status");
      return true;
    }
    if (jobApprovalStatus === "REJECTED" && !jobRejectedReason) {
      this.setErrorMessage("Please select rejected reason");
      return true;
    }
    if (jobApprovalStatus === "VERIFIED") {
      const liveStatus = makeJobliveDirectly ? "GO_LIVE" : "READY_FOR_QA";
      data.job_live_status = liveStatus;
      data.job_reject_reason_id = null;
    } else {
      data.job_live_status = null;
      data.job_reject_reason_id = jobRejectedReason;
    }
    data.job_approval_status = jobApprovalStatus;
    this.setState({ isSaveDisabled: true });
    this.saveJob(data, true);
  };

  /* Start
   * This function will call rfd api
   * after checking the changes in related fields values
   * Rfd related fields are: job_contact, no_of_openings
   */

  reloadRfdAPI = () => {
    const { rfdFields, jobData } = this.state;
    const { relaodRfd } = this.props;
    if (isReloadRfd(rfdFields, jobData)) relaodRfd();
  };

  /* End */

  /* Handle job count to avoid job details api */
  updateLiveJobCount = ({
    total_live_jobs,
    pending_job_count,
    premiumIntent
  }) => {
    const { jobData } = this.state;
    const { job_approval_status = "" } = jobData;
    if (!job_approval_status) jobData.job_approval_status = "PENDING";
    jobData.total_live_jobs = total_live_jobs;
    jobData.pending_job_count = pending_job_count;
    if (premiumIntent !== undefined && premiumIntent !== null)
      jobData.interested_in_premium_offering =
        premiumIntent === "No" ? false : true;
    this.setState({ jobData, premiumProductIntent: premiumIntent });
    this.setInitialState(jobData, true);
  };
  /* End */

  handlePremiumIntentCapture = ({ active, data, jobId, recId }) => {
    this.setState({ premiumProductIntent: active.label, isLeftSaved: false });
  };

  render() {
    const {
      isLoading,
      isConsultant,
      jobData,
      clientList,
      editedFields,
      isRecruiterVerified,
      errorMessage,
      isSaveDisabled,
      isLeftSaved,
      jobPostFields,
      otherJobList,
      isRightSaved,
      recName,
      recPhone,
      agent_name,
      agent_phone,
      isRecruiterOnboarded,
      makeJobliveDirectly,
      onboardingMethod,
      recEmail,
      isRecEmailVerified,
      documentType,
      isDifferentAddress,
      premiumProductIntent,
      isNewCity,
      isPartOfCreditSystem
    } = this.state;
    const {
      page,
      jobId,
      role,
      isAllowEdit,
      crmMetaData,
      recId,
      statusMessage,
      rfdData,
      handleRfdClick,
      handleOnBoradingScore,
      relaodRfd,
      jobPostMetaData
    } = this.props;
    const {
      company_id,
      createdAt,
      source,
      crm_cleaning_status,
      crm_calling_status,
      crm_qa_status,
      job_live_status,
      jobRejectedReason,
      job_calling_status,
      crm_status_remarks = "",
      first_live_by = "",
      job_status,
      has_old_status = false,
      job_calling_first_status = "NEW",
      job_calling_second_status = "NEW",
      moderator_name = "",
      jobApprovalStatus,
      total_live_jobs = "",
      hiring_start_date,
      hiring_end_date,
      pending_job_count = "",
      interested_in_premium_offering,
      job_distinction,
      live_date = "",
      jobPlanUpgradeDate = "",
      boost_type = "",
      applicant_count = 0,
      reco_count = 0
    } = jobData;
    const tagList = {
      companyId: company_id,
      jobId,
      recId,
      createdAt,
      source,
      isJobAutoLive: first_live_by === "AUTO_LIVE",
      moderatorName: moderator_name,
      agent_name,
      agent_phone,
      totalLiveJobs: total_live_jobs,
      totalPendingJobs: pending_job_count,
      jobLiveDate: live_date,
      madePremium: jobPlanUpgradeDate,
      applicationCount: applicant_count,
      recoCount: reco_count
    };
    const otherMethodDetails = getOtherMethodInfo(
      recEmail,
      isRecEmailVerified,
      documentType
    );
    const { onboardingCalcKeys } = rfdData;
    const {
      true_caller_score = "",
      whatsapp_account_score = "",
      job_posting_details_score = "",
      is_onboarded
    } = onboardingCalcKeys;

    const isOBCFieldEmpty =
      isEmpty(true_caller_score) ||
      isEmpty(whatsapp_account_score) ||
      isEmpty(job_posting_details_score);
    const isOnboarded = is_onboarded ? !is_onboarded : isOBCFieldEmpty;

    const sidebarInfo = {
      jobId,
      page,
      role,
      otherJobList,
      job_live_status,
      crm_status_remarks,
      jobApprovalStatus,
      jobRejectedReason,
      job_calling_status,
      isRecruiterVerified,
      crmMetaData,
      job_status,
      isAllowEdit,
      isJobLiveClicked: isRightSaved,
      isRecruiterOnboarded,
      makeJobliveDirectly,
      disabled: isAllowEdit || isSaveDisabled || isOnboarded,
      onboardingMethod: {
        method: onboardingMethod,
        otherMethodDetails,
        documentType
      },
      totalLiveJobs: total_live_jobs,
      isDifferentAddress,
      interested_in_premium_offering,
      recId,
      premiumProductIntent,
      handlePremiumIntentCapture: this.handlePremiumIntentCapture,
      job_distinction,
      isNewCity,
      isPartOfCreditSystem,
      boost_type
    };
    const oldStatusList = {
      crm_cleaning_status,
      crm_calling_status,
      crm_qa_status
    };
    const callStatusValue = {
      job_calling_first_status,
      job_calling_second_status
    };
    return (
      <div className="rowContainer">
        <div className="leftBar">
          {editedFields.length ? (
            <EditedFieldList editedField={editedFields} />
          ) : null}
          <div className="containerCard">
            {jobData?.archived && (
              <div className="archiveContainer">
                <img src="/images/alert.svg" alt="alert" />
                <div className="job-archived"> This Job Is Archived</div>
              </div>
            )}
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                <div className="company-details">
                  <JobCalling
                    recName={recName}
                    recPhone={recPhone}
                    callStatusValue={callStatusValue}
                    handleCallStatus={this.getFieldData}
                    isNewCity={isNewCity}
                  />
                  <div className="seperator" />
                  <JobDetails
                    data={jobData}
                    saveJobClicked={this.state.saveJobClicked}
                    crmMetaData={crmMetaData}
                    jobPostMetaData={jobPostMetaData}
                    page={page}
                    editedFields={editedFields}
                    statusMessage={statusMessage}
                    jobPostFields={jobPostFields}
                    getFieldData={this.getFieldData}
                  />
                  <div className="seperator" />
                  <InterviewDetails
                    data={jobData}
                    isConsultant={isConsultant}
                    clientList={clientList}
                    editedFields={editedFields}
                    getFieldData={this.getFieldData}
                  />
                  <div className="seperator" />
                  <JobLiveDate
                    hiringStartDate={hiring_start_date}
                    hiringEndDate={hiring_end_date}
                    editedFields={editedFields}
                    getFieldData={this.getFieldData}
                  />
                </div>
                <SaveChanges
                  onClick={this.jobSaveChanges}
                  errorMessage={errorMessage}
                  disabled={isAllowEdit || isSaveDisabled || jobData?.archived}
                  isSaved={isLeftSaved}
                />
              </>
            )}
          </div>
          <ViewLogs
            jobId={jobId}
            oldStatus={has_old_status}
            type="job"
            oldStatusList={oldStatusList}
            statusMessage={statusMessage}
          />
        </div>
        <div className="rightBar">
          <RightSideBar
            tagList={tagList}
            type="job"
            getFieldData={this.getFieldData}
            sidebarInfo={sidebarInfo}
            onClick={this.handleJobStatus}
            isLoading={isLoading}
            rfdData={rfdData}
            handleRfdClick={handleRfdClick}
            handleOnBoradingScore={handleOnBoradingScore}
            relaodRfd={relaodRfd}
          />
        </div>
      </div>
    );
  }
}

export default JobVerification;
