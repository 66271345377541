import React, { useState, useEffect } from "react";
import DocumentTitle from "react-document-title";
import { checkPermission } from "../../services/utils";
import {
  ADMIN_PANEL,
  ENTERPRISE_LEADS,
  MODEARTOR_ASSIGNMENT
} from "../../routes/RouteConstants";
import NoAccess from "../Common/NoAccess";
import HeadingRow from "../Common/HeadingRow";
import LoadingSpinner from "../LoadingSpinner";
import { getLcsCampaignList } from "../../services/metadataApiServices";

const EnterpriseLeadsDashboard = () => {
  const [defaultClient, setDefaultClient] = useState("");
  const [isLoading, setLoader] = useState(true);

  useEffect(() => {
    getLcsCampaignList()
      .then(response => {
        if (response.length) {
          setDefaultClient(response[0].client_id);
        }
        setLoader(false);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        setLoader(false);
      });
  }, []);

  const showEnterpriseLeads = checkPermission("Enterprise Leads");
  const showLeadsAdmin = checkPermission("EnterpriseLeadsAdmin");
  const isViewPage = showEnterpriseLeads || showLeadsAdmin;

  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <DocumentTitle title={"Job Hai CRM - Enterprise Leads"}>
      <div className="dashboard">
        <HeadingRow heading="Enterprise Leads - Dashboard" />
        <div className="clr gap-20" />
        <div className="whiteCard">
          {isViewPage ? (
            <div className="container-fluid">
              <div className="clr gap-20" />
              <div className="row marginBottom20">
                {showLeadsAdmin ? (
                  <>
                    <div className="col-md-4 marginBottom20">
                      <div className="box">
                        <a
                          href={`${ENTERPRISE_LEADS}/admin-view?client=${defaultClient}`}
                        >
                          Admin Leads
                        </a>
                      </div>
                    </div>
                    <div className="col-md-4 marginBottom20">
                      <div className="box">
                        <a href={`${ENTERPRISE_LEADS}/perfromance`}>
                          Moderator Performance
                        </a>
                      </div>
                    </div>
                    <div className="col-md-4 marginBottom20">
                      <div className="box">
                        <a href={`${ADMIN_PANEL}?pageType=LEAD`}>
                          Moderator Report
                        </a>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="box">
                        <a
                          href={`${MODEARTOR_ASSIGNMENT}?pageType=LEAD&client=${defaultClient}`}
                        >
                          Moderator Assignment
                        </a>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {showEnterpriseLeads ? (
                      <>
                        <div className="col-md-4">
                          <div className="box">
                            <a
                              href={`${ENTERPRISE_LEADS}/moderator-view?client=${defaultClient}`}
                            >
                              Leads
                            </a>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="box">
                            <a href={`${ENTERPRISE_LEADS}/perfromance`}>
                              My Performance
                            </a>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          ) : (
            <NoAccess />
          )}
        </div>
      </div>
    </DocumentTitle>
  );
};

export default EnterpriseLeadsDashboard;
