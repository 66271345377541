import React, { useState, useRef, useEffect } from "react";
import { Check, ChevronDown, ChevronUp } from "lucide-react";
import cn from "../../lib/utils";

const CustomSelect = ({
  options,
  value,
  onChange,
  placeholder,
  fieldName,
  labelKey = "name",
  valueKey = "id"
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  const handleToggle = () => setIsOpen(!isOpen);
  const handleClose = () => setIsOpen(false);

  const handleSelect = option => {
    onChange(option, fieldName);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        handleClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  const findSelectedItem = options.find(item => item[valueKey] === value) || {};
  return (
    <div className="relative w-full" ref={selectRef}>
      <div
        className={cn(
          "flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 cursor-pointer"
        )}
        onClick={handleToggle}
      >
        <span className="line-clamp-1">
          {findSelectedItem?.[labelKey]
            ? findSelectedItem?.[labelKey]
            : placeholder}
        </span>
        {isOpen ? (
          <ChevronUp className="h-4 w-4 opacity-50" />
        ) : (
          <ChevronDown className="h-4 w-4 opacity-50" />
        )}
      </div>
      {isOpen && (
        <div className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md border bg-popover text-popover-foreground shadow-md">
          {options.map(option => (
            <div
              key={option[valueKey]}
              className={cn(
                "relative flex w-full cursor-pointer select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground",
                value === option[valueKey] && "bg-accent text-accent-foreground"
              )}
              onClick={() => handleSelect(option)}
            >
              <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
                {value === option[valueKey] && <Check className="h-4 w-4" />}
              </span>
              {option[labelKey]}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
