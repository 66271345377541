import React from "react";
import { Alert } from "react-bootstrap";
import MetaData from "../../constants/MetaData";
import CustomTextField from "../Common/Form/CustomTextField";
import Heading from "../Common/Form/Heading";
import Label from "../Common/Form/Label";
import Chips from "../Common/Chips";
import CustomSelect from "../Common/Form/CustomSelect";
import { getNthItemText } from "../../services/utils";
import PremiumIntentCapture from "./PremiumIntentCapture";

const JobVerificationStatus = props => {
  const { getFieldData, sidebarInfo, onClick } = props;
  const {
    jobId,
    crm_status_remarks,
    otherJobList,
    jobRejectedReason,
    crmMetaData = [],
    job_status,
    role = "QA",
    page = "",
    isRecruiterVerified,
    isJobLiveClicked,
    isRecruiterOnboarded,
    makeJobliveDirectly,
    disabled,
    jobApprovalStatus,
    job_live_status,
    isDifferentAddress,
    job_distinction,
    isNewCity
  } = sidebarInfo;
  const { jobRejectedReasons = [] } = crmMetaData;
  let index = otherJobList.findIndex(item => item === Number(jobId));

  const jobNumber = getNthItemText(index);

  const chipsList = [{ label: "Disabled", id: "DISABLED" }];
  const activeChips = "DISABLED";

  const handleChips = (event, fieldName, item) => {
    let id = item.id;
    getFieldData(fieldName, id);
  };

  const handleOnOff = (event, fieldName) => {
    let id = event.currentTarget.dataset.id;
    getFieldData(fieldName, id);
    props.onClick(id);
  };

  const labelMakeJobLive = isNewCity ? "Ready to Live" : "Make Job Live";
  let liveLabel = makeJobliveDirectly
    ? `${labelMakeJobLive} 🎉`
    : "Send For QA";
  let ctaLabel = jobApprovalStatus === "REJECTED" ? "Reject Job" : liveLabel;

  let successMesage =
    jobApprovalStatus === "REJECTED"
      ? "Job Rejected"
      : "Job verification done!";

  successMesage = isRecruiterOnboarded
    ? "New Recruiter Onboarded!!"
    : successMesage;
  let showWarning = false;
  showWarning =
    jobApprovalStatus === "VERIFIED" && isDifferentAddress && !isJobLiveClicked;

  if (job_live_status) {
    const labelSuccessMsg = isNewCity ? "Ready to Live" : "Job is live";

    successMesage =
      job_live_status === "READY_FOR_QA" ? "Sent for QA" : labelSuccessMsg;
  }
  const isShowButton =
    jobApprovalStatus === "VERIFIED" ||
    (jobApprovalStatus === "REJECTED" && jobRejectedReason);
  const showLiveStatus = role === "QA" && page === "live";
  let jobApprovalStatusList = MetaData.jobApprovalStatus;
  return (
    <div className="jobStatusContainer">
      <div className="rowContainer">
        <Heading
          heading={`${index + 1}${jobNumber} Job Status`}
          customClass="sideBarHeading"
        />
        {job_distinction === "Extra" ? (
          <div className="paidStatus">Paid</div>
        ) : null}
      </div>
      {job_status === "DISABLED" ? (
        <div className="formRow">
          <div className="fullWidth">
            <Label lable="Job Live Status" />
            <div className="statusChip">
              <Chips
                chips={chipsList}
                activeChips={[activeChips]}
                isMulti={false}
                fieldName="job_status"
              />
            </div>
          </div>
        </div>
      ) : null}
      {showLiveStatus ? (
        <div className="formRow">
          <div className="fullWidth">
            <Label lable="Job Live Status" />
            <Chips
              chips={MetaData.jobStatus}
              activeChips={job_status ? [job_status] : []}
              onClick={handleOnOff}
              isMulti={false}
              label="title"
              fieldName="job_status"
            />
          </div>
        </div>
      ) : null}
      <div className="formRow">
        <div className="fullWidth">
          <Label lable="Job Approval Status" />
          <div className="statusChip">
            <Chips
              chips={jobApprovalStatusList}
              activeChips={jobApprovalStatus ? [jobApprovalStatus] : []}
              onClick={handleChips}
              isMulti={false}
              fieldName="jobApprovalStatus"
            />
          </div>
        </div>
      </div>

      {jobApprovalStatus === "REJECTED" ? (
        <div className="formRow">
          <div className="fullWidth">
            <Label lable="Rejected Reason" />
            <CustomSelect
              name="jobRejectedReason"
              value={jobRejectedReason}
              onChange={e => getFieldData("jobRejectedReason", e.target.value)}
              optionsList={jobRejectedReasons}
              itemLabel="reason"
              optionValue="id"
              allowDisabled={true}
            />
          </div>
        </div>
      ) : null}
      <PremiumIntentCapture sidebarInfo={sidebarInfo} />

      <div className="formRow">
        <div className="fullWidth">
          <Label lable="Remarks" isOptional={true} />
          <CustomTextField
            fieldName={"crm_status_remarks"}
            name="crm_status_remarks"
            value={crm_status_remarks}
            onChange={e => getFieldData("crm_status_remarks", e.target.value)}
            placeholder="Leave your remarks here..."
            textArea={true}
          />
        </div>
      </div>
      {isShowButton && isRecruiterVerified ? (
        <>
          <div className="formRow">
            <div className="statusBtnContainer">
              {isJobLiveClicked ? (
                <input
                  type="button"
                  value={successMesage}
                  className={"successButton"}
                />
              ) : (
                <input
                  type="button"
                  value={ctaLabel}
                  className={"submitBtn"}
                  onClick={onClick}
                  disabled={disabled}
                />
              )}
            </div>
          </div>
          {showWarning ? (
            <div className="formRow warningContainer">
              <Alert variant="warning">
                <img
                  src={"/images/warning.svg"}
                  alt="arrow"
                  width="24"
                  height="24"
                  className="down-arrow"
                />
                Job and Recruiter City/Locality is not matching!
              </Alert>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default JobVerificationStatus;
