import React, { useState } from "react";
import { withStyles } from "@material-ui/styles";
import cx from "classnames";

const styles = theme => ({});

function NumericInput({ classes, classNames, max, min, setParentVal }) {
  const [inputVal, setInputVal] = useState("");

  function onInpChange(e) {
    const value = e.target.value;
    if (
      (/^\d*$/.test(value) && value === "") ||
      (parseInt(value) >= 1 && parseInt(value) <= 99)
    ) {
      setInputVal(value);
      setParentVal(value);
    }
  }
  return (
    <input
      type="number"
      className={cx(classNames)}
      placeholder="1-99 max."
      onChange={onInpChange}
      value={inputVal}
      max={max || "99"}
      min={min || "1"}
      step="1"
      onKeyDown={evt =>
        (evt.key === "e" ||
          evt.key === "." ||
          evt.key === "-" ||
          evt.key === "+") &&
        evt.preventDefault()
      }
    />
  );
}

export default withStyles(styles)(NumericInput);
