import { useEffect, useState } from "react";
import { FilterIcon } from "lucide-react";
import DatePicker from "react-datepicker";
import { Input } from "../../ui/input";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger
} from "../../ui/sheet";
import { Button } from "../../ui/button";
import { Label } from "../../ui/label";
import useInsideSalesStore from "../../../zustandService/useInsideSalesStore";
import {
  formatDateTime,
  isAdmin,
  hasAnyValue,
  parseDateStringToDate,
  validateDateRange,
  validateMinMax
} from "../../../services/utilsV2";
import { getFormattedValue } from "../../../services/utils";
import AssignedTo from "../AssignedTo";
import useInsideLeadStore from "../../../zustandService/useInsideLeadStore";
import CustomSelect from "../../ui/CustomSelect";

const SalesFilter = () => {
  const [fieldItems, setFieldItems] = useState({});
  const [errorMesage, setErrorMessage] = useState("");
  const [resetFilter, setResetFilter] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const {
    updateFilterData,
    initialFilters,
    defaultFilters,
    updateInitialFilters
  } = useInsideSalesStore(state => ({
    updateFilterData: state.updateFilterData,
    initialFilters: state.initialFilters,
    defaultFilters: state.defaultFilters,
    updateInitialFilters: state.updateInitialFilters
  }));

  const { moderatorList } = useInsideLeadStore(state => ({
    moderatorList: state.moderatorList
  }));

  useEffect(() => {
    if (Object.keys(initialFilters).length > 0) {
      setResetFilter(hasAnyValue(initialFilters));
      setFieldItems({ ...initialFilters });
    }
  }, []);

  const resetFieldItems = () => {
    setFieldItems(defaultFilters);
    setErrorMessage("");
  };

  const resetFilters = () => {
    setFieldItems(defaultFilters);
    setResetFilter(false);
    updateInitialFilters(defaultFilters);
    updateFilterData({});
    setErrorMessage("");
  };

  const handleChange = e => {
    let { name, value } = e.target;
    if (
      name === "orderIds" ||
      name === "recruiterIds" ||
      name === "recruiterPhone" ||
      name === "orgIds"
    ) {
      value = getFormattedValue(value, true);
    }
    setFieldItems({
      ...fieldItems,
      [name]: value
    });
  };

  const handleDateChange = (field, date) => {
    setFieldItems({
      ...fieldItems,
      [field]: date
    });
  };

  const handleMinMaxChange = (field, e) => {
    let { name, value } = e.target;
    setFieldItems(prevState => ({
      ...prevState,
      [field]: { ...prevState[field], [name]: getFormattedValue(value, true) }
    }));
  };

  const handleOptionChange = option => {
    setFieldItems({
      ...fieldItems,
      teamLeadIds: option?.id || ""
    });
  };

  const handleSubmit = () => {
    const filters = {};
    setErrorMessage("");
    // Assign filters from fieldItems
    const fieldsToMap = [
      { key: "orgName", isArray: false },
      { key: "orgIds", isArray: true },
      { key: "orderIds", isArray: true },
      { key: "recruiterIds", isArray: true },
      { key: "recruiterName", isArray: false },
      { key: "recruiterPhone", isArray: false },
      { key: "moderatorIds", isArray: true },
      { key: "teamLeadIds", isArray: true }
    ];
    fieldsToMap.forEach(({ key, isArray }) => {
      if (fieldItems[key]) {
        filters[key] = isArray ? [Number(fieldItems[key])] : fieldItems[key];
      }
    });

    // Validate date ranges
    const dateFields = [
      { field: "creditPurchaseDate", label: "Credit PurchaseDate Date" }
    ];

    for (const { field, label } of dateFields) {
      const dateError = validateDateRange(fieldItems, field, label);
      if (dateError) {
        setErrorMessage(dateError);
        return;
      }
      const { from, to } = fieldItems[field];
      if (from || to) {
        filters[field] = {
          from: formatDateTime(from),
          to: formatDateTime(to, "23:59:59")
        };
      }
    }

    // Validate min-max ranges
    const minMaxFields = [
      { field: "creditsPurchased", label: "Credits purchased" }
    ];

    for (const { field, label } of minMaxFields) {
      const minMaxerror = validateMinMax(fieldItems, field, label);
      if (minMaxerror) {
        setErrorMessage(minMaxerror);
        return;
      }
      const { min, max } = fieldItems[field];
      if (min || max) {
        filters[field] = {
          min: min ? Number(min) : undefined,
          max: max ? Number(max) : undefined
        };
      }
    }
    updateFilterData({ ...filters });
    updateInitialFilters(fieldItems);
    setResetFilter(hasAnyValue(filters));
    handleClose();
  };
  let teamLeadList = [];
  if (isAdmin()) {
    teamLeadList = moderatorList.filter(
      item => item.lmsUserLevel === "TEAM_LEAD"
    );
  }
  return (
    <div className="flex items-center justify-between p-3 mb-6">
      <div className="flex justify-end w-full space-x-3">
        <Sheet>
          <SheetTrigger>
            {resetFilter && (
              <Button
                onClick={resetFilters}
                className="mr-2 bg-blue-500 border border-blue-500 text-white hover:bg-blue-600"
              >
                Reset Filter
              </Button>
            )}
            <Button
              variant="outline"
              className="ml-2"
              size="icon"
              onClick={handleOpen}
            >
              <FilterIcon className="w-4 h-4" />
            </Button>{" "}
          </SheetTrigger>
          <SheetContent
            isOpen={isOpen}
            handleClose={handleClose}
            className="overflow-y-auto max-h-full filter"
          >
            <SheetHeader>
              <SheetTitle>Filters</SheetTitle>
            </SheetHeader>
            <div className="flex flex-col gap-4 mt-12">
              <div className="grid gap-2">
                <Label className="text-sm font-medium">Order Ids</Label>
                <Input
                  type="text"
                  placeholder="Order Ids"
                  name="orderIds"
                  value={fieldItems.orderIds || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-2">
                <Label className="text-sm font-medium">Recruiter Ids</Label>
                <Input
                  type="text"
                  placeholder="Recruiter Id"
                  name="recruiterIds"
                  value={fieldItems.recruiterIds || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-2">
                <Label className="text-sm font-medium">Recruiter Name</Label>
                <Input
                  type="text"
                  placeholder="Recruiter Name"
                  name="recruiterName"
                  value={fieldItems.recruiterName || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-2">
                <Label className="text-sm font-medium">Recruiter Phone</Label>
                <Input
                  type="text"
                  placeholder="Recruiter Phone"
                  name="recruiterPhone"
                  value={fieldItems.recruiterPhone || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-2">
                <Label className="text-sm font-medium">Organisation Id</Label>
                <Input
                  type="text"
                  placeholder="Organisation Id"
                  name="orgIds"
                  value={fieldItems.orgIds || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-2">
                <Label className="text-sm font-medium">Company Name</Label>
                <Input
                  type="text"
                  placeholder="Company Name"
                  name="orgName"
                  value={fieldItems.orgName || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-2">
                <Label className="text-sm font-medium">Credits Purchased</Label>
                <div className="flex items-center space-x-2">
                  <Input
                    type="tel"
                    placeholder="Min"
                    value={fieldItems.creditsPurchased?.min || ""}
                    name="min"
                    onChange={e => handleMinMaxChange("creditsPurchased", e)}
                    className="w-40"
                  />
                  <Input
                    type="tel"
                    placeholder="Max"
                    value={fieldItems.creditsPurchased?.max || ""}
                    name="max"
                    onChange={e => handleMinMaxChange("creditsPurchased", e)}
                    className="w-40"
                  />
                </div>
              </div>
              <div className="grid gap-2">
                <Label className="text-sm font-medium">
                  Credits Purchased Date
                </Label>
                <div className="flex items-center space-x-2">
                  <DatePicker
                    selected={parseDateStringToDate(
                      fieldItems.creditPurchaseDate?.from
                    )}
                    onSelect={date =>
                      handleDateChange("creditPurchaseDate", {
                        ...fieldItems.creditPurchaseDate,
                        from: date
                      })
                    }
                    placeholderText="From"
                    dateFormat="yyyy-MM-dd"
                    className="form-control w-30"
                  />
                  <DatePicker
                    selected={parseDateStringToDate(
                      fieldItems.creditPurchaseDate?.to
                    )}
                    onSelect={date =>
                      handleDateChange("creditPurchaseDate", {
                        ...fieldItems.creditPurchaseDate,
                        to: date
                      })
                    }
                    placeholderText="To"
                    dateFormat="yyyy-MM-dd"
                    className="form-control w-30"
                    minDate={parseDateStringToDate(
                      fieldItems.creditPurchaseDate?.from
                    )}
                  />
                </div>
              </div>
              <AssignedTo
                fieldItems={fieldItems}
                setFieldItems={setFieldItems}
              />
              {teamLeadList.length ? (
                <div className="grid gap-2">
                  <Label>Team Lead</Label>
                  <CustomSelect
                    options={teamLeadList}
                    value={fieldItems.teamLeadIds}
                    onChange={handleOptionChange}
                    placeholder={`Select Team Lead`}
                  />
                </div>
              ) : null}
            </div>
            {errorMesage && (
              <div className="flex text-red-500 mt-6">{errorMesage}</div>
            )}
            <SheetFooter className="flex justify-end mt-12">
              <Button variant="outline" onClick={resetFieldItems}>
                Reset
              </Button>
              <Button onClick={handleSubmit}>Apply Filter</Button>
            </SheetFooter>
          </SheetContent>
        </Sheet>
      </div>
    </div>
  );
};

export default SalesFilter;
