import React, { useState } from "react";
import { withStyles } from "@material-ui/styles";
import { styles, postCreditsAddition, getPendingOrder } from "./helper";
import {
  extractPANFromGST,
  formatNumber,
  getErrorMessage,
  getFormattedValue,
  linkGeneratorUtils,
  validateCheque,
  validatePAN,
  validatePayUid
} from "../../services/utils";
import ConfirmationCTA from "./ConfirmationCTA";
import AfterSuccessCTA from "./AfterSuccessCTA";
import CreditsAdditionForm from "./CreditsAdditionForm";
import {
  MAX_UNPAID_CREDITS,
  minBasicValueReqForPan,
  MIN_CREDITS
} from "../../services/constant";

const STATES = {
  FETCHING: "FETCHING",
  FETCHED: "FETCHED",
  ERROR: "ERROR",
  PRELIMINARY: "PRELIMINARY",
  FINAL: "FINAL",
  SUCCESS: "SUCCESS"
};

const validityMapper = validityInDays => {
  if (validityInDays >= 28 && validityInDays <= 31) {
    return "30 days";
  }
  if (validityInDays >= 89 && validityInDays <= 93) {
    return "3 months";
  }
  if (validityInDays >= 360 && validityInDays <= 366) {
    return "1 year";
  }
  return `${validityInDays} days`;
};

function CreditsAddition({
  classes,
  recId,
  setTotalCredits,
  showTitle = true
}) {
  const [currentState, setCurrentState] = useState(STATES.PRELIMINARY);
  const [fieldItems, setFieldItems] = useState({
    transactionType: "",
    orderId: "",
    validity: "",
    credits: "",
    creditReason: "",
    file: "",
    gstNumber: "",
    paymentMode: "",
    payUid: "",
    chequeNo: "",
    financeReferenceNumber: "",
    panNumber: ""
  });
  const [postingState, setPostingState] = useState(STATES.FETCHED);
  const [errorMessage, setErrorMessage] = useState("");
  const [pendingData, setPendingData] = useState([]);

  const {
    transactionType,
    orderId,
    credits,
    validity,
    creditReason,
    file,
    gstNumber,
    panNumber,
    paymentMode,
    chequeNo,
    payUid,
    financeReferenceNumber
  } = fieldItems;

  const onGstFieldBlur = ({ target: { name, value } }) => {
    const panNo = extractPANFromGST(value);
    if (!panNo && !panNumber) return;
    setFieldItems({
      ...fieldItems,
      panNumber: panNo
    });
  };

  async function onSave(st) {
    setErrorMessage("");

    if (st === STATES.PRELIMINARY) {
      if (handleFormValidation()) {
        return;
      }
      setCurrentState(STATES.FINAL);
    }
    let obj = {};
    if (transactionType === "UNPAID") {
      obj = new FormData();
      obj.append("validity", validity);
      obj.append("credits", credits);
      obj.append("file", file);
      obj.append("recruiterId", recId);
      obj.append("transactionType", transactionType);
      obj.append("remark", creditReason);
    } else {
      obj["orderId"] = orderId;
      obj["recruiterId"] = recId;
      obj["transactionType"] = transactionType;
      obj["remark"] = creditReason;
      obj = {
        ...obj,
        paymentMode,
        panNumber: panNumber ? panNumber : undefined,
        payuId: payUid ? payUid : undefined,
        chequeNo: chequeNo ? chequeNo : undefined,
        financeReferenceNumber: financeReferenceNumber
          ? financeReferenceNumber
          : undefined
      };
    }
    if (transactionType === "PAID" && gstNumber) {
      obj["gstNumber"] = gstNumber;
    }

    if (st === STATES.FINAL) {
      setPostingState(STATES.FETCHING);

      try {
        const res = await postCreditsAddition(obj);
        setPostingState(STATES.SUCCESS);
        setTotalCredits(res);
      } catch (error) {
        const msg = getErrorMessage(error);
        setErrorMessage(msg?.message || "Unable to save, try again");
        setPostingState(STATES.ERROR);
        setCurrentState(STATES.PRELIMINARY);
        resetFieldItems();
      }
    }
  }
  function onCancel() {
    setCurrentState(STATES.PRELIMINARY);
    setPostingState(STATES.FETCHED);
    resetFieldItems();
  }

  function resetFieldItems() {
    setFieldItems({
      transactionType: "",
      orderId: "",
      validity: "",
      credits: "",
      creditReason: "",
      file: ""
    });
  }

  function handleChange({ target: { name, value } }) {
    if (name === "transactionType") resetFieldItems();
    if (name === "transactionType" && value === "PAID") {
      getPendingOrder(recId)
        .then(response => {
          setPostingState(STATES.FETCHED);
          setPendingData(response);
        })
        .catch(() => {
          setPostingState(STATES.ERROR);
        });
    }
    if (name !== "file") value = getFormattedValue(value);
    if (name === "payUid" || name === "chequeNo")
      value = getFormattedValue(value, true);
    if (name === "credits") {
      value = getFormattedValue(value, true, true);
    }
    setFieldItems(prevValues => ({ ...prevValues, [name]: value }));
  }

  function handleFormValidation() {
    const orderObj = pendingData.find(item => item.orderId == orderId) || {};
    let gstPattern =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-2]{1}[Z]{1}[0-9A-Z]{1}$/;
    if (
      credits &&
      (credits < MIN_CREDITS ||
        credits > MAX_UNPAID_CREDITS ||
        credits % MIN_CREDITS !== 0)
    ) {
      setErrorMessage("Invalid credits");
      return true;
    }
    if (
      transactionType === "UNPAID" &&
      creditReason &&
      creditReason.trim().length == 0
    ) {
      setErrorMessage("Please enter remarks");
      return true;
    }
    if (
      transactionType === "PAID" &&
      gstNumber &&
      !gstPattern.test(gstNumber)
    ) {
      setErrorMessage("Please enter valid GST");
      return true;
    }
    if (
      transactionType === "PAID" &&
      orderObj.sellingPriceWithoutGst > minBasicValueReqForPan &&
      !validatePAN(panNumber)
    ) {
      setErrorMessage("Please enter valid PAN");
      return true;
    }

    if (!file && transactionType === "UNPAID") {
      setErrorMessage("Please upload document");
      return true;
    }
    if (transactionType === "PAID") {
      switch (paymentMode) {
        case "CHEQUE": {
          if (!validateCheque(chequeNo)) {
            setErrorMessage("Please enter valid cheque no");
            return true;
          }
          break;
        }
        case "PAYU":
        case "SCANNER": {
          if (!validatePayUid(payUid)) {
            setErrorMessage("Please enter valid Pay U id");
            return true;
          }
          break;
        }
        case "BANK_TRANSFER": {
          if (!financeReferenceNumber) {
            setErrorMessage("Please enter valid Finance reference no");
            return true;
          }
          break;
        }
        default:
          break;
      }
    }
  }
  let selectedOrder = [];
  let paidOrderValidity = 0;
  let paidOrderdiscount = 0;
  let paidCredits = 0;
  if (orderId) {
    selectedOrder = pendingData.filter(
      item => item.orderId === Number(orderId)
    );
    if (selectedOrder.length) {
      paidOrderValidity = selectedOrder[0]?.expiry;
      paidOrderdiscount = selectedOrder[0]?.discountPercentage || 0;
      paidCredits = selectedOrder[0]?.credits;
    }
  }
  return (
    <>
      {showTitle && <div className="heading">Credit Addition</div>}
      {currentState === STATES.PRELIMINARY ? (
        <CreditsAdditionForm
          fieldItems={fieldItems}
          onChange={handleChange}
          errorMessage={errorMessage}
          onSave={onSave}
          pendingData={pendingData}
          onGstFieldBlur={onGstFieldBlur}
        />
      ) : (
        <>
          {transactionType === "PAID"
            ? (function () {
                const obj = pendingData.find(item => item.orderId == orderId);
                const credits = obj.credits;
                const sellingPrice = obj.sellingPriceWithoutGst;
                const validityLabel = obj.validityLabel;
                const noOfJobs = linkGeneratorUtils({
                  credits,
                  compute: "noOfJobs"
                });
                const perJob = formatNumber(sellingPrice / noOfJobs);
                return (
                  <div>
                    {`${credits} credits(good for ${noOfJobs} Premium jobs) @ INR ${sellingPrice}(${perJob}/Job)+Taxes with validity of ${validityLabel}`}
                  </div>
                );
              })()
            : `${credits} credits(good for ${linkGeneratorUtils({
                credits,
                compute: "noOfJobs"
              })} Premium job${
                linkGeneratorUtils({
                  credits,
                  compute: "noOfJobs"
                }) > 1
                  ? "s"
                  : ""
              } ) will be added for free with validity of 
          ${validityMapper(validity)}`}

          {postingState === STATES.SUCCESS ? (
            <div className={classes.successState}></div>
          ) : null}
          {postingState === STATES.ERROR ? (
            <div className={classes.errorState}>
              <i class="fa fa-x" aria-hidden="true" />
              <span>&nbsp; Unable to save</span>
            </div>
          ) : null}
          {postingState === STATES.SUCCESS ? (
            <AfterSuccessCTA onCancel={() => onCancel()} label="Add more" />
          ) : (
            <ConfirmationCTA
              disabled={postingState === STATES.FETCHING}
              onSave={() => onSave(STATES.FINAL)}
              onCancel={() => onCancel()}
            />
          )}
        </>
      )}
    </>
  );
}

export default withStyles(styles)(React.memo(CreditsAddition));
