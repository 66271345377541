import { useState } from "react";
import { getErrorMessage } from "../../../services/utils";
import JobHai from "../../../api/JobHai";

const OTLButton = ({ id, callBack }) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const getOTL = async () => {
    try {
      setLoading(true);
      await JobHai.post(`dynamic-dag/one-time/${id}`);
      if (callBack) callBack();
    } catch (error) {
      const msg = getErrorMessage(error);
      setError(msg);
    } finally {
      setLoading(false);
    }
  };

  const dropTable = async () => {
    if (window.confirm("Are you sure you want to drop table?")) {
      try {
        setLoading(true);
        await JobHai.post(`dynamic-dag/table-drop/${id}`);
        if (callBack) callBack();
      } catch (error) {
        const msg = getErrorMessage(error);
        setError(msg);
      } finally {
        setLoading(false);
      }
    }
  };

  const sampleRun = async () => {
    try {
      setLoading(true);
      await JobHai.post(`dynamic-dag/sample-run/${id}`);
      if (callBack) callBack();
    } catch (error) {
      const msg = getErrorMessage(error);
      setError(msg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <>
        {error && <span className="text-danger">{error} </span>}
        <button
          className="btn btn-danger marginleft"
          disabled={isLoading}
          onClick={dropTable}
        >
          Drop Table
        </button>
        <button
          className="btn btn-primary marginleft"
          disabled={isLoading}
          onClick={sampleRun}
        >
          Sample Run
        </button>
        <button
          className="btn btn-primary marginleft"
          disabled={isLoading}
          onClick={getOTL}
        >
          One Time Load
        </button>
      </>
    </>
  );
};

export default OTLButton;
