import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import Label from "../../Common/Form/Label";
import CustomTextField from "../../Common/Form/CustomTextField";
import JobHai from "../../../api/JobHai";
import { DYNAMIC_DAG } from "../../../routes/RouteConstants";
import Chips from "../../Common/Chips";
import { booleanOptions, dagBuilderSource } from "../../../constants/MetaData";
import {
  checkPermission,
  findBooleanState,
  findChipValue,
  getErrorMessage
} from "../../../services/utils";
import NoAccess from "../../Common/NoAccess";
import LoadingSpinner from "../../LoadingSpinner";
import OTLButton from "./OTLButton";
import OTLDataList from "./OTLDataList";
import Heading from "../../Common/Form/Heading";
import CustomSelect from "../../Common/Form/CustomSelect";

const AddDagBuilder = () => {
  const { id } = useParams();
  const isAccess = checkPermission("Dynamic Dag Builder");
  const [formData, setFormData] = useState({
    destination_table_name: "",
    query: "",
    source: "mysql",
    destination_schema: ""
  });
  const [status, setStatus] = useState({
    error: null,
    success: null,
    isLoading: false,
    btnDisabled: false
  });
  const [activeBoolean, setActiveBoolen] = useState([]);
  const [dataList, setDataList] = useState([]);

  const getDagInfo = useCallback(async () => {
    setStatus(prevStatus => ({ ...prevStatus, isLoading: true }));
    try {
      const response = await JobHai.get(`dynamic-dag/${id}`);
      const data = response.data.data?.result;
      setFormData({
        destination_table_name: data?.destination_table_name || "",
        query: data?.query || "",
        source: data?.source || "mysql",
        destination_schema: data?.destination_schema || ""
      });
      if (findChipValue(data?.is_active) == "Yes")
        setActiveBoolen({ value: true, label: "Yes", id: "yes" });
      if (findChipValue(data?.is_active) == "No")
        setActiveBoolen({ value: false, label: "No", id: "no" });
    } catch (error) {
      setStatus(prevStatus => ({
        ...prevStatus,
        error: getErrorMessage(error)
      }));
    } finally {
      setStatus(prevStatus => ({ ...prevStatus, isLoading: false }));
    }
  }, [id]);

  const getOTLData = async () => {
    try {
      const response = await JobHai.get(`dynamic-dag/one-time/${id}`);
      const data = response.data.data;
      setDataList(data);
    } catch (error) {
      const msg = getErrorMessage(error);
      setStatus(prevStatus => ({ ...prevStatus, error: msg }));
    }
  };

  useEffect(() => {
    if (isAccess && id) {
      getDagInfo();
      getOTLData();
    }
  }, [isAccess, id, getDagInfo]);

  const handleChange = ({ target: { name, value } }) => {
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleBooleanChips = (event, fieldName, item) => {
    let id = event.currentTarget.dataset.id;
    setActiveBoolen(activeBoolean.id ? [] : item);
  };

  const saveQuery = async () => {
    const { query, destination_table_name, source, destination_schema } =
      formData;
    if (!query || !destination_schema) {
      setStatus(prevStatus => ({
        ...prevStatus,
        error: "Please write valid SQL query and enter destination schema"
      }));
      return;
    }
    setStatus(prevStatus => ({ ...prevStatus, error: "" }));
    setStatus(prevStatus => ({ ...prevStatus, btnDisabled: true }));
    try {
      const apiURL = id ? `dynamic-dag/${id}` : `dynamic-dag`;
      const response = await JobHai.post(apiURL, {
        query,
        destination_table_name,
        source,
        destination_schema,
        is_active: activeBoolean?.label
          ? findBooleanState(activeBoolean?.label)
          : null
      });
      const data = response.data.data;
      if (!id && data?.id) window.location = `${DYNAMIC_DAG}/${data.id}`;
      setStatus(prevStatus => ({
        ...prevStatus,
        success: "Data Saved",
        btnDisabled: false
      }));
      setTimeout(() => {
        setStatus(prevStatus => ({ ...prevStatus, success: null }));
      }, 2000);
    } catch (error) {
      setStatus(prevStatus => ({
        ...prevStatus,
        error: getErrorMessage(error),
        btnDisabled: false
      }));
    }
  };

  const cancelRun = async item => {
    if (window.confirm("Are you sure you want cancel?")) {
      const { dag_run_id, dag_id } = item;
      try {
        await JobHai.post(`dynamic-dag/cancel-run`, { dag_run_id, dag_id });
        getOTLData();
      } catch (error) {
        const msg = getErrorMessage(error);
        setStatus(prevStatus => ({ ...prevStatus, error: msg }));
      }
    }
  };

  const { destination_table_name, query, source, destination_schema } =
    formData;
  const { error, success, isLoading, btnDisabled } = status;
  const disabled =
    !query || !source || !destination_schema || !destination_table_name;

  return (
    <div className="dragbuilder">
      <div className="gap-20" />
      <div className="pageHeading row">
        <h2>
          {id ? `Edit ` : `Add `} Query{" "}
          <div className="float-right">
            <a href={DYNAMIC_DAG}>Go Back</a>
          </div>
        </h2>
      </div>
      <div className="gap-20" />
      <div className="whitebox">
        {isAccess ? (
          <>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                <Heading heading="Query Details" />
                <div className="formRow">
                  <div className="left">
                    <Label lable="Destination Table Name" />
                    <CustomTextField
                      value={destination_table_name}
                      onChange={handleChange}
                      name="destination_table_name"
                      placeholder="Destination Table Name"
                      maxLength={100}
                    />
                  </div>
                  <div className="right">
                    <div className="sourceStatus">
                      <div className="source">
                        <Label lable="Source" />
                        <CustomSelect
                          name="source"
                          value={source}
                          onChange={handleChange}
                          defaultPlaceholder="Select Source"
                          optionsList={dagBuilderSource}
                        />
                      </div>
                      <div className="status">
                        <Label lable="Is Active" />
                        <Chips
                          chips={booleanOptions}
                          activeChips={activeBoolean}
                          onClick={handleBooleanChips}
                          isClose={true}
                          fieldName="is_active"
                          isMulti={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="formRow">
                  <div className="fullWidth">
                    <Label lable="Query" />
                    <CustomTextField
                      value={query}
                      onChange={handleChange}
                      name="query"
                      textArea={true}
                      rows="24"
                      placeholder="Enter your query"
                    />
                  </div>
                </div>
                <div className="formRow">
                  <div className="fullWidth">
                    <Label lable="Destination Schema" />
                    <CustomTextField
                      value={destination_schema}
                      onChange={handleChange}
                      name="destination_schema"
                      textArea={true}
                      rows="24"
                      placeholder="Enter destination schema"
                    />
                  </div>
                </div>
                <div className="formRow">
                  <div className="form-row fullWidth">
                    <div className="col-md-12">
                      <div className="float-right">
                        {error && <span className="text-danger">{error} </span>}
                        {success && (
                          <span className="text-success">{success} </span>
                        )}
                        {id && <OTLButton id={id} callBack={getOTLData} />}
                        <button
                          className="btn btn-primary marginleft"
                          disabled={disabled || btnDisabled}
                          onClick={saveQuery}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {id && (
                  <OTLDataList dataList={dataList} cancelRun={cancelRun} />
                )}
              </>
            )}
          </>
        ) : (
          <NoAccess />
        )}
      </div>
    </div>
  );
};

export default AddDagBuilder;
