import BootstrapTable from "react-bootstrap-table-next";
import CustomePagination from "../Common/Pagination";

import moment from "moment";
import { getEditPageURL } from "../../services/utils";

const columns = [
  {
    text: "Transaction Type",
    dataField: "transactionLabel",
    formatter: (cellContent, row) => {
      const jobId = row?.jobId;
      const documentUrl = row?.documentUrl;
      const recrutierId = row?.recrutierId;
      const remark = row?.remark;
      const editURL = getEditPageURL("VERIFIED", jobId, recrutierId);
      return jobId ? (
        row?.transactionLabel
      ) : (
        <>
          {row?.transactionLabel}{" "}
          {documentUrl ? (
            <a
              href={documentUrl}
              target="_blank"
              rel="noreferrer"
              title={remark}
            >
              <i className="fa fa-eye" />
            </a>
          ) : null}
        </>
      );
    }
  },
  {
    text: "Credits",
    dataField: "credits",
    sort: true
  },
  {
    text: "Status",
    dataField: "status",
    sort: true
  },
  {
    text: "Created At",
    dataField: "transactionDate"
  },
  {
    text: "Last Updated",
    dataField: "lastUpdated",
    formatter: (cellContent, row) => {
      return moment(row.lastUpdated).format("DD-MM-YYYY h:mm");
    }
  },
  {
    text: "Closing Balance",
    dataField: "closingBalance"
  },
  {
    text: "Expiry On",
    dataField: "expirationDate",
    formatter: (cellContent, row) => {
      return moment(row.expirationDate).isValid()
        ? moment(row.expirationDate).format("DD-MM-YYYY")
        : "";
    }
  },
  {
    text: "Basic Value",
    dataField: "amount",
    sort: true
  }
];

function TransactionHistoryTable({ data, fetchData }) {
  const tableData = data?.data;
  if (data?.state === "IDLE") return "";

  if (data?.state === "FAILED")
    return data?.errorMsg || "Error in loading transactions data";
  if (data?.state === "LOADING") return "Loading transactions data";

  return (
    <div className={"whitebox"}>
      <BootstrapTable
        keyField="moderator_id"
        data={tableData?.history || []}
        columns={columns}
        hover
        condensed
        bordered={false}
        loading={true}
        rowClasses="listvalue"
        headerClasses="listHeding"
        classes="borderless"
        noDataIndication={() => <div>No data</div>}
      />
      <CustomePagination
        activePage={tableData?.page}
        itemsCountPerPage={10}
        totalItemsCount={tableData?.totalCount}
        onPageChange={pageNo => fetchData({ page: pageNo })}
        showPagination={true}
        showLimitedRow={true}
      />
      <div className="clr gap-20" />
    </div>
  );
}

export default TransactionHistoryTable;
