import React from "react";

const UploadFiles = props => {
  const {
    fileUrl = "",
    fileName = "",
    fieldName = "document",
    handleFileUpload
  } = props;
  return (
    <div className="customFileClass">
      {fileUrl ? (
        <>
          <div className="left">
            <a
              href={fileUrl}
              target="_blank"
              rel="noopener noreferrer"
              title={"Click here to view document"}
            >
              <img
                src="/images/viewDoc.svg"
                alt="view"
                width="28"
                height="22"
              />
              View Document
            </a>
          </div>
          <div className="right">
            <label className="custom-file-upload-edit">
              <input type="file" name={fieldName} onChange={handleFileUpload} />
              <img
                src={"/images/edit.svg"}
                alt="edit document"
                width="16"
                height="16"
              />
            </label>
          </div>
        </>
      ) : (
        <div className="left">
          <label className="ducument-file-upload">
            <input type="file" name={fieldName} onChange={handleFileUpload} />
            <img
              src={"/images/upload.svg"}
              alt="add document"
              width="24"
              height="19"
            />
            <span>{fileName ? fileName : `Upload Document`}</span>
          </label>
        </div>
      )}
    </div>
  );
};

export default UploadFiles;
