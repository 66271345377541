import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import cx from "classnames";
import {
  getCatSectionVariableData,
  saveCatSectionVariableData
} from "../../../services/metadataApiServices";
import SelectDropdown from "../../ReactSelect";
import classes from "./catAssignmentVarForm.module.css";
import { CircularProgress } from "@material-ui/core";
import PopupMessage from "../../PopupMessage";

const onFormSubmit = ({ data, optionsObj, idKeyObj, apiMethod }) => {
  for (let key in data) {
    const formIdSet = new Set(data[key].map(item => item[idKeyObj[key]]));
    const selectedValues = optionsObj[key].filter(item => item.cat_cohort[key]);
    const combinedData = [
      ...formIdSet,
      ...selectedValues.map(item => item[idKeyObj[key]])
    ];
    const combinedDataSet = new Set(combinedData);
    let apiPayload = [];
    combinedDataSet.forEach(item => {
      apiPayload.push({
        [idKeyObj[key]]: item,
        isEnabled: formIdSet.has(item)
      });
    });
    data[key] = apiPayload;
  }
  apiMethod(data);
};

const ParentForm = props => {
  const { cityList, categoryList, getApiData, saveApiData } = props;
  const selectedSouthCities = cityList.filter(
    item => item.cat_cohort.SOUTH_CITIES
  );
  const selectedTopCities = cityList.filter(
    item => item.cat_cohort.TOP_10_CITIES
  );
  const selectedTopCategories = categoryList.filter(
    item => item.cat_cohort.TOP_CATEGORY
  );
  const callSaveApi = async data => {
    await saveApiData(data);
    getApiData();
  };
  const onSubmit = data => {
    onFormSubmit({
      data,
      optionsObj: {
        SOUTH_CITIES: cityList,
        TOP_10_CITIES: cityList,
        TOP_CATEGORY: categoryList
      },
      idKeyObj: {
        SOUTH_CITIES: "_id",
        TOP_10_CITIES: "_id",
        TOP_CATEGORY: "id"
      },
      apiMethod: callSaveApi
    });
  };
  const method = useForm({
    defaultValues: {
      SOUTH_CITIES: selectedSouthCities,
      TOP_10_CITIES: selectedTopCities,
      TOP_CATEGORY: selectedTopCategories
    }
  });
  const { handleSubmit } = method;
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CityGroup cityList={cityList} method={method} />
        <div className={classes.divider} />
        <CategoryGroup categoryList={categoryList} method={method} />
        <button
          className={cx("btn btn-primary", classes.submitBtnClass)}
          type="submit"
        >
          Submit
        </button>
      </form>
    </>
  );
};

const CityGroup = props => {
  const { cityList, method } = props;
  const { control, getValues, watch, trigger } = method;
  const southCitiesWatcher = watch("SOUTH_CITIES");
  const topCitiesWatcher = watch("TOP_10_CITIES");
  const hasCommonElements = () => {
    const southCities = getValues("SOUTH_CITIES");
    const topCities = getValues("TOP_10_CITIES");
    const southCitiesIdSet = new Set(southCities.map(obj => obj._id));
    let commonElements = [];
    for (let item of topCities) {
      if (southCitiesIdSet.has(item._id)) {
        commonElements.push(item.label);
      }
    }
    return { commonElementFound: commonElements.length, commonElements };
  };
  const validateSouthCities = value => {
    trigger("TOP_10_CITIES");
    if (!value || value.length === 0) {
      return "Field is required";
    }
    return true;
  };
  const validateTopCities = value => {
    if (!value || value.length === 0) {
      return "Field is required";
    }
    const elementsRequired = 10;

    if (value.length !== elementsRequired) {
      return `Field must contain ${elementsRequired} elements`;
    }

    const { commonElementFound, commonElements } = hasCommonElements();
    if (commonElementFound) {
      return `Top 10 Cities must not contain cities present in South Cities(${commonElements.join(
        ","
      )})`;
    }

    return true;
  };
  return (
    <div>
      <h5>City Group Selection</h5>
      <div className={classes.formElementsCnt}>
        <SelectDropdown
          fullWidth={false}
          name="SOUTH_CITIES"
          label="Select south cities"
          control={control}
          options={cityList}
          closeMenuOnSelect={false}
          validateArray={validateSouthCities}
          isMulti
          isSearchable
          customClass={classes.dropdownCnt}
          helpText={
            southCitiesWatcher?.length
              ? `${southCitiesWatcher.length} Cities selected`
              : null
          }
        />
        <SelectDropdown
          fullWidth={false}
          name="TOP_10_CITIES"
          label="Select top 10 cities"
          control={control}
          options={cityList}
          closeMenuOnSelect={false}
          validateArray={validateTopCities}
          isMulti
          isSearchable
          customClass={classes.dropdownCnt}
          helpText={
            topCitiesWatcher?.length
              ? `${topCitiesWatcher?.length} Cities selected`
              : null
          }
        />
      </div>
    </div>
  );
};

const CategoryGroup = props => {
  const { categoryList, method } = props;

  const validateCategories = value => {
    if (!value || value.length === 0) {
      return "Field is required";
    }
  };
  const { control, watch } = method;
  const categoryWatcher = watch("TOP_CATEGORY");
  return (
    <div>
      <h5>Category Group Selection</h5>
      <div className={classes.formElementsCnt}>
        <SelectDropdown
          fullWidth={false}
          name="TOP_CATEGORY"
          label="Select categories"
          control={control}
          options={categoryList}
          closeMenuOnSelect={false}
          labelKey={"title"}
          idKey={"id"}
          validateArray={validateCategories}
          isMulti
          isSearchable
          customClass={classes.dropdownCnt}
          helpText={
            categoryWatcher?.length
              ? `${categoryWatcher?.length} Categories selected`
              : null
          }
        />
      </div>
    </div>
  );
};

const CatAssignmentVarForm = () => {
  const [cityList, setCityList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [showLoader, setLoader] = useState(true);
  const [popupMessageObj, setPopupMessageObj] = useState({ showMsg: false });
  const saveApiData = async data => {
    try {
      setLoader(true);
      await saveCatSectionVariableData(data);
      setLoader(false);
      setPopupMessageObj({
        msgType: "success",
        msg: "Details have been saved successfully",
        showMsg: true
      });
    } catch (error) {
      console.error(error);
      setPopupMessageObj({
        msgType: "error",
        msg: "Error in saving the Data",
        showMsg: true
      });
      setLoader(false);
    }
  };

  const getApiData = async () => {
    try {
      setLoader(true);
      const data = await getCatSectionVariableData();
      setCategoryList(Object.values(data.categories));
      setCityList(Object.values(data.cities));
      setLoader(false);
    } catch (error) {
      console.error(error);
      setPopupMessageObj({
        msgType: "error",
        msg: "Something went wrong",
        showMsg: true
      });
      setLoader(false);
    }
  };

  useEffect(() => {
    getApiData();
  }, []);
  return (
    <>
      {showLoader ? (
        <div className={classes.loaderCnt}>
          <CircularProgress
            classes={{ root: classes.loaderStyles }}
            color="inherit"
          />
        </div>
      ) : (
        <>
          <PopupMessage
            data={popupMessageObj}
            click={() => setPopupMessageObj({ showMsg: false })}
          />
          <ParentForm
            cityList={cityList}
            getApiData={getApiData}
            saveApiData={saveApiData}
            categoryList={categoryList}
          />
        </>
      )}
    </>
  );
};

export default CatAssignmentVarForm;
