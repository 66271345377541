import { useState } from "react";
import { Card, CardHeader, CardTitle, CardContent } from "../../ui/card";
import { CoinsIcon } from "lucide-react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "../../ui/accordion";
import { customUseEffect } from "../../../services/utils";
import { customDateFormat } from "../../../services/utilsV2";
import LinkGenerate from "../../../components/CreditWidget/LinkGenerate";
import CreditsAddition from "../../../components/CreditWidget/CreditsAddition";
import CreditsTransfer from "../../../components/CreditWidget/CreditsTransfer";
import CreditsPurge from "../../../components/CreditWidget/CreditsPurge";
import { getData } from "../../../components/CreditWidget/helper";

const STATES = {
  FETCHING: "FETCHING",
  FETCHED: "FETCHED",
  ERROR: "ERROR"
};

const CreditWidget = ({ recId }) => {
  const [dataState, setDataState] = useState();
  const [data, setData] = useState({});
  const [totalCredits, setTotalCredits] = useState(0);

  customUseEffect(() => {
    setDataState(STATES.FETCHING);
    if (recId) {
      getData(recId)
        .then(response => {
          setDataState(STATES.FETCHED);
          setTotalCredits(response?.totalAvailableCredits);
          setData(response);
        })
        .catch(() => {
          setDataState(STATES.ERROR);
        });
    }
  }, [recId]);

  const {
    monthlyBasicJobsLeft,
    showExpiringCredits,
    creditsGettingExpired,
    creditsDateOfExpiry,
    visible,
    adminVisible
  } = data;
  return (
    <Card>
      <CardHeader>
        <div className="flex  items-center space-x-3">
          <div className=" size-10 bg-blue-50 flex items-center text-blue-500 rounded-md   justify-center">
            <CoinsIcon />
          </div>
          <CardTitle className="text-xl">Credit</CardTitle>
        </div>
      </CardHeader>
      <CardContent className="grid gap-4 insidesales">
        <Accordion type="single" collapsible>
          <div className=" grid grid-cols-2">
            {dataState === STATES.FETCHING ? (
              <>
                <div>
                  <div className="w-10 h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-2" />
                  <div className="w-10 h-4 bg-gray-200 rounded-full dark:bg-gray-700" />
                </div>
                <div>
                  <div className="w-8 h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-2" />
                  <div className="w-10 h-4 bg-gray-200 rounded-full dark:bg-gray-700" />
                </div>
              </>
            ) : (
              <>
                <div>
                  <div className=" text-2xl font-semibold text-green-500 mb-2">
                    {totalCredits}
                  </div>

                  <div className="text-gray-500">Total Credits</div>
                </div>
                <div>
                  <div className=" text-2xl font-semibold mb-2">
                    {monthlyBasicJobsLeft}
                  </div>
                  <div className="text-gray-500">Free Jobs left</div>
                </div>
                {showExpiringCredits && (
                  <div>
                    {creditsGettingExpired} Expiring on{" "}
                    {customDateFormat(creditsDateOfExpiry, "DD MMM, YYYY")}
                  </div>
                )}
              </>
            )}
          </div>
          {visible ? (
            <>
              <AccordionItem value="item-1">
                <AccordionTrigger>Payment Link Generator</AccordionTrigger>
                <AccordionContent className="bg-gray-50 p-3 py-6 rounded-xl mb-6 add-credit">
                  <LinkGenerate
                    admin={adminVisible}
                    recId={recId}
                    showTitle={false}
                  />
                </AccordionContent>
              </AccordionItem>

              {adminVisible ? (
                <>
                  <AccordionItem value="item-2">
                    <AccordionTrigger>Credit Addition</AccordionTrigger>
                    <AccordionContent className="bg-gray-50 p-3 py-6 rounded-xl mb-6">
                      <CreditsAddition
                        recId={recId}
                        setTotalCredits={setTotalCredits}
                        showTitle={false}
                      />
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-3">
                    <AccordionTrigger>Credit Transfer</AccordionTrigger>
                    <AccordionContent className="bg-gray-50 p-3 py-6 rounded-xl mb-6">
                      <CreditsTransfer
                        recId={recId}
                        setTotalCredits={setTotalCredits}
                        showTitle={false}
                      />
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="item-4">
                    <AccordionTrigger>Service de-activate</AccordionTrigger>
                    <AccordionContent className="bg-gray-50 p-3 py-6 rounded-xl mb-6">
                      <CreditsPurge
                        recId={recId}
                        setTotalCredits={setTotalCredits}
                        showTitle={false}
                      />
                    </AccordionContent>
                  </AccordionItem>
                </>
              ) : null}
            </>
          ) : null}
        </Accordion>
      </CardContent>
    </Card>
  );
};

export default CreditWidget;
