import JobHai from "../../api/JobHai";
import { checkPermission } from "../../services/utils";

const styles = theme => ({
  root: {
    color: "#414858",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    background: "#FFFFFF",
    border: "1px solid rgba(233, 236, 245, 0.6)",
    borderRadius: "10px",
    marginBottom: "20px",
    "& .flexRow": {
      display: "flex",
      flexDirection: "row"
    },
    "& .btn1": {
      width: "68px"
    },
    "& .btn2": {
      width: "82px",
      backgroundColor: "#ffffff",
      color: "#2a89f6",
      border: "1px solid #2a89f6"
    },
    "& .btns": {
      height: "44px",
      padding: "0px"
    },

    "& .spaceBetween": {
      justifyContent: "space-between"
    },
    "& .justifyCenter": {
      justifyContent: "center"
    },
    "& .center": {
      alignItems: "center"
    },
    "& .flex": {
      display: "flex"
    },
    "& .container2Padding": {
      paddingRight: "12px"
    },
    "& .disabled": {
      opacity: 0.5
    },
    "& .loading": {
      marginBottom: "10px"
    }
  },
  container2: {
    "& :last-child": {
      marginLeft: "auto"
    }
  },
  container3: {
    "& :first-child": {
      marginRight: "5px"
    }
  },
  padding: {
    padding: "16px 28px"
  },
  component1: {
    "& img": {
      marginRight: "9px"
    },
    "& .bottomSpacing": {
      marginBottom: "8px"
    }
  },
  header: {
    background: "rgba(233, 236, 245, 0.3)",
    border: "1px solid #F1F3F9",
    borderRadius: "10px",
    color: "#41485A",
    "& .container2Child1": {
      marginRight: "18px"
    }
  },
  input: {
    width: "91px",
    color: "#2e2e36",
    fontWeight: "600",
    "&::placeholder": {
      color: "#8D9DC1",
      fontWeight: "400"
    }
  },
  jobLoader: { height: "16px", width: "40%" },
  successState: {
    color: "#18C1A3",
    fontStyle: "italic",
    display: "inline-block",
    marginLeft: "14px",
    fontWeight: "600"
  },
  errorState: {
    color: "red",
    fontStyle: "italic",
    display: "inline-block",
    marginLeft: "14px",
    fontWeight: "600"
  }
});

const formatResponse = data => {
  return {
    free: Number(data.free_job_count_available),
    paid: Number(data.paid_job_count_available),
    visible: checkPermission("Purchase Allowed"),
    exceptionForJobPost: data.exception_for_job_post
  };
};
const getData = recId => {
  return new Promise((resolve, reject) => {
    JobHai.post("/get-posted-job-count", { recruiter_id: recId })
      .then(res => {
        resolve(formatResponse(res.data.data));
      })
      .catch(err => reject(err));
  });
};
const postData = async (recId, count) => {
  const res = await JobHai.post("purchase_extra_job", {
    recruiter_id: recId,
    purchase_count: Number(count)
  });
  return formatResponse({
    ...res.data.data
  });
};
export { styles, getData, postData };
