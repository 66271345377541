import DocumentTitle from "react-document-title";
import TransactionHistorySearch from "./TransactionHistorySearch";
import TransactionHistoryRecruiterDetails from "./TransactionHistoryRecruiterDetails";
import { useEffect, useState } from "react";
import JobHai from "../../api/JobHai";
import React from "react";
import TransactionHistoryTable from "./TransactionHistoryTable";
import CreditReserved from "./CreditReservedTable";
import withRouter from "../../services/withRouter";
import { customUseEffect, getErrorMessage } from "../../services/utils";
import HeadingRow from "../Common/HeadingRow";
import { RECUITERS } from "../../routes/RouteConstants";

const fetchRecruiterData = async ({ filters }) => {
  const response = await JobHai.get(`/transactions/recruiter-profile`, {
    params: { ...filters }
  });

  let data = response.data.data;

  return data;
};

const fetchTransactionData = async ({ params }) => {
  const view = params.view;
  let url = "/transactions/transaction-history";
  if (view === "creditReserved") url = "/transactions/reserve-credit-history";
  delete params.view;
  const formattedParams = {};
  for (let key in params) {
    formattedParams[key] = params[key] || undefined;
  }

  const response = await JobHai.get(`${url}`, {
    params: {
      ...formattedParams,
      page: formattedParams.page || 1,
      recruiterNumber: formattedParams.recruiterNumber || undefined,
      recruiterId: formattedParams.recruiterId || undefined,
      size: "10",
      status: formattedParams.status || undefined
    }
  });

  let data = response.data.data;
  return data;
};
function TransactionHistory(props) {
  const [recruiterData, setRecruiterData] = useState({ state: "IDLE" });
  const [pageData, setPageData] = useState({ state: "IDLE" });

  const searchParams = window.location.search || "";

  const queryParams = new URLSearchParams(searchParams);
  const recId = queryParams.get("recruiterId");
  const recPhone = queryParams.get("recruiterPhone");
  const view = queryParams.get("view");
  const defaultStatusFilter = view === "creditReserved" ? "PENDING" : undefined;
  const onSearch = data => {
    if (!data) return;
    if (!data.recruiterId && !data.recruiterPhone) return;
    if (data.recruiterId && data.recruiterPhone) {
      alert("Please use either  the recruiterId or the recruiterPhone");
      return;
    }

    props.navigate(
      `/recruiters/transaction-history?${(() => {
        let pageView = view === "creditReserved" ? `&view=creditReserved` : "";
        if (data.recruiterPhone)
          return `recruiterPhone=${data.recruiterPhone}${pageView}`;
        else return `recruiterId=${data.recruiterId}${pageView}`;
      })()}`
    );
    getRecruiterData();
    getPageData({});
  };

  const [formData, setFormData] = useState({});
  useEffect(() => {
    setFormData({
      recruiterId: (() => {
        let id;
        try {
          id = JSON.parse(recId);
        } catch (e) {}
        return id || undefined;
      })(),
      recruiterPhone: (() => {
        let phone;
        try {
          phone = JSON.parse(recPhone);
        } catch (e) {}
        return phone || undefined;
      })()
    });
  }, [recPhone, recId]);

  async function getRecruiterData() {
    try {
      setRecruiterData({ ...recruiterData, state: "LOADING" });

      const res = await fetchRecruiterData({
        filters: {
          recruiterNumber: formData.recruiterPhone || recPhone,
          recruiterId: formData.recruiterId || recId
        }
      });
      setRecruiterData({ ...recruiterData, data: res, state: "FETCHED" });
    } catch (error) {
      setRecruiterData({
        ...recruiterData,
        errorMsg: getErrorMessage(error),
        state: "FAILED"
      });
    }
  }

  async function getPageData(data) {
    try {
      setPageData({ ...pageData, state: "LOADING" });
      const res = await fetchTransactionData({
        params: {
          view: view,
          recruiterNumber: formData.recruiterPhone || recPhone,
          recruiterId: formData.recruiterId || recId,
          page: data?.page,
          status: defaultStatusFilter,
          ...data
        }
      });
      setPageData({
        ...pageData,
        data: res,
        state: "FETCHED"
      });
    } catch (error) {
      setPageData({
        ...pageData,
        errorMsg: getErrorMessage(error),
        state: "FAILED"
      });
    }
  }
  customUseEffect(() => {
    if (recPhone || recId) getRecruiterData();
    if (recPhone || recId) getPageData({});
  }, []);

  function filterSearch(data) {
    getPageData(data);
  }
  const heading =
    view === "creditReserved"
      ? "Reserved Credits"
      : "Recruiters Transaction History";
  return (
    <div className="job_seeker recruiter-search">
      <DocumentTitle title={"Job Hai CRM - Transaction History"} />
      <div>
        <div className="clr gap-20" />
        <HeadingRow heading={heading} goBackURL={RECUITERS} />
        <TransactionHistorySearch
          formData={formData}
          setFormData={setFormData}
          onSearch={onSearch}
        />
        <div className="clr gap-20" />
        <TransactionHistoryRecruiterDetails
          view={view}
          recruiterData={recruiterData}
          pageSearchData={{ recId, recPhone }}
          filterSearch={filterSearch}
        />
        <div className="clr gap-20" />
        {view !== "creditReserved" && (
          <TransactionHistoryTable fetchData={getPageData} data={pageData} />
        )}
        {view === "creditReserved" && (
          <CreditReserved fetchData={getPageData} data={pageData} />
        )}
      </div>
    </div>
  );
}

export default React.memo(withRouter(TransactionHistory));
