import React from "react";
import GoBack from "../Common/GoBack";
import RecruiterStatus from "./RecruiterStatus";
import { isRecruiterVerified } from "../../services/utils";

const Tabs = props => {
  const {
    type = "",
    isSticky,
    href,
    onClick,
    recruiterStatus = "",
    isRecEdited = false,
    jobEdited = false
  } = props;
  const isVerified = isRecruiterVerified(recruiterStatus);
  return (
    <div className="job-tab">
      {isSticky ? <GoBack href={href} /> : null}
      <ul className="tabs">
        <li
          className={type === "company" ? "active" : ""}
          onClick={() => onClick("company")}
        >
          Recruiter Verification
          {isRecEdited ? (
            <img
              src="/images/warning.svg"
              alt="rec-edited"
              width="16"
              height="16"
            />
          ) : (
            <>
              {type === "jobs" ? (
                <>
                  {isVerified ? (
                    <img
                      src="/images/rec-verified.svg"
                      alt="rec-verified"
                      width="14"
                      height="14"
                    />
                  ) : (
                    <RecruiterStatus recruiterStatus={recruiterStatus} />
                  )}
                </>
              ) : null}
            </>
          )}
        </li>
        <li
          className={type === "jobs" ? "active" : ""}
          onClick={() => onClick("jobs")}
        >
          Job Verification
          {jobEdited ? (
            <img
              src="/images/warning.svg"
              alt="rec-edited"
              width="16"
              height="16"
            />
          ) : null}
        </li>
      </ul>
    </div>
  );
};
export default Tabs;
