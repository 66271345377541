import { useEffect, useState } from "react";
import { FilterIcon } from "lucide-react";
import DatePicker from "react-datepicker";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger
} from "../../ui/sheet";
import { Label } from "../../ui/label";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import useInsideCompaniesStore from "../../../zustandService/useInsideCompaniesStore";
import { getFormattedValue } from "../../../services/utils";
import {
  formatDateTime,
  hasAnyValue,
  parseDateStringToDate,
  validateDateRange,
  validateMinMax
} from "../../../services/utilsV2";
import AssignedTo from "../AssignedTo";
import CustomSelect from "../../ui/CustomSelect";

const CompaniesFilters = () => {
  const [fieldItems, setFieldItems] = useState({});
  const [errorMesage, setErrorMessage] = useState("");
  const [resetFilter, setResetFilter] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const {
    updateFilterData,
    initialFilters,
    defaultFilters,
    updateInitialFilters
  } = useInsideCompaniesStore(state => ({
    updateFilterData: state.updateFilterData,
    initialFilters: state.initialFilters,
    defaultFilters: state.defaultFilters,
    updateInitialFilters: state.updateInitialFilters
  }));
  useEffect(() => {
    if (Object.keys(initialFilters).length > 0) {
      setResetFilter(hasAnyValue(initialFilters));
      setFieldItems({ ...initialFilters });
    }
  }, []);

  const resetFieldItems = () => {
    setFieldItems(defaultFilters);
    setErrorMessage("");
  };

  const resetFilters = () => {
    setFieldItems(defaultFilters);
    setResetFilter(false);
    updateInitialFilters(defaultFilters);
    updateFilterData({});
    setErrorMessage("");
  };

  const handleChange = e => {
    let { name, value } = e.target;
    if (name === "orgId") {
      value = getFormattedValue(value, true);
    }
    setFieldItems({
      ...fieldItems,
      [name]: value
    });
  };

  const handleDateChange = (field, date) => {
    setFieldItems({
      ...fieldItems,
      [field]: date
    });
  };

  const handleOptionChange = option => {
    setFieldItems({
      ...fieldItems,
      cleaningStatus: option?.id || ""
    });
  };

  const handleMinMaxChange = (field, e) => {
    let { name, value } = e.target;
    setFieldItems(prevState => ({
      ...prevState,
      [field]: { ...prevState[field], [name]: getFormattedValue(value, true) }
    }));
  };

  const handleSubmit = () => {
    const filters = {};
    setErrorMessage("");
    // Assign filters from fieldItems
    const fieldsToMap = [
      { key: "orgName", isArray: false },
      { key: "orgId", isArray: true },
      { key: "moderatorIds", isArray: true },
      { key: "cleaningStatus", isArray: false }
    ];
    fieldsToMap.forEach(({ key, isArray }) => {
      if (fieldItems[key]) {
        filters[key] = isArray ? [Number(fieldItems[key])] : fieldItems[key];
      }
    });

    // Validate date ranges
    const dateFields = [
      { field: "assignedDate", label: "Assigned date" },
      { field: "unallocationDate", label: "Expiry date" }
    ];

    for (const { field, label } of dateFields) {
      const dateError = validateDateRange(fieldItems, field, label);
      if (dateError) {
        setErrorMessage(dateError);
        return;
      }
      const { from, to } = fieldItems[field];
      if (from || to) {
        filters[field] = {
          from: formatDateTime(from),
          to: formatDateTime(to, "23:59:59")
        };
      }
    }

    // Validate min-max ranges
    const minMaxFields = [
      { field: "creditsAvailable", label: "Credits Available" },
      { field: "creditsPurchased", label: "Credits purchased" },
      { field: "verifiedRecruitersCount", label: "Verified Recruiters" }
    ];

    for (const { field, label } of minMaxFields) {
      const minMaxerror = validateMinMax(fieldItems, field, label);
      if (minMaxerror) {
        setErrorMessage(minMaxerror);
        return;
      }
      const { min, max } = fieldItems[field];
      if (min || max) {
        filters[field] = {
          min: min ? Number(min) : undefined,
          max: max ? Number(max) : undefined
        };
      }
    }
    if (filters.cleaningStatus) {
      filters.isCompanyReviewed = filters.cleaningStatus === "REVIEWED" ? 1 : 0;
      delete filters.cleaningStatus;
    }
    updateFilterData({ ...filters });
    updateInitialFilters(fieldItems);
    setResetFilter(hasAnyValue(filters));
    handleClose();
  };
  const cleaningStatusList = [
    { id: "REVIEWED", name: "Reviewed" },
    { id: "NOT_REVIEWED", name: "Not Reviewed" }
  ];

  return (
    <div className="flex items-center gap-3">
      <Sheet>
        <SheetTrigger>
          {resetFilter && (
            <Button
              onClick={resetFilters}
              className="mr-2 bg-blue-500 border border-blue-500 text-white hover:bg-blue-600"
            >
              Reset Filter
            </Button>
          )}
          <Button
            variant="outline"
            className="ml-2"
            size="icon"
            onClick={handleOpen}
          >
            <FilterIcon className="w-4 h-4" />
          </Button>
        </SheetTrigger>
        <SheetContent
          isOpen={isOpen}
          handleClose={handleClose}
          className="overflow-y-auto max-h-full filter"
        >
          <SheetHeader>
            <SheetTitle>Filters</SheetTitle>
          </SheetHeader>
          <div className="flex flex-col gap-4 mt-12">
            <div className="grid gap-2">
              <Label htmlFor="companyName" className="text-sm font-medium">
                Company Name
              </Label>
              <Input
                id="orgName"
                name="orgName"
                type="text"
                placeholder="Eg. InfoEdge"
                value={fieldItems.orgName || ""}
                onChange={handleChange}
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="orgId" className="text-sm font-medium">
                Organization Id
              </Label>
              <Input
                id="orgId"
                name="orgId"
                type="tel"
                value={fieldItems.orgId || ""}
                onChange={handleChange}
                maxLength={10}
                placeholder="Eg. 12345"
              />
            </div>
            <div className="grid gap-2">
              <Label className="text-sm font-medium">Credits Purchased</Label>
              <div className="flex items-center space-x-2">
                <Input
                  type="tel"
                  placeholder="Min"
                  value={fieldItems.creditsPurchased?.min || ""}
                  name="min"
                  onChange={e => handleMinMaxChange("creditsPurchased", e)}
                  className="w-40"
                />
                <Input
                  type="tel"
                  placeholder="Max"
                  value={fieldItems.creditsPurchased?.max || ""}
                  name="max"
                  onChange={e => handleMinMaxChange("creditsPurchased", e)}
                  className="w-40"
                />
              </div>
            </div>
            <div className="grid gap-2">
              <Label className="text-sm font-medium">Credits Available</Label>
              <div className="flex items-center space-x-2">
                <Input
                  type="tel"
                  placeholder="Min"
                  value={fieldItems.creditsAvailable?.min || ""}
                  name="min"
                  onChange={e => handleMinMaxChange("creditsAvailable", e)}
                  className="w-40"
                />
                <Input
                  type="tel"
                  placeholder="Max"
                  value={fieldItems.creditsAvailable?.max || ""}
                  name="max"
                  onChange={e => handleMinMaxChange("creditsAvailable", e)}
                  className="w-40"
                />
              </div>
            </div>
            <div className="grid gap-2">
              <Label className="text-sm font-medium">Verified Recruiters</Label>
              <div className="flex items-center space-x-2">
                <Input
                  type="tel"
                  placeholder="Min"
                  value={fieldItems.verifiedRecruitersCount?.min || ""}
                  name="min"
                  onChange={e =>
                    handleMinMaxChange("verifiedRecruitersCount", e)
                  }
                  className="w-40"
                />
                <Input
                  type="tel"
                  placeholder="Max"
                  value={fieldItems.verifiedRecruitersCount?.max || ""}
                  name="max"
                  onChange={e =>
                    handleMinMaxChange("verifiedRecruitersCount", e)
                  }
                  className="w-40"
                />
              </div>
            </div>
            {/* <div className="grid gap-2">
              <Label htmlFor="endDate">Assigned Date</Label>
              <div className="flex items-center space-x-2">
                <DatePicker
                  selected={parseDateStringToDate(
                    fieldItems.assignedDate?.from
                  )}
                  onSelect={date =>
                    handleDateChange("assignedDate", {
                      ...fieldItems.assignedDate,
                      from: date
                    })
                  }
                  placeholderText="From"
                  dateFormat="yyyy-MM-dd"
                  className="form-control w-30"
                />
                <DatePicker
                  selected={parseDateStringToDate(fieldItems.assignedDate?.to)}
                  onSelect={date =>
                    handleDateChange("assignedDate", {
                      ...fieldItems.assignedDate,
                      to: date
                    })
                  }
                  placeholderText="To"
                  dateFormat="yyyy-MM-dd"
                  className="form-control w-30"
                  minDate={parseDateStringToDate(fieldItems.assignedDate?.from)}
                />
              </div>
            </div> */}
            <div className="grid gap-2">
              <Label className="text-sm font-medium">Expiry Date</Label>
              <div className="flex items-center space-x-2">
                <DatePicker
                  selected={parseDateStringToDate(
                    fieldItems.unallocationDate?.from
                  )}
                  onSelect={date =>
                    handleDateChange("unallocationDate", {
                      ...fieldItems.unallocationDate,
                      from: date
                    })
                  }
                  placeholderText="From"
                  dateFormat="yyyy-MM-dd"
                  className="form-control w-30"
                />
                <DatePicker
                  selected={parseDateStringToDate(
                    fieldItems.unallocationDate?.to
                  )}
                  onSelect={date =>
                    handleDateChange("unallocationDate", {
                      ...fieldItems.unallocationDate,
                      to: date
                    })
                  }
                  placeholderText="To"
                  dateFormat="yyyy-MM-dd"
                  className="form-control w-30"
                  minDate={parseDateStringToDate(
                    fieldItems.unallocationDate?.from
                  )}
                />
              </div>
            </div>
            <AssignedTo fieldItems={fieldItems} setFieldItems={setFieldItems} />
            <div className="grid gap-2">
              <Label htmlFor="cleaningStatus">Cleaning Status</Label>
              <CustomSelect
                options={cleaningStatusList}
                value={fieldItems.cleaningStatus}
                onChange={handleOptionChange}
                placeholder={"Select Cleaning Status"}
              />
            </div>
          </div>
          {errorMesage && (
            <div className="flex text-red-500 mt-6">{errorMesage}</div>
          )}
          <SheetFooter className="flex justify-end mt-12">
            <Button variant="outline" onClick={resetFieldItems}>
              Reset
            </Button>
            <Button onClick={handleSubmit}>Apply Filter</Button>
          </SheetFooter>
        </SheetContent>
      </Sheet>
    </div>
  );
};

export default CompaniesFilters;
