import React from "react";
import DocumentTitle from "react-document-title";
import JobHai from "../../../api/JobHai";
import PopupMessage from "../../PopupMessage";
import LoadingSpinner from "../../LoadingSpinner";
import AdminPannelFilter from "./AdminPannelFilter";
import AdminPannelTabs from "./AdminPannelTabs";
import UnverifiedList from "./UnverifiedList";
import VerifiedList from "./VerifiedList";
import ReportedList from "./ReportedList";
import LeadList from "./LeadList";
import PageTabs from "../../Common/PageTabs";
import {
  ADMIN_PANEL,
  ANALYTICS,
  ENTERPRISE_LEADS
} from "../../../routes/RouteConstants";
import CatList from "./CatList";
import CatSubTabs from "./CatSubTabs";
import { getErrorMessage } from "../../../services/utils";

class AdminPannel extends React.Component {
  constructor(props) {
    super(props);
    const search = window.location.search || "";
    const queryParams = new URLSearchParams(search);
    const isNewCity = queryParams.get("isNewCity");
    const pageType = queryParams.get("pageType");
    this.state = {
      msgData: { msg: "", showMsg: false, msgType: "error" },
      isLoading: true,
      isButtonDisabled: false,
      type: pageType === "LEAD" ? "LEAD" : "UNVERIFIED",
      filters: {},
      dataList: [],
      totalData: {},
      isNewCity: isNewCity === "true",
      showTabs: pageType !== "LEAD",
      catSection: "FRESH"
    };
  }

  componentDidMount() {
    this.getReport();
  }

  getReport = () => {
    const { type, filters, isNewCity, catSection } = this.state;
    let queryString = Object.keys(filters)
      .map(key => key + "=" + filters[key])
      .join("&");
    let url = `moderator-report?type=${type}&is_new_city_tab=${isNewCity}`;
    if (type === "CAT") {
      url = `moderator-report-cat?cat_sub_section=${catSection}`;
    }
    if (queryString) url = `${url}&${queryString}`;
    this.setState({ isButtonDisabled: true });

    JobHai.get(url)
      .then(response => {
        let data = response.data.data;
        const {
          moderators = [],
          total = {},
          result = [],
          totalCount = []
        } = data;
        this.setState({
          isLoading: false,
          isButtonDisabled: false,
          dataList: type === "CAT" ? result : moderators,
          totalData: type === "CAT" ? totalCount : total,
          msgData: { msg: "", showMsg: false, msgType: "" }
        });
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setState({
          isLoading: false,
          isButtonDisabled: false,
          msgData: { msg: msg, showMsg: true, msgType: "error" }
        });
      });
  };

  handleTabClick = type => {
    this.setState(
      { type, dataList: [], catSection: "FRESH", filters: {}, isLoading: true },
      () => this.getReport()
    );
  };

  handleCatTab = catSection => {
    this.setState(
      { catSection, dataList: [], filters: {}, isLoading: true },
      () => this.getReport()
    );
  };

  searchReport = filters => {
    this.setState({ filters }, () => this.getReport());
  };

  handleClosePopup = () => {
    this.setState({ msgData: [{ msg: "", showMsg: false }] });
  };

  statusMessage = (msg, msgType) => {
    this.setState({
      msgData: {
        msg: msg,
        showMsg: true,
        msgType: !msgType ? "success" : "error"
      }
    });
  };

  render() {
    const {
      msgData,
      isLoading,
      isButtonDisabled,
      type,
      dataList,
      totalData,
      isNewCity,
      showTabs,
      catSection
    } = this.state;
    const pageTabs = [
      { label: "Old City", url: ADMIN_PANEL, isActive: !isNewCity },
      {
        label: "New City",
        url: `${ADMIN_PANEL}?isNewCity=true`,
        isActive: isNewCity
      }
    ];
    const goBackURL = showTabs ? ANALYTICS : ENTERPRISE_LEADS;
    const heading = showTabs ? "Admin Panel" : "Moderator Report";
    const showFilter = type === "CAT";
    return (
      <DocumentTitle title={"Job Hai CRM - Analytic"}>
        <div className="admin-pannel">
          <div className="clr gap-20" />
          <PopupMessage data={msgData} click={this.handleClosePopup} />
          <div className="pageHeading">
            <h2>
              {heading}
              {showTabs ? <PageTabs pageTabs={pageTabs} /> : null}
              <div className="float-right">
                <a href={goBackURL}>Go Back</a>
              </div>
            </h2>
          </div>
          {showTabs ? (
            <AdminPannelTabs
              handleClick={this.handleTabClick}
              isSelected={type}
              isNewCity={isNewCity}
            />
          ) : null}
          {type === "CAT" && (
            <CatSubTabs active={catSection} onClick={this.handleCatTab} />
          )}
          {showFilter && (
            <AdminPannelFilter
              searchReport={this.searchReport}
              statusMessage={this.statusMessage}
              isButtonDisabled={isButtonDisabled}
              tabType={catSection}
            />
          )}
          <div className="clr gap-20" />

          <div className="whitebox scrollX table-responsive">
            {isLoading ? (
              <>
                <div className="clr gap-20" />
                <LoadingSpinner />
              </>
            ) : (
              <>
                {type === "UNVERIFIED" || type === "CRAWLED" ? (
                  <UnverifiedList dataList={dataList} totalData={totalData} />
                ) : null}
                {type === "VERIFIED" ? (
                  <VerifiedList dataList={dataList} totalData={totalData} />
                ) : null}
                {type === "REPORTED" ? (
                  <ReportedList dataList={dataList} totalData={totalData} />
                ) : null}
                {type === "LEAD" ? (
                  <LeadList dataList={dataList} totalData={totalData} />
                ) : null}
                {type === "CAT" ? (
                  <CatList
                    dataList={dataList}
                    totalData={totalData}
                    catSection={catSection}
                  />
                ) : null}
              </>
            )}
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

export default AdminPannel;
