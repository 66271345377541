import React from "react";
import moment from "moment";
import LoadingSpinner from "../../LoadingSpinner";
import JobHai from "../../../api/JobHai";
import PopupMessage from "../../PopupMessage";
import {
  getErrorMessage,
  getFormattedValue,
  addDays,
  getCustomErrorMessage,
  checkPermission
} from "../../../services/utils";
import CrawledRecruiterList from "./CrawledRecruiterList";
import CustomePagination from "../../Common/Pagination";
import Bucket from "./Bucket";
import { COMPANY_EDIT_PAGE } from "../../../routes/RouteConstants";
import withRouter from "../../../services/withRouter";
import ProxyModeratorName from "../../Common/ProxyModeratorName";
import CustomCheckbox from "../../Common/CustomCheckbox";
import CrawledRecruiterFilter from "./CrawledRecruiterFilter";
import HeadingRow from "../../Common/HeadingRow";

class CrawledRecruiter extends React.Component {
  constructor(props) {
    super(props);
    const { params = {} } = props;
    this.stateRef = React.createRef();

    const { role } = params;
    const pathname = window.location.pathname || "";
    const queryParams = new URLSearchParams(window.location.search);
    const bucket = queryParams.get("bucket");
    const defaultFilter = this.getDefaultFilter(bucket);
    const isCatAdmin = checkPermission("CAT Assignment");
    this.state = {
      recList: [],
      initailRecList: [],
      role,
      isLoading: true,
      msgData: { msg: "", showMsg: false, msgType: "error" },
      isButtonDisabled: false,
      activePage: 1,
      count: 0,
      itemsCountPerPage: 50,
      filters: defaultFilter,
      savedId: "",
      successMessage: "",
      pathname,
      bucket,
      sectionData: {},
      errorMsg: "",
      saveBtnDisabled: false,
      selectedRows: [],
      isCatAdmin
    };
  }

  getDefaultFilter = bucket => {
    const todayDate = new Date();
    const date = moment(todayDate).format("YYYY-MM-DD");
    let defaultFilter =
      bucket === "follow_up"
        ? { follow_up_date: date }
        : bucket === "re_attempt" || bucket === "doc_chase"
        ? { attempt_date: date }
        : {};
    return bucket ? defaultFilter : { start_date: date };
  };

  handleClosePopup = () => {
    this.setState({ msgData: [{ msg: "", showMsg: false }] });
  };

  componentDidMount() {
    this.getRecruiters();
  }

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
  };

  componentDidUpdate(prevProps, prevState) {
    const { filters, activePage } = this.state;
    if (filters !== prevState.filters) {
      this.setState({
        filters: filters
      });
      this.getRecruiters();
    }
    if (activePage !== prevState.activePage) {
      this.setState({
        activePage: activePage
      });
      this.getRecruiters();
      window.scroll({ top: 0, behavior: "smooth" });
    }
  }

  getRecruiters = () => {
    const { activePage, itemsCountPerPage, filters, bucket, isCatAdmin } =
      this.state;
    let url = `/get-crawled-recruiters`;
    JobHai.post(url, {
      page: activePage - 1,
      size: itemsCountPerPage,
      is_admin: isCatAdmin,
      ...filters,
      bucketId: bucket ? bucket : undefined
    })
      .then(response => {
        const {
          recruiter_list = {},
          count = "",
          sectionData = {}
        } = response.data.data;
        this.setState({
          recList: recruiter_list,
          initailRecList: recruiter_list,
          count,
          sectionData,
          isLoading: false,
          isButtonDisabled: false
        });
        this.stateRef.current = recruiter_list;
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setState({
          isLoading: false,
          isButtonDisabled: false,
          count: 0,
          msgData: { msg: msg, showMsg: true, msgType: "error" }
        });
      });
  };

  searchRecruiter = filters => {
    this.setState({
      isButtonDisabled: true,
      activePage: 1,
      filters,
      msgData: {
        msg: "",
        showMsg: false,
        msgType: ""
      },
      savedId: "",
      successMessage: "",
      errorMsg: "",
      selectedRows: []
    });
  };

  handlePostJob = recId => {
    JobHai.post("/move-crawled-jobs", {
      recruiter_id: recId,
      job_ids: []
    })
      .then(response => {
        const jobId = response.data.data.length
          ? response.data.data[0].job_id
          : "";
        var url = `${COMPANY_EDIT_PAGE}/crawled/${this.state.role}/${jobId}/?recId=${recId}`;
        if (jobId) window.open(url, "_blank");
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setState({
          msgData: { msg: msg, showMsg: true, msgType: "error" }
        });
      });
  };

  handleChange = (recId, e, isFollowup = false) => {
    let name = "",
      value = "";

    if (isFollowup) {
      name = "follow_up_date";
      value = e;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    if (name === "select-row") {
      this.setState(prevState => ({
        selectedRows:
          !prevState.selectedRows.filter(item => item === recId).length > 0
            ? [...prevState.selectedRows, recId]
            : prevState.selectedRows.filter(item => item !== recId)
      }));
    }

    if (name === "already_verified_phone" || name === "referral_phone") {
      value = getFormattedValue(value, true, true);
    }

    const { recList } = this.state;
    let listArr = JSON.parse(JSON.stringify(recList));
    var foundIndex = listArr.findIndex(item => item.recruiter_id === recId);
    const curRecId = listArr.filter(item => item.recruiter_id === recId);
    if (curRecId.length > 0 && curRecId[0]) {
      curRecId[0][name] = value;
      if (name === "crawled_recruiter_call_feedback") {
        curRecId[0]["follow_up_date"] =
          value === "INTERESTED" ? addDays(new Date(), 1) : "";
      }
      if (name === "recruiter_calling_status") {
        curRecId[0]["crawled_recruiter_calling_sub_dispositions"] = "";
      }
      listArr[foundIndex] = curRecId[0];
      this.setState({ recList: listArr });
    }
    this.setState({
      savedId: "",
      successMessage: "",
      errorMsg: ""
    });
  };

  handleSave = async item => {
    const { initailRecList } = this.state;
    const {
      recruiter_id,
      recruiter_calling_status,
      recruiter_consent,
      crawling_remarks,
      crawled_recruiter_call_feedback,
      follow_up_date,
      link_shared,
      already_verified_phone,
      bucket_name = "",
      company_name = "",
      recruiter_online_jobs_consent,
      crawled_recruiter_calling_sub_dispositions,
      call_connected_other_reason
    } = item;

    const currentList = initailRecList.filter(
      rec => rec.recruiter_id === recruiter_id
    );
    const old_follow_up_date = currentList.length
      ? currentList[0].follow_up_date
      : "";
    const old_crawled_recruiter_call_feedback = currentList.length
      ? currentList[0].crawled_recruiter_call_feedback
      : "";
    const callingAttemptList = [
      "ATTEMPT_CONNECTED_1",
      "ATTEMPT_NOT_CONNECTED_1",
      "ATTEMPT_CONNECTED_2",
      "ATTEMPT_NOT_CONNECTED_2",
      "ATTEMPT_CONNECTED_3",
      "ATTEMPT_NOT_CONNECTED_3"
    ];
    const isCallingAttempt = callingAttemptList.includes(
      recruiter_calling_status
    );
    let followUpDate = null;
    const formattedFollowUpDateTime = moment(follow_up_date).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    if (follow_up_date && old_follow_up_date) {
      const formattedFollowUpDate = moment(follow_up_date).format("YYYY-MM-DD");
      const formattedOldFollowUpDate =
        moment(old_follow_up_date).format("YYYY-MM-DD");

      if (formattedFollowUpDate !== formattedOldFollowUpDate) {
        followUpDate = formattedFollowUpDateTime;
      } else {
        followUpDate = follow_up_date ? formattedFollowUpDateTime : null;
      }
    } else {
      followUpDate = follow_up_date ? formattedFollowUpDateTime : null;
    }

    let crawledRCF =
      crawled_recruiter_call_feedback &&
      old_crawled_recruiter_call_feedback !== crawled_recruiter_call_feedback
        ? crawled_recruiter_call_feedback
        : null;
    const isOtherSelected =
      crawled_recruiter_calling_sub_dispositions === "CONNECTED_OTHER" ||
      crawled_recruiter_calling_sub_dispositions === "NOT_CONNECTED_OTHER";
    if (isCallingAttempt && !crawled_recruiter_calling_sub_dispositions) {
      this.setState({
        errorMsg: "Please select calling sub status.",
        savedId: recruiter_id
      });
      return;
    } else if (
      isCallingAttempt &&
      isOtherSelected &&
      !call_connected_other_reason
    ) {
      this.setState({
        errorMsg: "Please enter enter other call status.",
        savedId: recruiter_id
      });
      return;
    } else if (
      crawled_recruiter_call_feedback === "INTERESTED" &&
      !follow_up_date
    ) {
      this.setState({
        errorMsg: "Follow up date can’t be blank for interested recruiters.",
        savedId: recruiter_id
      });
      return;
    } else if (
      crawled_recruiter_call_feedback === "NOT_INTERESTED" &&
      !crawling_remarks
    ) {
      this.setState({
        errorMsg: "Please add remarks, why not interested.",
        savedId: recruiter_id
      });
      return;
    }

    this.setState({
      errorMsg: "",
      savedId: recruiter_id,
      saveBtnDisabled: true
    });

    let obj = {
      recruiter_id,
      crawling_remarks,
      recruiter_consent: recruiter_consent
        ? Number(recruiter_consent)
        : undefined,
      crawled_recruiter_call_feedback: crawledRCF,
      follow_up_date: followUpDate,
      link_shared,
      already_verified_phone: already_verified_phone || null,
      bucket_name: bucket_name || undefined,
      company_name,
      recruiter_online_jobs_consent: recruiter_online_jobs_consent || undefined,
      crawled_recruiter_calling_sub_dispositions,
      call_connected_other_reason:
        crawled_recruiter_calling_sub_dispositions === "CONNECTED_OTHER"
          ? call_connected_other_reason
          : null,
      call_not_connected_other_reason:
        crawled_recruiter_calling_sub_dispositions === "NOT_CONNECTED_OTHER"
          ? call_connected_other_reason
          : null
    };

    if (
      recruiter_calling_status === "ATTEMPT_CONNECTED_1" ||
      recruiter_calling_status === "ATTEMPT_NOT_CONNECTED_1"
    ) {
      obj.recruiter_calling_first_status =
        recruiter_calling_status === "ATTEMPT_CONNECTED_1"
          ? "ATTEMPT_CONNECTED"
          : "ATTEMPT_NOT_CONNECTED";
    }
    if (
      recruiter_calling_status === "ATTEMPT_CONNECTED_2" ||
      recruiter_calling_status === "ATTEMPT_NOT_CONNECTED_2"
    ) {
      obj.recruiter_calling_second_status =
        recruiter_calling_status === "ATTEMPT_CONNECTED_2"
          ? "ATTEMPT_CONNECTED"
          : "ATTEMPT_NOT_CONNECTED";
    }
    if (
      recruiter_calling_status === "ATTEMPT_CONNECTED_3" ||
      recruiter_calling_status === "ATTEMPT_NOT_CONNECTED_3"
    ) {
      obj.recruiter_calling_third_status =
        recruiter_calling_status === "ATTEMPT_CONNECTED_3"
          ? "ATTEMPT_CONNECTED"
          : "ATTEMPT_NOT_CONNECTED";
    }

    try {
      await JobHai.post("/v2/save_recruiter", obj);
      this.setState({
        savedId: recruiter_id,
        successMessage: "Saved",
        errorMsg: "",
        saveBtnDisabled: false
      });
      this.getRecruiters();
    } catch (error) {
      let msg = getCustomErrorMessage(error);
      this.setState({
        msgData: { msg, showMsg: true, msgType: "error" },
        saveBtnDisabled: false
      });
    }
  };

  handleCheckboxChange = e => {
    const { checked } = e.target;
    const { recList } = this.state;
    const allRecIds = recList.map(rec => rec.recruiter_id);
    this.setState(prevState => ({
      selectedRows: checked ? [...allRecIds] : []
    }));
  };

  callBack = type => {
    const msg =
      type === "reassign" ? "Lead has been reassigned" : "Lead has been closed";
    this.setState({
      selectedRows: [],
      msgData: { msg, showMsg: true, msgType: "" }
    });
    this.getRecruiters();
  };

  render() {
    const {
      isButtonDisabled,
      recList,
      role,
      count,
      activePage,
      itemsCountPerPage,
      isLoading,
      msgData,
      savedId,
      successMessage,
      pathname,
      bucket,
      sectionData,
      errorMsg,
      saveBtnDisabled,
      selectedRows,
      isCatAdmin
    } = this.state;
    const isAllSelected = selectedRows.length === recList.length;
    const gobackUrl = bucket ? pathname : `/jobs/`;
    const showCheckBox = isCatAdmin && !bucket && recList.length;
    if (isLoading) {
      return <LoadingSpinner />;
    } else {
      return (
        <>
          <ProxyModeratorName />
          <div>
            <div className="clr gap-20" />
            <div className="clr">
              <HeadingRow heading="Crawled Leads" goBackURL={gobackUrl} />
              <div className="clr" />
              {!bucket ? (
                <Bucket pathname={pathname} sectionData={sectionData} />
              ) : null}

              <CrawledRecruiterFilter
                isButtonDisabled={isButtonDisabled}
                searchRecruiter={this.searchRecruiter}
                isBucket={!!bucket}
                bucketId={bucket}
                selectedRecruiters={selectedRows}
                callBack={this.callBack}
                isCatAdmin={isCatAdmin}
              />
              <PopupMessage data={msgData} click={this.handleClosePopup} />
              <div className="clr" />
              <div className="whitebox crawled-rec">
                <div className="react-bootstrap-table">
                  <table className="table table-hover table-condensed borderless">
                    <thead>
                      <tr className="listHeding">
                        {showCheckBox ? (
                          <th width="10">
                            <CustomCheckbox
                              onChange={this.handleCheckboxChange}
                              checked={isAllSelected}
                              indeterminate={
                                selectedRows.length && !isAllSelected
                              }
                            />
                          </th>
                        ) : null}
                        <th width="90">Recruiter Id</th>
                        <th width="170">Company Name</th>
                        <th width="120">Recruiter Name</th>
                        <th>City</th>
                        <th width="100">Pending Jobs</th>
                        <th>Phone no</th>
                        <th>Calling Status</th>
                        <th width="270">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recList.length > 0 ? (
                        <>
                          {recList.map((item, index) => {
                            return (
                              <CrawledRecruiterList
                                dataList={item}
                                role={role}
                                handlePostJob={this.handlePostJob}
                                handleChange={this.handleChange}
                                handleSave={this.handleSave}
                                savedId={savedId}
                                successMessage={successMessage}
                                key={`rec-list${index}`}
                                errorMsg={errorMsg}
                                saveBtnDisabled={saveBtnDisabled}
                                bucketId={bucket}
                                initailRecList={this.stateRef.current}
                                isCurrentSelected={selectedRows.includes(
                                  item.recruiter_id
                                )}
                                callBack={this.callBack}
                                showCheckBox={showCheckBox}
                                isCatAdmin={isCatAdmin}
                              />
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <tr>
                            <td colSpan={showCheckBox ? 9 : 8} align="center">
                              <div className="spinner">No Data Found</div>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="clr" />
                <CustomePagination
                  activePage={activePage}
                  itemsCountPerPage={itemsCountPerPage}
                  totalItemsCount={count}
                  onPageChange={this.handlePageChange}
                  showPagination={count > 0 ? true : false}
                />
                <div className="clr gap-20" />
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

export default withRouter(CrawledRecruiter);
