import React from "react";
import Select from "react-select";
import withRouter from "../../../services/withRouter";
import JobHai from "../../../api/JobHai";
import PopupMessage from "../../PopupMessage";
import "../../../styleNew.css";
import {
  getErrorMessage,
  checkPermission,
  getFormattedValue,
  getCustomErrorMessage,
  redirectURL
} from "../../../services/utils";
import { LEARN_TAB } from "../../../routes/RouteConstants";
import UploadFiles from "../../Common/Form/UploadFiles";
import {
  getCrmCityData,
  getLearnTabData
} from "../../../services/metadataApiServices";
import Heading from "../../Common/Form/Heading";
import CustomSelect from "../../Common/Form/CustomSelect";
import Label from "../../Common/Form/Label";
import CustomTextField from "../../Common/Form/CustomTextField";
import HeadingRow from "../../Common/HeadingRow";
import { ADD_VIDEO } from "../../../services/eventsLogs/eventConstants";
import { logEvents } from "../../../services/eventsLogs";
import MultiSelect from "../../Common/Form/MultiSelect";

class AddVideo extends React.Component {
  constructor(props) {
    super(props);
    const { params = {} } = props;
    const contentId = params.id ? params.id : "";
    this.state = {
      isButtonDisabled: false,
      metadata: { categories: [], tags: [], content_types: [] },
      errorMsg: "",
      msgData: { msg: "", showMsg: false, msgType: "" },
      contentType: "VIDEO",
      media_url: "",
      content_title: "",
      category: "",
      newTag: "no",
      selectTags: "",
      isVertical: "",
      isActive: "",
      selectedCategory: [],
      newTagId: "",
      newTagLabel: "",
      newTagRank: "",
      meta_desc: "",
      successMsg: "",
      fileName: "Upload Thumbnail",
      file: "",
      thumbnail: "",
      blue_grey_flag: "",
      isSeries: "no",
      series_sequence: "",
      series_name: "",
      experience: "",
      qualification: "",
      contentId,
      isLive: "no",
      cityIds: [{ value: "ALL", label: "All" }],
      cityList: []
    };
  }

  handleClosePopup = () => {
    this.setState({ msgData: [{ msg: "", showMsg: false }] });
  };

  componentDidMount() {
    const { contentId } = this.state;
    this.getLTVMetadataData();
    if (contentId) {
      this.getCityList().then(() => {
        this.getContentInfo(contentId);
      });
    } else {
      this.getCityList();
    }
  }

  getCityList = async () => {
    try {
      const cityList = await getCrmCityData(); // Fetch city data
      const optionsList = cityList.map(item => ({
        value: item._id,
        label: item.label
      }));
      // Prepend 'All' option
      const updatedCityList = [{ value: "ALL", label: "All" }, ...optionsList];
      // Update state with city list
      this.setState({
        cityList: updatedCityList,
        msgData: { msg: "", showMsg: false, msgType: "" } // Clear any error message
      });
    } catch (error) {
      // Error handling with message extraction
      const msg =
        getErrorMessage(error) || "An error occurred while fetching city data.";
      this.setState({
        msgData: { msg: msg, showMsg: true, msgType: "error" },
        cityList: [] // Reset city list in case of failure
      });
    }
  };

  getContentInfo = contentId => {
    JobHai.post("/get-data-contentId", { content_id: contentId })
      .then(metadata => {
        const data = metadata.data.data;
        this.setInitilaValue(data);
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setState({
          isLoading: false,
          msgData: { msg: msg, showMsg: true, msgType: "error" }
        });
      });
  };

  setInitilaValue = videoData => {
    const { cityList } = this.state;
    const {
      content_type = "VIDEO",
      is_vertical = false,
      label = "",
      media_url = "",
      is_active = false,
      meta_desc = "",
      tags = [],
      categories = [],
      blue_grey_flag = "",
      thumbnail = "",
      is_series,
      series_sequence,
      series_name,
      experience,
      qualification,
      is_live,
      city_ids = []
    } = videoData;
    const selectTags = tags.length ? tags[0]?.tag_id : "";
    const selectedCity = city_ids.length ? city_ids : ["ALL"];
    const filteredCityList = cityList.filter(city =>
      selectedCity.includes(city.value)
    );

    this.setState({
      media_url,
      contentType: content_type,
      content_title: label,
      category: "",
      newTag: "no",
      selectTags,
      isVertical: is_vertical ? "yes" : "no",
      isActive: is_active ? "yes" : "no",
      selectedCategory: this.getCategoryList(categories),
      meta_desc,
      blue_grey_flag,
      thumbnail,
      isSeries: is_series ? "yes" : "no",
      series_sequence,
      series_name,
      experience,
      qualification,
      isLive: is_live ? "yes" : "no",
      cityIds: filteredCityList
    });
  };

  getLTVMetadataData = () => {
    getLearnTabData()
      .then(response => {
        this.setState({
          metadata: response
        });
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setState({
          isLoading: false,
          msgData: { msg: msg, showMsg: true, msgType: "error" }
        });
      });
  };

  handleChange = event => {
    let { name, value } = event.target;
    if (name === "newTagRank") {
      value = getFormattedValue(value, true, true);
    } else if (name === "newTag" && value === "yes") {
      this.prepareNewTagData();
    } else {
      value = getFormattedValue(value);
    }
    if (name === "contentType") {
      this.setState({
        cityIds: value === "VIDEO" ? [{ value: "ALL", label: "All" }] : [],
        isLive: "no"
      });
    }
    this.setState({ [name]: value });
  };

  handleCityChange = cityIds => {
    const isAllSelected = cityIds.some(city => city.value === "ALL");
    // If "ALL" is selected, reset the selection to only include "ALL"
    if (isAllSelected) {
      this.setState({ cityIds: [{ value: "ALL", label: "All" }] });
    } else {
      // If "ALL" is not selected, set the state with the current selection
      this.setState({ cityIds });
    }
  };

  prepareNewTagData = () => {
    const { metadata } = this.state;
    const { tags = [] } = metadata;
    const maxTagId = Math.max(...tags.map(o => o.tag_id), 0);
    const maxTagRank = tags.length;
    this.setState({
      newTagId: maxTagId + 1,
      newTagRank: maxTagRank + 1
    });
  };

  changeCategory = params => {
    this.setState({ selectedCategory: params });
  };

  handleFileUpload = event => {
    const { type = "", files = [] } = event.target;
    if (type === "file" && files.length) {
      const file = files[0];
      const fileSize = file.size;
      const fileName = file.name;
      const reg = /(.*?)\.(jpg|jpeg|png|webp)$/;

      if (!fileName.match(reg)) {
        this.setState({
          errorMsg: "Only extensions .jpeg/.jpg/.png/.webp allowed."
        });
      } else if (fileSize > 2097152) {
        this.setState({
          errorMsg: "Please upload a file size below 2MB."
        });
      } else {
        this.setState({
          errorMsg: "",
          fileName,
          file
        });
      }
    }
  };
  handleSave = () => {
    const {
      contentType,
      content_title,
      media_url,
      selectTags,
      isVertical,
      isActive,
      selectedCategory,
      newTag,
      newTagId,
      newTagLabel,
      meta_desc,
      contentId,
      file,
      blue_grey_flag,
      isSeries,
      series_sequence,
      series_name,
      experience,
      qualification,
      isLive,
      cityIds
    } = this.state;

    const category_ids = this.prepareCategoryIds(
      selectedCategory,
      blue_grey_flag
    );
    const { metadata } = this.state;
    const { tags = [] } = metadata;
    const selectedTagId = Number(selectTags);
    // Filter tags and extract the necessary properties
    const tagsList = tags
      .filter(item => item.tag_id === selectedTagId)
      .map(({ tag_id, label }) => ({ tag_id, label }));

    let finalTagsList = tagsList;
    if (newTag === "yes") {
      finalTagsList = [
        {
          tag_id: newTagId,
          label: newTagLabel
        }
      ];
    }
    if (
      this.validateForm({
        content_title,
        selectTags,
        media_url,
        meta_desc,
        isActive,
        isVertical,
        newTag,
        blue_grey_flag,
        category_ids,
        file,
        contentId,
        isSeries,
        series_sequence,
        series_name,
        cityIds
      })
    ) {
      const data = this.prepareFormData({
        contentId,
        file,
        content_title,
        category_ids,
        isActive,
        meta_desc,
        blue_grey_flag,
        media_url,
        contentType,
        isVertical,
        isSeries,
        series_sequence,
        series_name,
        experience,
        qualification,
        tagsList: finalTagsList,
        isLive,
        cityIds
      });
      this.setState({ isButtonDisabled: true, errorMsg: "" });
      const url = contentId ? "edit-learn-data" : "upload-learn-data";

      JobHai.post(url, data)
        .then(() => {
          this.setState({
            isButtonDisabled: false,
            successMsg: "Data Saved",
            msgData: { msg: "", showMsg: false, msgType: "error" }
          });
          logEvents(ADD_VIDEO, { media_url, contentId: contentId || "" });
          setTimeout(() => {
            this.setState({ successMsg: "" });
            redirectURL(LEARN_TAB);
          }, 2000);
        })
        .catch(error => {
          const msg = getCustomErrorMessage(error);
          this.setState({
            isButtonDisabled: false,
            errorMsg: msg
          });
        });
    }
  };

  getCategoryList = categories => {
    const categoryList = [];
    categories.map(item =>
      categoryList.push({
        label: item.label,
        value: item.id
      })
    );
    return categoryList;
  };

  prepareCategoryIds = (selectedCategory, blue_grey_flag) => {
    const category_ids = [];
    if (blue_grey_flag === "Manual") {
      selectedCategory.forEach(item => category_ids.push(item.value));
    }
    return category_ids;
  };

  setValistionErrorMessage = errorMsg => {
    this.setState({
      errorMsg
    });
  };
  validateForm = ({
    content_title,
    selectTags,
    media_url,
    meta_desc,
    isActive,
    isVertical,
    newTag,
    blue_grey_flag,
    category_ids,
    file,
    contentId,
    isSeries,
    series_sequence,
    series_name,
    cityIds
  }) => {
    const { newTagLabel } = this.state;
    var regex = /^[A-Za-z0-9_-]{11}$/;
    if (!content_title) {
      this.setValistionErrorMessage("Please add video title");
      return false;
    } else if (!regex.test(media_url)) {
      this.setValistionErrorMessage("Please enter valid youtube video id");
      return false;
    } else if (!meta_desc) {
      this.setValistionErrorMessage("Please add meta description");
      return false;
    } else if (!isVertical) {
      this.setValistionErrorMessage("Please select Is Vertical");
      return false;
    } else if (!isActive) {
      this.setValistionErrorMessage("Please select Is Active");
      return false;
    } else if (!blue_grey_flag) {
      this.setValistionErrorMessage("Please select Blue/Grey Flag");
      return false;
    } else if (blue_grey_flag === "Manual" && !category_ids.length) {
      this.setValistionErrorMessage("Please select category");
      return false;
    } else if (newTag === "no" && !selectTags && !contentId) {
      this.setValistionErrorMessage("Please select tag");
      return false;
    } else if (newTag === "yes" && !newTagLabel && !contentId) {
      this.setValistionErrorMessage("Please add new tag label");
      return false;
    } else if (!contentId && !file) {
      this.setValistionErrorMessage("Please upload a file");
      return false;
    } else if (isSeries === "yes" && !series_sequence) {
      this.setValistionErrorMessage("Please select series sequence");
      return false;
    } else if (isSeries === "yes" && !series_name) {
      this.setValistionErrorMessage("Please select series name");
      return false;
    } else if (!cityIds.length) {
      this.setValistionErrorMessage("Please select city");
      return false;
    }
    return true;
  };
  prepareFormData = ({
    contentId,
    file,
    content_title,
    category_ids,
    isActive,
    meta_desc,
    blue_grey_flag,
    media_url,
    contentType,
    tagsList,
    isVertical,
    isSeries,
    series_sequence,
    series_name,
    experience,
    qualification,
    isLive,
    cityIds
  }) => {
    const formData = new FormData();
    if (file) formData.append("file", file);
    formData.append("label", content_title);
    formData.append("category_ids", JSON.stringify(category_ids));
    formData.append("is_active", isActive === "yes");
    formData.append("meta_desc", meta_desc);
    formData.append("blue_grey_flag", blue_grey_flag);
    formData.append("is_series", isSeries === "yes" ? true : false);
    if (isSeries === "yes") {
      formData.append("series_sequence", series_sequence || null);
      formData.append("series_name", series_name || null);
    }
    formData.append("experience", experience || null);
    formData.append("qualification", qualification || null);
    formData.append("tags", JSON.stringify(tagsList));
    formData.append("is_live", isLive === "yes" ? true : false);
    const valuesArray = cityIds.map(item => item.value);
    formData.append("city_ids", JSON.stringify(valuesArray));
    if (contentId) {
      formData.append("content_id", contentId);
    }
    formData.append("content_type", contentType);
    if (!contentId) {
      formData.append("media_url", media_url);
      formData.append("is_vertical", isVertical === "yes");
    }
    return formData;
  };
  render() {
    const {
      isButtonDisabled,
      errorMsg,
      msgData,
      contentType,
      media_url,
      content_title,
      newTag,
      selectTags,
      isVertical,
      metadata,
      selectedCategory,
      newTagRank,
      newTagLabel,
      meta_desc,
      successMsg,
      contentId,
      thumbnail,
      fileName,
      blue_grey_flag,
      isActive,
      isSeries,
      series_sequence,
      series_name,
      experience,
      qualification,
      isLive,
      cityIds,
      cityList
    } = this.state;
    const isReadOnly = !!contentId;
    const { categories = [], tags = [], content_types = [] } = metadata;
    const isAccess = checkPermission("LearnTabVideo");
    const chipsList = [
      { label: "Yes", id: "yes" },
      { label: "No", id: "no" }
    ];
    const blueGreyFlagList = [
      { label: "All", value: "All" },
      { label: "Blue", value: "Blue" },
      { label: "Grey", value: "Grey" },
      { label: "Manual", value: "Manual" }
    ];
    let tagList = [];
    tags.map(item => {
      return tagList.push({ ...item, value: item.tag_id });
    });
    const categoryList = this.getCategoryList(categories);
    const qualificationList = metadata?.qualification || [];
    let experienceArr = metadata?.experience || [];
    const experienceList = experienceArr.map(item => ({
      value: item,
      label: item
    }));
    const contentTypeList = content_types.map(item => ({
      value: item,
      label: item
    }));
    const seriesList = [];
    for (let i = 1; i <= 50; i++) {
      seriesList.push({ value: i, label: i });
    }
    return (
      <div className="edit-form">
        <div className="gap-20" />
        <PopupMessage data={msgData} click={this.handleClosePopup} />
        <HeadingRow heading="Add Video" goBackURL={LEARN_TAB} />
        <div className="gap-20" />
        <div className="whitebox video-form" style={{ minHeight: "750px" }}>
          <div className="gap-20" />
          <div className="container-fluid">
            <Heading heading="Video Details:" />
            <div className="formRow form-row">
              <div className="col-md-4">
                <Label lable="Content Type" />
                <CustomSelect
                  name="contentType"
                  value={contentType}
                  onChange={this.handleChange}
                  optionsList={contentTypeList}
                  isSelectDisabled={true}
                  disabled={isReadOnly}
                />
              </div>
              <div className="col-md-4">
                <Label lable="Video Title" />
                <CustomTextField
                  name="content_title"
                  value={content_title}
                  onChange={this.handleChange}
                  maxLength={100}
                  placeholder="Enter video title"
                />
              </div>

              <div className="col-md-4">
                <Label lable="Youtube Video ID" />
                <CustomTextField
                  name="media_url"
                  value={media_url}
                  onChange={this.handleChange}
                  maxLength={11}
                  placeholder="Enter youtube video id"
                  isReadonly={isReadOnly}
                />
              </div>
            </div>
            <div className="formRow form-row">
              <div className="col-md-4">
                <Label lable="Meta Description" />
                <CustomTextField
                  name="meta_desc"
                  value={meta_desc}
                  onChange={this.handleChange}
                  maxLength={100}
                  placeholder="Enter meta description"
                />
              </div>
              <div className="col-md-4">
                <Label lable="Experience" isOptional={true} />
                <CustomSelect
                  name="experience"
                  value={experience}
                  onChange={this.handleChange}
                  optionsList={experienceList}
                  optionValue="id"
                  defaultPlaceholder="All"
                />
              </div>
              <div className="col-md-4">
                <Label lable="Qualification" isOptional={true} />
                <CustomSelect
                  name="qualification"
                  value={qualification}
                  onChange={this.handleChange}
                  optionsList={qualificationList}
                  optionValue="id"
                  defaultPlaceholder="All"
                />
              </div>
            </div>
            <div className="formRow form-row">
              <div className="col-md-2">
                <Label lable="Is Vertical" />
                <CustomSelect
                  name="isVertical"
                  value={isVertical}
                  onChange={this.handleChange}
                  optionsList={chipsList}
                  optionValue="id"
                  disabled={isReadOnly}
                  isSelectDisabled={true}
                />
              </div>
              <div className="col-md-2">
                <Label lable="Is Active" />
                <CustomSelect
                  name="isActive"
                  value={isActive}
                  onChange={this.handleChange}
                  optionsList={chipsList}
                  optionValue="id"
                  isSelectDisabled={true}
                />
              </div>
              <div className="col-md-2">
                <Label lable="Is Series" />
                <CustomSelect
                  name="isSeries"
                  value={isSeries}
                  onChange={this.handleChange}
                  optionsList={chipsList}
                  optionValue="id"
                  isSelectDisabled={true}
                />
              </div>
              <div className="col-md-2">
                <Label lable="Is Live" />
                <CustomSelect
                  name="isLive"
                  value={isLive}
                  onChange={this.handleChange}
                  optionsList={chipsList}
                  optionValue="id"
                  isSelectDisabled={true}
                  disabled={contentType === "VIDEO"}
                />
              </div>
              <div className="col-md-4">
                <Label lable="Blue/Grey Flag" />
                <CustomSelect
                  name="blue_grey_flag"
                  value={blue_grey_flag}
                  onChange={this.handleChange}
                  optionsList={blueGreyFlagList}
                  defaultPlaceholder="Select Category"
                  isSelectDisabled={true}
                />
              </div>
            </div>
            <div className="formRow form-row">
              <div className="col-md-4">
                <Label lable="New Tag" />
                <CustomSelect
                  name="newTag"
                  value={newTag}
                  onChange={this.handleChange}
                  optionsList={chipsList}
                  optionValue="id"
                />
              </div>
              {newTag === "yes" ? (
                <div className="col-md-4">
                  <div className="form-row">
                    <div className="col-md-9">
                      <Label lable="New Tag Label" />
                      <CustomTextField
                        name="newTagLabel"
                        value={newTagLabel}
                        onChange={this.handleChange}
                        maxLength={30}
                        placeholder="Enter Tag Label"
                        isReadOnly={isReadOnly}
                      />
                    </div>
                    <div className="col-md-3">
                      <Label lable="Tag Rank" />
                      <CustomTextField
                        name="newTagRank"
                        value={newTagRank}
                        onChange={this.handleChange}
                        maxLength={3}
                        isReadOnly={isReadOnly}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-md-4">
                  <Label lable="Tags" />
                  <CustomSelect
                    name="selectTags"
                    value={selectTags}
                    onChange={this.handleChange}
                    optionsList={tagList}
                    optionValue="tag_id"
                    defaultPlaceholder="Select Tags"
                  />
                </div>
              )}
              {blue_grey_flag === "Manual" ? (
                <div className="col-md-4">
                  <Label lable="Category" />
                  <Select
                    value={selectedCategory}
                    onChange={this.changeCategory}
                    options={categoryList}
                    isMulti
                    placeholder={"Select Category"}
                    isSelectDisabled={true}
                  />
                </div>
              ) : null}
            </div>

            <div className="formRow form-row">
              <div className="col-md-4">
                <Label lable="Select City" />
                <MultiSelect
                  value={cityIds}
                  onChange={this.handleCityChange}
                  optionList={cityList}
                  placeholder={"Select City"}
                  isDisabled={contentType === "VIDEO"}
                />
              </div>
              {isSeries === "yes" ? (
                <>
                  <div className="col-md-4">
                    <Label lable="Series sequence" />
                    <CustomSelect
                      name="series_sequence"
                      value={series_sequence}
                      onChange={this.handleChange}
                      optionsList={seriesList}
                      optionValue="id"
                      isSelectDisabled={true}
                    />
                  </div>
                  <div className="col-md-4">
                    <Label lable="Series Name" />
                    <CustomTextField
                      name="series_name"
                      value={series_name}
                      onChange={this.handleChange}
                      maxLength={100}
                      placeholder="Enter Series Name"
                    />
                  </div>
                </>
              ) : null}
            </div>
            <div className="form-row">
              <div className="col-md-4">
                <UploadFiles
                  fileUrl={thumbnail}
                  fileName={fileName}
                  fieldName="uploadThumbnail"
                  handleFileUpload={this.handleFileUpload}
                />
              </div>
            </div>
            <div className="formRow form-row">
              <div className="col-md-12">
                <div className="float-right">
                  {errorMsg ? (
                    <span className="text-danger">{errorMsg} </span>
                  ) : null}
                  {isButtonDisabled || !isAccess ? (
                    <button className="btn btn-primary disable" disabled>
                      Save
                    </button>
                  ) : (
                    <>
                      {successMsg ? (
                        <span className="text-success">{successMsg} </span>
                      ) : (
                        <button
                          className="btn btn-primary"
                          onClick={this.handleSave}
                        >
                          Save
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(AddVideo);
