import React, { useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

const DateRangeFilter = props => {
  const [errorMessage, setErrorMessage] = useState("");

  const {
    ctaCallback,
    fromText = "Date From: ",
    toText = "To: ",
    ctaText = "Search",
    successMsg,
    minStartDays = 0,
    minStartSelectedDate
  } = props;

  const today = new Date();
  const startMinDate = new Date(today);
  startMinDate.setDate(today.getDate() - minStartDays);

  const [startDate, setStartDate] = useState(minStartSelectedDate);
  const [endDate, setEndDate] = useState(new Date());

  const handleSearch = e => {
    e.preventDefault();
    if (!startDate) {
      setErrorMessage("Please enter start date");
      return;
    }
    if (!endDate) {
      setErrorMessage("Please enter end date");
      return;
    }
    if (startDate > endDate) {
      setErrorMessage("Please enter start date less than end date");
      return;
    }
    const dateObject = {
      start_date: formatDate(startDate),
      end_date: formatDate(endDate)
    };
    ctaCallback(dateObject);
  };

  const formatDate = date => {
    return date ? moment(date).format("YYYY-MM-DD") : undefined;
  };
  const isButtonDisabled = !startDate || !endDate;
  return (
    <div className="job_seeker">
      <div className="gap-20"></div>
      <form autoComplete="off" onSubmit={handleSearch} className="search">
        <div className="row align-items-center">
          <div className="col-md-5">
            <div className="date-lable padding15">{fromText}</div>
            <div className="date-field">
              <DatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                dateFormat="yyyy-MM-dd"
                className="form-control"
                maxDate={endDate || new Date()}
                minDate={startMinDate}
              />
            </div>
            <div className="date-lable">{toText}</div>
            <div className="date-field">
              <DatePicker
                selected={endDate}
                onChange={date => setEndDate(date)}
                dateFormat="yyyy-MM-dd"
                className="form-control"
                maxDate={new Date()}
                minDate={startDate}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="float-right">
              {isButtonDisabled ? (
                <button className="btn btn-primary disable" disabled>
                  {ctaText}
                </button>
              ) : (
                <button className="btn btn-primary" type="submit">
                  {ctaText}
                </button>
              )}
            </div>
          </div>
          {errorMessage ? (
            <span className="text-danger">{errorMessage}</span>
          ) : successMsg ? (
            <span className="text-success">{successMsg}</span>
          ) : null}
        </div>
      </form>
      <div className="gap-20"></div>
    </div>
  );
};

export default DateRangeFilter;
