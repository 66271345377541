import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { getSales, getSalesSummary } from "../services/insideSales";

const defaultFilters = {
  orderIds: "",
  recruiterIds: "",
  recruiterName: "",
  recruiterPhone: "",
  orgIds: "",
  orgName: "",
  creditsPurchased: {
    min: "",
    max: ""
  },
  creditPurchaseDate: { from: "", to: "" },
  moderatorIds: "",
  teamLeadIds: ""
};
const pageSize = 20;

const useInsideSalesStore = create(
  persist(
    (set, get) => ({
      defaultFilters,
      initialFilters: { ...defaultFilters },
      payloadData: {
        page: 1,
        size: pageSize,
        filters: {}
      },
      fetchState: "LOADING",
      salesData: {},
      errorMessage: null,
      salesSummary: {},
      fetchData: async (payloadData, summery = true) => {
        set({ fetchState: "LOADING" });
        try {
          const requests = [getSales(payloadData)];
          if (summery) {
            requests.push(getSalesSummary(payloadData));
          }
          const responses = await Promise.all(requests);
          const [responseSalesData, responseSalesSummary] = responses;
          set(state => ({
            salesData: responseSalesData,
            fetchState: "SUCCESS",
            errorMessage: null,
            salesSummary: summery
              ? responseSalesSummary?.salesSummary
              : state.salesSummary
          }));
        } catch (err) {
          console.error("Error fetching data:", err);
          set({
            fetchState: "ERROR",
            salesData: {},
            errorMessage: err.message || "",
            salesSummary: {}
          });
        }
      },

      fetchSalesData: () => {
        const { payloadData, fetchData } = get();
        fetchData(payloadData);
      },

      updateFilterData: newFilterData => {
        set(state => ({
          payloadData: {
            ...state.payloadData,
            page: 1,
            filters: { ...newFilterData }
          }
        }));
        const { payloadData, fetchData } = get();
        fetchData(payloadData);
      },

      updatePageNumber: page => {
        set(state => ({
          payloadData: { ...state.payloadData, page }
        }));
        const { payloadData, fetchData } = get();
        fetchData(payloadData, false);
      },

      resetFilterData: () => {
        set(state => ({
          payloadData: {
            ...state.payloadData,
            filters: {}
          }
        }));
        const { payloadData, fetchData } = get();
        fetchData(payloadData);
      },
      updateInitialFilters: newFilters => {
        set(state => ({
          initialFilters: { ...state.initialFilters, ...newFilters }
        }));
      }
    }),
    {
      name: "lfmsSalesData",
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);

export default useInsideSalesStore;
