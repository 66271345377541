import React from "react";
import DocumentTitle from "react-document-title";
import JobHai from "../../api/JobHai";
import PopupMessage from "../PopupMessage";
import LoadingSpinner from "../LoadingSpinner";
import { checkPermission } from "../../services/utils";
import ModeratorFilter from "./ModeratorFilter";

class ModeratorActivity extends React.Component {
  constructor(props) {
    super(props);
    const { params = {} } = props;
    let { type = "moderator-activaty" } = params;
    const showModActivity = checkPermission("Moderator Activity");
    const showAgentAcitvity = checkPermission("Agent Activity");
    if (!showModActivity) {
      type = "agent-activity";
    }
    this.state = {
      statsHTML: "",
      currentApi: type === "agent-activity" ? "agent-stats" : "moderator-stats",
      tabList: [
        {
          title: "Moderator Activity",
          id: "moderator",
          url: "https://api.jobhai.com/web/stat?password=T@rika_ka_sta1",
          apiURL: "moderator-stats",
          is_selected: type === "moderator-activaty" ? true : false,
          isVisible: showModActivity
        },
        {
          title: "Agent Activity",
          id: "agent",
          apiURL: "agent-stats",
          url: "https://api.jobhai.com/metadata/agent-activity",
          is_selected: type === "agent-activity" ? true : false,
          isVisible: showAgentAcitvity
        }
      ],
      msgData: { msg: "", showMsg: false, msgType: "error" },
      isLoading: true,
      isButtonDisabled: false
    };
  }

  componentDidMount() {
    const { currentApi } = this.state;
    this.getAnalytics(currentApi);
  }

  handleClick = type => {
    const { tabList } = this.state;
    const currentItem = tabList.filter(item => item.id === type)[0]
      ? tabList.filter(item => item.id === type)[0]
      : [];

    var foundIndex = tabList.findIndex(item => item.id === type);
    tabList.map(item => (item.is_selected = false));
    if (currentItem) {
      currentItem.is_selected = !currentItem.is_selected;
      tabList[foundIndex] = currentItem;
      this.setState({ tabList: tabList, currentApi: currentItem.apiURL });
      this.getAnalytics(currentItem.apiURL);
    }
  };

  getAnalytics = (url = "moderator-stats", filters = {}) => {
    JobHai.post(url, {
      start_date: filters.start_date ? filters.start_date : undefined,
      end_date: filters.end_date ? filters.end_date : undefined
    })
      .then(response => {
        this.setState({
          statsHTML: response.data,
          isLoading: false,
          isButtonDisabled: false,
          msgData: { msg: "", showMsg: false, msgType: "" }
        });
      })
      .catch(
        function (error) {
          let msg = error.response ? error.response.data.error : error;
          this.setState({
            isLoading: false,
            isButtonDisabled: false,
            msgData: { msg: msg, showMsg: true, msgType: "error" }
          });
        }.bind(this)
      );
  };

  searchModerator = filters => {
    const { currentApi } = this.state;
    this.setState({
      isButtonDisabled: true
    });
    this.getAnalytics(currentApi, filters);
  };

  handleClosePopup = () => {
    this.setState({ msgData: [{ msg: "", showMsg: false }] });
  };

  statusMessage = (msg, msgType) => {
    this.setState({
      msgData: {
        msg: msg,
        showMsg: true,
        msgType: !msgType ? "success" : "error"
      }
    });
  };

  render() {
    const { tabList, statsHTML, msgData, isLoading } = this.state;
    const activeTabs = tabList.filter(item => item.isVisible);
    if (isLoading) {
      return <LoadingSpinner />;
    } else {
      return (
        <DocumentTitle title={"Job Hai CRM - Analytic"}>
          <div className="dashboard">
            <div className="clr gap-20" />

            <PopupMessage data={msgData} click={this.handleClosePopup} />
            <div className="pageHeading row">
              <h2>
                Moderator/Agent Activity
                <div className="float-right">
                  <a href={"/analytics"}>Go Back</a>
                </div>
              </h2>
            </div>

            <div className="clr gap-20"></div>
            <div className="row">
              <div className="col-md-12">
                {activeTabs.map(item => {
                  return (
                    <span
                      className={
                        item.is_selected
                          ? "unClickedChip clickedChip"
                          : "unClickedChip"
                      }
                      onClick={() => this.handleClick(item.id)}
                      key={item.id}
                    >
                      {item.title}
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="clr"></div>
            <ModeratorFilter
              searchModerator={this.searchModerator}
              statusMessage={this.statusMessage}
            />
            <div className="whitebox">
              <div
                className="row"
                dangerouslySetInnerHTML={{ __html: statsHTML }}
              ></div>
            </div>
          </div>
        </DocumentTitle>
      );
    }
  }
}

export default ModeratorActivity;
