import React from "react";
import { FormControlLabel, Switch } from "@material-ui/core";
import { useController } from "react-hook-form";

const MaterialSwitch = ({
  name,
  label,
  control,
  rules,
  error,
  defaultChecked,
  formControlClasses,
  ...rest
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    defaultValue: defaultChecked
  });

  return (
    <FormControlLabel
      classes={formControlClasses}
      control={<Switch defaultChecked={defaultChecked} {...field} {...rest} />}
      label={label}
      error={!!fieldState.error || !!error}
    />
  );
};

export default MaterialSwitch;
