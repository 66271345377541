import {
  DRIVER_CATERGORY_ID,
  ERROR_MESSAGE,
  GA4_TRACKING_ID_REC,
  ONE_JOB_CREDITS,
  PERSONAL_EMAILS
} from "./constant";
import {
  JOB_EDIT_PAGE,
  COMPANY_EDIT_PAGE,
  LIVE_JOBS,
  JOBS,
  UNVERIFIED_RECRUITERS,
  RECUITERS_LIST,
  RECUITERS_APPLICANT
} from "../routes/RouteConstants";
import MetaData, {
  restrictActionList,
  restrictionStatusList
} from "../constants/MetaData";
import JobHai from "../api/JobHai";
import { removeCookie } from "./cookie";
import axios from "axios";
import { useRef } from "react";
import { useEffect } from "react";
import { getCrmCityData, getLocality } from "./metadataApiServices";

export function getCustomErrorMessage(error) {
  const { response = {} } = error;
  const { data = {} } = response;
  const { details = {} } = data;
  const { body = {} } = details;
  const errorList = Object.values(body);
  if (errorList.length) {
    return errorList[0];
  }
  if (details.error) {
    return details.error;
  }
  if (data.error) {
    return data.error;
  }
  return ERROR_MESSAGE;
}

export function getErrorMessage(error) {
  try {
    let errorMessage = "";
    if (error && error.response) {
      if (error?.response?.data?.details?.error) {
        errorMessage = error?.response?.data?.details?.error;
        return errorMessage;
      }
      if (error?.response?.data?.error?.message) {
        return error?.response?.data?.error?.message;
      }
      if (error.response.data) {
        errorMessage = error.response.data.error;
      } else {
        errorMessage = ERROR_MESSAGE;
      }
    } else {
      errorMessage = error ? error : ERROR_MESSAGE;
    }
    return errorMessage;
  } catch (error) {
    return ERROR_MESSAGE;
  }
}

export function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return "";
}

export function replaceTagWithLineBreak(string) {
  if (string) {
    let str = string.replace(/<br>/g, "");
    str = str.replace(/<p>/g, "").replace(/<\/p>/g, "\n");
    str = str.replace(/\n+$/, "");
    return str;
  } else {
    return "";
  }
}

export function isNumeric(value) {
  return /^\d+$/.test(value);
}

export function checkPermission(key = "", isWriteAllow = false) {
  const userJsonString = JSON.parse(localStorage.getItem("userData"));
  const { access_json = [], user_level = "USER" } = userJsonString || {};
  let flag = false;
  if (user_level === "ADMIN") flag = true;
  if (access_json) {
    if (key) {
      const curObject = access_json.find(
        item => item.id === key || item.label === key
      );
      const accessLevel = curObject?.access_level || 0;
      if (accessLevel) {
        flag = isWriteAllow ? accessLevel > 1 : accessLevel > 0;
      }
    }
  }
  return flag;
}

export const isCRMAdmin = () => {
  const userJsonString = JSON.parse(localStorage.getItem("userData"));
  const { user_level = "USER" } = userJsonString;
  return user_level === "ADMIN";
};

export const getFormattedValue = (
  string,
  isNumber = false,
  isPhone = false,
  alphabetOnly = false
) => {
  if (string) {
    let value = string.replace("<", ""); //  To Remove < Tags
    value = value.replace(">", ""); //  To Remove > Tags
    value = value.replace(/(<([^>]+)>)/gi, ""); // Added To Remove Tags
    if (isNumber) value = value.replace(/\D/, "");
    if (isPhone) value = value.replace(/^0/, "");
    if (alphabetOnly) value = value.replace(/[^A-Z a-z]/gi, "");
    return value;
  } else {
    return "";
  }
};

export const isEmpty = value => {
  return value == null || value.length === 0;
};

export const isNullOrEmpty = value => value === null || value === "";

export const addDays = (date, days) => {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const getHiringEndDate = no_of_openings => {
  let ied = new Date();
  if (Number(no_of_openings) > 0) {
    ied = addDays(new Date(), 45);
  }
  if (no_of_openings > 2) {
    ied = addDays(new Date(), 60);
  }
  if (no_of_openings > 10) {
    ied = addDays(new Date(), 90);
  }
  if (no_of_openings > 50) {
    ied = addDays(new Date(), 120);
  }
  if (no_of_openings > 100) {
    ied = addDays(new Date(), 150);
  }
  return ied;
};

export const redirectURL = url => {
  window.location = url;
};

export const logout = () => {
  localStorage.clear();
  sessionStorage.clear();
  removeCookie("proxy_moderator_id");
  removeCookie("proxy_moderator_name");
  removeCookie("moderator_id");
};

export const getEditPageURL = (
  verificationStatus,
  jobId,
  recId,
  role = "QA",
  page = "organic"
) => {
  const pageURL =
    verificationStatus === "VERIFIED_REPEAT" ||
    verificationStatus === "VERIFIED_NON_REPEAT" ||
    verificationStatus === "VERIFIED"
      ? JOB_EDIT_PAGE
      : COMPANY_EDIT_PAGE;
  const editUrl = `${pageURL}/${page}/${role}/${jobId}?recId=${recId}`;
  return editUrl;
};

export const isJobEditPage = pathName => {
  if (pathName) {
    let arr = pathName.split("/");
    arr = arr.filter(item => {
      return item;
    });

    const firstItem = arr[0];
    const lastItem = arr[arr.length - 1];
    if (
      (arr.length === 4 &&
        (firstItem === "jobs" || firstItem === "company") &&
        Number(lastItem)) ||
      (firstItem === "enterprise-leads" && arr[1] === "update")
    )
      return true;
  }
  return false;
};

export const getActiveItemList = (array, value, id = "id") => {
  let arr = [];
  if (value && array.length) {
    let tempArr = array.filter(item => item[id] === value);
    arr = tempArr.length ? tempArr[0] : [];
  } else {
    arr = [];
  }
  return arr;
};

export const isEditedFields = (editedFields, fieldName) => {
  return editedFields.includes(fieldName) ? true : false;
};

export const setResponse = (key = "", data) => {
  sessionStorage.setItem(key, JSON.stringify(data));
};

export const getResponse = key => {
  const data = JSON.parse(sessionStorage.getItem(key));
  return data;
};

export const removeItem = key => {
  if (key) sessionStorage.removeItem(key);
};

export const setObjToNull = obj => {
  var returnObj = {};
  Object.keys(obj).map(key => {
    let nullObj = { [key]: obj[key] == "" ? null : obj[key] };
    Object.assign(returnObj, nullObj);
  });
  return returnObj;
};

export const isValidUrl = url => {
  if (url) {
    let string = url.toLowerCase();
    const www = string.substring(0, 4); // if url start from www.
    const http = string.substring(0, 7); // if url start from http://
    const https = string.substring(0, 8); // if utl start from https://

    // Regx to check http://wwww.domain.com or https://wwww.domain.com or http://domain.com or https://domain.com or www.domain.com
    const regx =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/;
    // To check special charactor on url
    // var specialCharRegx = /[!$%^&*()+|~=`{}\[\]";'<>?,]/;

    // To check http:// or https:// or www in  url
    if (!(www === "www." || http === "http://" || https === "https://")) {
      return false;
    } else {
      // To check special charactor in url
      // if (specialCharRegx.test(url)) {
      //   return false;
      // } else {
      let str = https === "https://" ? string : string.replace("www.", "");
      return !!regx.test(str);
      // }
    }
  }
};

export const getFieldName = string => {
  if (string) {
    const capitalize = string
      .split("_")
      .map(sub => sub.charAt(0).toUpperCase() + sub.slice(1))
      .join(" ");
    return capitalize;
  }
};

export const getNthItemText = item => {
  return item === 0 ? `st` : item === 1 ? `nd` : item === 2 ? `rd` : `th`;
};

export const isObject = val => {
  return typeof val === "object";
};

export const isRecruiterVerified = recruiterStatus => {
  return (
    recruiterStatus === "VERIFIED_REPEAT" ||
    recruiterStatus === "VERIFIED_NON_REPEAT"
  );
};

export const getDocumentStatus = docStatus => {
  const docList = MetaData.companyDocumentStatus;
  let selectedDoc = docList.filter(item => item.id === docStatus);
  if (docStatus && selectedDoc.length) {
    return selectedDoc[0].label;
  } else {
    return docStatus === "no_document" ? "No Document" : docStatus;
  }
};

export const nl2br = (str, is_xhtml) => {
  var breakTag =
    is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
  return (str + "").replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    "$1" + breakTag + "$2"
  );
};

export const wordWrap = (str, maxWidth) => {
  if (str) {
    let newLineStr = "\n";
    let res = "";
    let i = 0;
    while (str.length > maxWidth) {
      let found = false;
      // Inserts new line at first whitespace of the line
      for (i = maxWidth - 1; i >= 0; i--) {
        if (testWhite(str.charAt(i))) {
          res = res + [str.slice(0, i), newLineStr].join("");
          str = str.slice(i + 1);
          found = true;
          break;
        }
      }
      // Inserts new line at maxWidth position, the word is too long to wrap
      if (!found) {
        res += [str.slice(0, maxWidth), newLineStr].join("");
        str = str.slice(maxWidth);
      }
    }
    return res + str;
  } else return "";
};

export const testWhite = x => {
  let white = new RegExp(/^\s$/);
  return white.test(x.charAt(0));
};

export const isEqual = (a, b, map = new Map()) => {
  if (a === b) return true; // need to check
  if (map.has(a) || map.has(b)) return true;
  map.set(a, b);
  if (typeof a === "object" && typeof b === "object") {
    const a_keys = Object.keys(a);
    const b_keys = Object.keys(b);
    if (a_keys.length !== b_keys.length) return false;
    for (let i = 0; i < a_keys.length; i++) {
      if (!isEqual(a[a_keys[i]], b[b_keys[i]], map)) return false;
    }
    return true;
  }
  return false;
};

export function checkForPersonalEmail(email = "") {
  let includes = PERSONAL_EMAILS.some(v => email.toLowerCase().includes(v));
  return includes;
}

export function getOtherMethodEmailInfo(email = "", isVerified = false) {
  let message = "Not Given";
  if (email) {
    let isPersonalEmail = checkForPersonalEmail(email);
    if (isPersonalEmail && isVerified) message = "Personal & Verified";
    if (isPersonalEmail && !isVerified) message = "Personal & Unverified";
    if (!isPersonalEmail && isVerified) message = "Official & Verified";
    if (!isPersonalEmail && !isVerified) message = "Official & Unverified";
  }
  return message;
}

export function getDocumentType(docType = "") {
  let message = "Not Available";
  if (docType) {
    let docList = {};
    if (localStorage.getItem("crmAllowDocument")) {
      docList = JSON.parse(localStorage.getItem("crmAllowDocument"));
      const selectedItem = getActiveItemList(docList, docType, "type") || {};
      const { validation = [], title = "" } = selectedItem;
      const isDocNumberRequired = validation.length;
      if (isDocNumberRequired) message = "Primary Document";
      else message = "Secondary Document";
    }
  }
  return message;
}

export function getOtherMethodInfo(email, isVerified, docType) {
  return [
    {
      key: "Email ID:",
      value: getOtherMethodEmailInfo(email, isVerified)
    },
    {
      key: "Document:",
      value: getDocumentType(docType)
    },
    {
      key: "Next Action:",
      value: "Check Onb Score & Call"
    }
  ];
}

export const getModeratorName = () => {
  let userJsonString = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : {};
  return userJsonString.name ? userJsonString.name : "NA";
};

export const getModeratorLevel = () => {
  const userJsonString = JSON.parse(localStorage.getItem("userData")) || {};
  const { user_level = "" } = userJsonString;
  return user_level;
};

export const isReloadRfd = (rfdFields, companyData) => {
  let isReload = false;
  for (const item in rfdFields) {
    if (rfdFields[item] !== companyData[item]) {
      isReload = true;
      break;
    }
  }
  return isReload;
};

export const getCityName = async cityId => {
  if (!cityId) return "";
  try {
    const cityList = (await getCrmCityData()) || [];
    const selectedCity = cityList.find(item => item._id === cityId);
    return selectedCity?.label || "";
  } catch (error) {
    console.error("Error fetching city name:", error);
    return "";
  }
};

export const getLocalityName = async (cityId, localityID) => {
  if (!localityID) return "";
  try {
    const locList = (await getLocality(cityId)) || [];
    const selectLoc = locList.find(item => item._id === localityID);
    return selectLoc?.label || "";
  } catch (error) {
    console.error("Error fetching locality name:", error);
    return "";
  }
};

export const getComanyDocument = type => {
  let docLabel = "";
  if (type) {
    if (localStorage.getItem("crmAllowDocument")) {
      const docList = JSON.parse(localStorage.getItem("crmAllowDocument"));
      const selectDoc = docList.filter(item => item.type === type);
      if (selectDoc.length) docLabel = selectDoc[0].label;
    }
  }
  return docLabel;
};

export const callPremiumSaveOnStateVsProp = ({
  statePremium,
  propPremium,
  recId,
  jobId
}) => {
  return new Promise((resolve, reject) => {
    if (comparePremiumValue(statePremium, propPremium)) {
      JobHai.post(`/save-premium-intent`, {
        recruiter_id: recId,
        interested_in_premium_offering: statePremium === "No" ? false : true
      })
        .then(() => {
          resolve();
          if (recId) {
            const recData = getResponse(recId);
            if (recData) {
              recData.interested_in_premium_offering =
                statePremium === "No" ? false : true;
              setResponse(recId, recData);
            }
          }
          if (jobId) {
            const jobData = getResponse(jobId);
            if (jobData) {
              jobData.interested_in_premium_offering =
                statePremium === "No" ? false : true;

              setResponse(jobId, jobData);
            }
          }
        })

        .catch(err => {
          // setActiveChip(null);
          console.log("err", err);
          resolve();
        });
    } else {
      resolve();
    }
  });
};

const comparePremiumValue = (state, prop) => {
  if (prop === true && state === "Yes") return false;
  if ((prop === false || prop === 0) && state === "No") return false;
  if ((prop === false || prop === 0) && state === "Yes") return true;
  if (prop === true && state === "No") return true;
  if (prop === null || prop === undefined) {
    if (state === "Yes" || state === "No") return true;
  }
  return false;
};

export function getIntent(interested_in_premium_off) {
  if (
    interested_in_premium_off == null ||
    interested_in_premium_off == undefined
  ) {
    return null;
  } else if (!interested_in_premium_off) return "No";
  return "Yes";
}

export const getSubStr = (string, length = 25) => {
  if (string && string.length > length) {
    return string.substr(0, length) + " ...";
  } else {
    return string;
  }
};

export const getFieldLabel = (
  arrayList,
  value,
  key = "value",
  label = "label"
) => {
  const selectedList = arrayList.filter(i => i[key] === value);
  if (selectedList.length) {
    return selectedList[0][label];
  } else {
    return value;
  }
};

export const checkFreeTextValidation = value => {
  if (!value) return false;
  if (value?.length > 30) return false;
  const regex = /^[a-zA-Z0-9 \-/]+$/;
  return regex.test(value);
};

export const isObjectEmpty = obj => {
  return Object.keys(obj).every(function (x) {
    return obj[x] === "" || obj[x] === null;
  });
};

export const getQueryString = querStrArr => {
  let queryString = Object.keys(querStrArr).map(key =>
    !isEmpty(querStrArr[key]) ? key + "=" + querStrArr[key] : null
  );
  queryString = queryString.filter(function (el) {
    return !isEmpty(el);
  });
  queryString = queryString.length > 0 ? queryString.join("&") : "";
  return queryString;
};

export const findBooleanState = state => {
  if (state === "Yes" || state === true) return true;
  else if (state === "No" || state === false) return false;
  return null;
};

export const findChipValue = state => {
  if (state === true || state === 1 || state === "Yes") return "Yes";
  else if (state === false || state === 0 || state === "No") return "No";
  return null;
};

export const isAnyCallingConnected = data =>
  data.lead_calling_first_status === "ATTEMPT_CONNECTED" ||
  data.lead_calling_second_status === "ATTEMPT_CONNECTED" ||
  data.lead_calling_third_status === "ATTEMPT_CONNECTED" ||
  data.lead_calling_fourth_status === "ATTEMPT_CONNECTED" ||
  data.lead_calling_fifth_status === "ATTEMPT_CONNECTED";

export const isAnyCallingNotConnected = (data, callingAttemts = 5) => {
  if (Number(callingAttemts) === 1) {
    return data.lead_calling_first_status === "ATTEMPT_NOT_CONNECTED";
  } else if (Number(callingAttemts) === 2) {
    return (
      data.lead_calling_first_status === "ATTEMPT_NOT_CONNECTED" &&
      data.lead_calling_second_status === "ATTEMPT_NOT_CONNECTED"
    );
  } else if (Number(callingAttemts) === 3) {
    return (
      data.lead_calling_first_status === "ATTEMPT_NOT_CONNECTED" &&
      data.lead_calling_second_status === "ATTEMPT_NOT_CONNECTED" &&
      data.lead_calling_third_status === "ATTEMPT_NOT_CONNECTED"
    );
  } else if (Number(callingAttemts) === 4) {
    return (
      data.lead_calling_first_status === "ATTEMPT_NOT_CONNECTED" &&
      data.lead_calling_second_status === "ATTEMPT_NOT_CONNECTED" &&
      data.lead_calling_third_status === "ATTEMPT_NOT_CONNECTED" &&
      data.lead_calling_fourth_status === "ATTEMPT_NOT_CONNECTED"
    );
  } else {
    return (
      data.lead_calling_first_status === "ATTEMPT_NOT_CONNECTED" &&
      data.lead_calling_second_status === "ATTEMPT_NOT_CONNECTED" &&
      data.lead_calling_third_status === "ATTEMPT_NOT_CONNECTED" &&
      data.lead_calling_fourth_status === "ATTEMPT_NOT_CONNECTED" &&
      data.lead_calling_fifth_status === "ATTEMPT_NOT_CONNECTED"
    );
  }
};

export const isNoneCallingSelected = data =>
  data.lead_calling_first_status !== "NEW" ||
  data.lead_calling_second_status !== "NEW" ||
  data.lead_calling_third_status !== "NEW" ||
  data.lead_calling_fourth_status !== "NEW" ||
  data.lead_calling_fifth_status !== "NEW";

export const isDriverCategory = catId => {
  return Number(catId) === DRIVER_CATERGORY_ID;
};

export const getJobBackURL = (
  page = "organic",
  role = "QA",
  recId,
  returnURL
) => {
  let goBackUrl = page === "live" ? LIVE_JOBS : `${JOBS}/${page}/${role}`;
  if (returnURL) {
    if (returnURL === "applicant")
      goBackUrl = `${RECUITERS_APPLICANT}/${recId}`;
    if (returnURL === "recruiters") goBackUrl = `${RECUITERS_LIST}`;
    if (returnURL === "unverified")
      goBackUrl = `${UNVERIFIED_RECRUITERS}/${role}`;
  }
  return goBackUrl;
};

export const fireGoogleAnalyticsEvent = ({ eventName, recId }) => {
  axios
    .create({
      baseURL: "https://analytics.google.com"
    })
    .post("/g/collect", null, {
      params: {
        v: 2,
        t: "event",
        tid: GA4_TRACKING_ID_REC,
        uid: recId,
        en: eventName
      }
    })
    .catch(error => {
      console.log(error);
    });
};

export const customUseEffect = (callback, deps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const effectRan = useRef(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!effectRan.current) {
      // console.log("effect applied - only on the FIRST mount");
      callback();
    }

    return () => (effectRan.current = true);
  }, deps);
};

export const getValueFromURL = key => {
  const search = window.location.search || "";
  const queryParams = new URLSearchParams(search);
  return queryParams.get(key) || "";
};

export const checkKeyValueExist = (array, value, key, extraCheck = 1) => {
  return array.some(function (el) {
    return el[key] === value && extraCheck;
  });
};

export const checkLCSKeyExist = (array, value, key = "name") => {
  return checkKeyValueExist(array, value, key);
};

export const scrollToElementUsingRef = inputRef => {
  if (inputRef?.current) {
    inputRef?.current.scrollIntoView({ behavior: "smooth", block: "center" });
  }
};
export const findValueInArrayObject = (array, find) => {
  return array.some(obj => Object.values(obj).some(value => value === find));
};

export const columnsSortItems = (list, column, order) => {
  return [...list].sort((a, b) => {
    const getValue = (item, column) => {
      switch (column) {
        case "id":
          return item.id;
        case "role":
          return item.user_level;
        default:
          return item.name;
      }
    };
    const valueA = getValue(a, column);
    const valueB = getValue(b, column);
    if (valueA < valueB) return order === "asc" ? -1 : 1;
    if (valueA > valueB) return order === "asc" ? 1 : -1;
    return 0;
  });
};
export const linkGeneratorUtils = ({ basicPrice, jobs, credits, compute }) => {
  if (compute === "noOfJobs") {
    return credits / ONE_JOB_CREDITS;
  }
  const price = Number(basicPrice) / Number(jobs);
  const perJob = Number(basicPrice) / Number(jobs);
  const discount = (ONE_JOB_CREDITS - perJob) / ONE_JOB_CREDITS;
  const discountPercentage = Number(discount * 100);

  const gstAmount = Number(basicPrice) + (Number(basicPrice) * 18) / 100;
  const goodFor = jobs;

  return {
    pricePerJob: formatNumber(price),
    discountPercentage: formatNumber(discountPercentage),
    gstAmount: formatNumber(gstAmount),
    credits: jobs * ONE_JOB_CREDITS,
    goodFor
  };
};
export const formatNumber = number => {
  try {
    if (Number.isInteger(number)) {
      return number;
    } else {
      return parseFloat(number.toFixed(2));
    }
  } catch (error) {
    return number;
  }
};

export const validateLCSDynamicFields = (formData, leadData) => {
  // Iterate over each form field
  for (let field of formData) {
    // Check if the field is mandatory
    if (field.is_mandatory) {
      // Determine if the field has an 'options' array (indicating a selectable field)
      if (field.field === "chip" || field.field === "select") {
        // Validate if the mandatory field has a selected value
        if (isNullOrEmpty(leadData[field.name])) {
          return `Please provide: ${field.title}`;
        }
      }
      // Check if the field is a date field
      else if (field.field === "date") {
        // Validate if the date field has a value
        if (!leadData[field.name]) {
          return `Please select ${field.title}`;
        }
      }
      // Check if the field is a textbox
      else if (field.field === "textbox") {
        // Validate if the textbox has a value
        if (!leadData[field.name]) {
          return `Please enter ${field.title}`;
        }
      }
    }
  }

  // Return null if no validation errors are found
  return null;
};

export const validityMapper = validity => {
  if (validity === "30 day") {
    return "30 days";
  }
  if (validity === "3 month") {
    return "3 months";
  }
  return validity;
};

export const isLFMSPage = pathName => {
  if (pathName) {
    let arr = pathName.split("/").filter(item => item);
    return arr[0] === "lfms";
  }
  return false;
};

export const isEqualWithTypeCoersion = (a, b, map = new Map()) => {
  if (a == b) return true;
  if (map.has(a) || map.has(b)) return true;
  map.set(a, b);
  if (typeof a === "object" && typeof b === "object") {
    const a_keys = Object.keys(a);
    const b_keys = Object.keys(b);
    if (a_keys.length != b_keys.length) return false;
    for (let i = 0; i < a_keys.length; i++) {
      if (!isEqualWithTypeCoersion(a[a_keys[i]], b[b_keys[i]], map))
        return false;
    }
    return true;
  }
  return false;
};

export const compareObjects = params => {
  const { baseObj, derivedObj, uniqueId = "id", comparatorKeys } = params;
  const result = [];
  derivedObj.map(obj2Item => {
    const baseObjItem = baseObj.find(
      obj1Item => obj1Item[uniqueId] === obj2Item[uniqueId]
    );
    if (baseObjItem !== undefined) {
      for (let comparatorKey of comparatorKeys) {
        if (
          !isEqualWithTypeCoersion(
            baseObjItem[comparatorKey],
            obj2Item[comparatorKey]
          )
        ) {
          result.push(obj2Item);
          break;
        }
      }
    } else {
      result.push(obj2Item);
    }
  });
  return result;
};

export const formattedRequestType = requestType => {
  if (requestType) {
    const action = restrictActionList.find(
      action => requestType === action.value
    );
    return action ? action.label : "";
  }
  return "";
};

export const formattedRestriction = restriction => {
  if (restriction) {
    const action = restrictionStatusList.find(
      action => restriction === action.value
    );
    return action ? action.label : "";
  }
  return "";
};

export function validatePAN(pan) {
  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  return panRegex.test(pan);
}
export function validateCheque(chequeNO) {
  const chequeNoPattern = /^\d{6}$/;
  return chequeNoPattern.test(chequeNO);
}

export function validatePayUid(payUid) {
  const payUidPattern = /^\d{11}$/;
  return payUidPattern.test(payUid);
}

export function extractPANFromGST(gstNumber) {
  const gstPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][1-9A-Z][Z][0-9A-Z]$/;
  if (!gstPattern.test(gstNumber)) {
    return null;
  }
  const panPattern = /^[0-9]{2}([A-Z]{5}[0-9]{4}[A-Z])/;
  const match = gstNumber.match(panPattern);
  return match ? match[1] : null;
}
