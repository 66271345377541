import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { VIEW_CAMPAIGN } from "../../routes/RouteConstants";
import { checkPermission } from "../../services/utils";
import NoAccess from "../Common/NoAccess";
import withRouter from "../../services/withRouter";
import { getCampaignDetails } from "../../services/lcsCampaign";
import LoadingSpinner from "../LoadingSpinner";
import PopupMessage from "../PopupMessage";
import CampaignCreation from "./CampaignCreation";

const EditCampaign = () => {
  const { id } = useParams();
  const isAccess =
    checkPermission("CampaignManagement") ||
    checkPermission("CampaignManagementAdmin");
  const [isLoading, setLoader] = useState(true);
  const [campaignData, setCampaignData] = useState({});
  const [msgData, setMessage] = useState({
    msg: "",
    showMsg: false,
    msgType: "error"
  });

  function callCampaignDetailsApi() {
    getCampaignDetails(id)
      .then(data => {
        const jobIds = data?.job_ids?.length ? data.job_ids.join(",") : "";
        const newData = { ...data, jobIds };
        setCampaignData(newData);
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        setMessage({
          msg: error.toString(),
          showMsg: true,
          msgType: "error"
        });
      });
  }
  function getUpdatedResponse() {
    callCampaignDetailsApi();
  }

  useEffect(() => {
    if (isAccess && id) {
      callCampaignDetailsApi();
    }
  }, [isAccess, id]);

  const handleClosePopup = () => {
    setMessage({ msg: "", showMsg: false });
  };

  if (!isAccess)
    return (
      <>
        <div className="clr gap-20" />
        <NoAccess />
      </>
    );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="clr gap-20" />
      <PopupMessage data={msgData} click={handleClosePopup} />
      <div className="pageHeading row">
        <h2>
          Edit Campaign
          <div className="float-right">
            <a href={VIEW_CAMPAIGN}>Go Back</a>
          </div>
        </h2>
      </div>
      <div className="gap-20" />
      <div className="whitebox">
        <CampaignCreation
          data={campaignData}
          campaignId={id}
          callBack={getUpdatedResponse}
        />
      </div>
    </>
  );
};

export default withRouter(EditCampaign);
