import { isLmsModerator } from "../../services/utilsV2";
import useInsideLeadStore from "../../zustandService/useInsideLeadStore";
import CustomSelect from "../ui/CustomSelect";
import { Label } from "../ui/label";

const AssignedTo = ({
  fieldItems,
  setFieldItems,
  label = "Executive",
  fieldName = "moderatorIds"
}) => {
  const { moderatorList } = useInsideLeadStore(state => ({
    moderatorList: state.moderatorList
  }));

  const handleChange = option => {
    setFieldItems({
      ...fieldItems,
      [fieldName]: option?.id || ""
    });
  };
  if (isLmsModerator()) return null;
  return (
    <>
      <div className="grid gap-2">
        <Label htmlFor="assign-to">{label}</Label>
        <CustomSelect
          options={moderatorList}
          value={fieldItems[fieldName]}
          onChange={handleChange}
          placeholder={`Select ${label}`}
        />
      </div>
    </>
  );
};
export default AssignedTo;
