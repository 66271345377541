import { useState } from "react";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from "../ui/table";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectItem
} from "../ui/select";
import { Checkbox } from "../ui/checkbox";
import useLeadAssignmentStore from "../../zustandService/useLeadAssignmentStore";
import { customUseEffect } from "../../services/utils";
import SubmitButton from "./submitButton";

const RenderActiveCheckBox = props => {
  const { moderatorConfigLocalState, updateModeratorConfigState } =
    useLeadAssignmentStore(state => ({
      moderatorConfigLocalState: state.moderatorConfigLocalState,
      updateModeratorConfigState: state.updateModeratorConfigState
    }));
  const { row, columnValue } = props;
  if (columnValue !== "isActive") return null;
  const { id } = row;
  const { isActive = false } = moderatorConfigLocalState.find(
    item => item.id == id
  );
  const updateIsActive = () => {
    updateModeratorConfigState(id, { isActive: !isActive });
  };
  return <Checkbox checked={!!isActive} onCheckedChange={updateIsActive} />;
};

const RenderTlSelect = props => {
  const { teamLeads, updateModeratorConfigState, moderatorConfigLocalState } =
    useLeadAssignmentStore(state => ({
      teamLeads: state.teamLeads,
      updateModeratorConfigState: state.updateModeratorConfigState,
      moderatorConfigLocalState: state.moderatorConfigLocalState
    }));
  const { row, columnValue } = props;
  const { isTl = false, id } = row;
  const { tlId = null } = moderatorConfigLocalState.find(item => item.id == id);

  if (columnValue !== "isTl" || isTl) return null;
  const updateTl = changedTlId => {
    updateModeratorConfigState(id, { tlId: changedTlId });
  };
  return (
    <Select value={tlId ? tlId : undefined} onValueChange={updateTl}>
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Select TL" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Team Leads</SelectLabel>
          {teamLeads.map((item, index) => {
            return (
              <SelectItem key={index} value={item.id}>
                {item.name}
              </SelectItem>
            );
          })}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
export default function ModeratorLevel() {
  const {
    moderatorConfig,
    moderatorConfigFetchState,
    fetchModeratorConfig,
    moderatorLevelColumns,
    saveModeratorConfig
  } = useLeadAssignmentStore(state => ({
    moderatorConfig: state.moderatorConfig,
    moderatorConfigFetchState: state.moderatorConfigFetchState,
    fetchModeratorConfig: state.fetchModeratorConfig,
    moderatorLevelColumns: state.moderatorLevelColumns,
    saveModeratorConfig: state.saveModeratorConfig
  }));
  customUseEffect(() => {
    fetchModeratorConfig();
  });
  if (moderatorConfigFetchState === "LOADING") return null;
  return (
    <div>
      <SubmitButton onClick={saveModeratorConfig} />
      <div className="w-full border rounded-xl">
        <Table>
          <TableHeader>
            <TableRow>
              {Object.keys(moderatorLevelColumns).map((colums, index) => {
                return <TableHead key={index}>{colums}</TableHead>;
              })}
            </TableRow>
          </TableHeader>
          <TableBody>
            {moderatorConfig.map((row, index) => {
              return (
                <TableRow key={index}>
                  {Object.values(moderatorLevelColumns).map(
                    (columnValue, index) => {
                      return columnValue === "isTl" ||
                        columnValue === "isActive" ? (
                        <TableCell key={index}>
                          <RenderTlSelect row={row} columnValue={columnValue} />
                          <RenderActiveCheckBox
                            row={row}
                            columnValue={columnValue}
                          />
                        </TableCell>
                      ) : (
                        <TableCell key={index}>{row[columnValue]}</TableCell>
                      );
                    }
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
