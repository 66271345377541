import JobHai from "../../api/JobHai";
import { getErrorMessage } from "../utils";
import { formatDateTime } from "../utilsV2";

// Helper function to clean up filters and params
const cleanParams = params => {
  const filters = { ...params.filters };
  const countItems = ["jobCount", "creditsPurchased", "creditsAvailable"];
  const dateItems = [
    "assignedDate",
    "followupDate",
    "churnDate",
    "saleDate",
    "unallocationDate"
  ];
  // Helper function to handle min and max filters
  const handleMinMax = filter => {
    const { min, max } = filters[filter];
    filters[filter] = {
      min: min ? Number(min) : undefined,
      max: max ? Number(max) : undefined
    };
  };

  // Helper function to handle date filters
  const handleDate = filter => {
    const { from, to } = filters[filter];
    filters[filter] = {
      from: formatDateTime(from),
      to: formatDateTime(to, "23:59:59")
    };
  };

  if (filters?.bucketIds?.includes("ALL")) delete filters.bucketIds;
  if (filters?.leadType === "ALL") delete filters.leadType;
  // Handle min-max filters
  countItems.forEach(filter => {
    if (filters[filter]) handleMinMax(filter);
  });

  // Handle date filters
  dateItems.forEach(filter => {
    if (filters[filter]) handleDate(filter);
  });

  const cleanedParams = {
    ...params,
    filters
  };

  return cleanedParams;
};

const apiCall = async (endpoint, params) => {
  try {
    const response = await JobHai.post(endpoint, params);
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const getLeads = async (params = {}) => {
  const cleanedParams = cleanParams(params);
  return await apiCall("/lms/v1/leads", cleanedParams);
};

export const getBucketCount = async (params = {}) => {
  delete params.page; // not required
  delete params.size;
  delete params.filters?.bucketIds;
  const cleanedParams = cleanParams(params);
  return await apiCall("/lms/v1/bucket-count", cleanedParams);
};

export const getInsideSalesModerators = async () => {
  try {
    const response = await JobHai.get("/moderators-mapping");
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const getCompanies = async (params = {}) => {
  return await apiCall("/lms/v1/companies", params);
};

export const fetchRecruiterDetailsLms = async ({ recruiterId, phoneNo }) => {
  const params = [];
  if (recruiterId) params.push(`recruiterId=${recruiterId}`);
  if (phoneNo) params.push(`phoneNo=${phoneNo}`);
  params.push(`withoutRestrictions=true`);

  const url = `/lms/recruiter-details?${params.join("&")}`;
  const response = await JobHai.get(url);
  return response.data.data;
};

export const checkForAssignment = async ({ recruiterId }) => {
  const url = `/lms/check-for-manual-lead-addition`;
  const res = await JobHai.post(url, { recruiterId: recruiterId });
  return res.data.data;
};

export const addManualLead = async ({ moderatorId, recruiterId, remarks }) => {
  const url = `lms/add-manual-lead`;
  console.log("rrr", recruiterId, moderatorId, remarks);
  const res = await JobHai.post(url, {
    recruiterId: recruiterId,
    moderatorId,
    remarks
  });
  return res;
};
export const reAssignCompanies = async ({
  actionType,
  moderatorId,
  orgIds,
  extension
} = {}) => {
  const apiURL =
    actionType === "reallocation"
      ? "/lms/reallocate-org"
      : "/lms/extend-allocation";
  const obj = {
    orgIds,
    ...(actionType === "reallocation" ? { moderatorId } : { extension })
  };

  return await apiCall(apiURL, obj);
};

export const reviveLead = async ({ leadId }) => {
  const url = `/lms/revive-lead/`;
  const res = await JobHai.post(url, { leadId });
  return res.data.data;
};

export const getSales = async params => {
  return await apiCall("/lms/v1/sales", params);
};

export const getSalesSummary = async params => {
  const filters = params?.filters;
  return await apiCall("/lms/v1/sales-summary", { filters });
};
