import { useEffect, useState } from "react";
import MetaData from "../../constants/MetaData";
import ClientDetails from "./ClientDetails";
import {
  getFormattedValue,
  isEditedFields,
  getCityName,
  getLocalityName
} from "../../services/utils";
import Heading from "../Common/Form/Heading";
import Label from "../Common/Form/Label";
import CustomTextField from "../Common/Form/CustomTextField";
import Chips from "../Common/Chips";
import { commonTextFieldLimit } from "../../services/constant";

const InterviewDetails = props => {
  const [isCompEditable, handleCompEditable] = useState(true);
  const [cityName, setCityName] = useState("");
  const [localityName, setLocalityName] = useState("");

  const handleChange = event => {
    let { name, value } = event.target;
    value = getFormattedValue(value);
    props.getFieldData(name, value);
  };

  const handleClientChange = obj => {
    const { id = "", label = "" } = obj;
    props.getFieldData("client_id", id);
    props.getFieldData("company_name", label);
  };

  const handleChips = (event, fieldName, item) => {
    const { interview_type = "" } = props.data || [];
    const value = item.id === interview_type ? "" : item.id;
    props.getFieldData(fieldName, value);
  };

  const {
    isConsultant = false,
    clientList = [],
    editedFields = [],
    data
  } = props;
  const {
    interview_address = "",
    interview_type = "",
    client_id = "",
    company_name = "",
    AppRecruiter = {}
  } = data;
  const { AppCompany = {} } = AppRecruiter;
  const { city_id = "", locality_id = "" } = AppCompany;

  useEffect(() => {
    const fetchCityName = async () => {
      const name = await getCityName(city_id);
      setCityName(name || "NA");
    };
    fetchCityName();
  }, [city_id]);

  useEffect(() => {
    const fetchLocalityName = async () => {
      const name = await getLocalityName(city_id, locality_id);
      setLocalityName(name || "NA");
    };
    fetchLocalityName();
  }, [locality_id]);

  const compName = AppCompany.company_name || null;
  const recInfo = `${compName} (${cityName}, ${localityName})`;
  const interviewTypeList = MetaData.interviewType;
  const interviewType = interviewTypeList.filter(function (item) {
    return item.id === interview_type;
  });
  const activeList = interviewType.length ? interviewType[0] : [];
  return (
    <>
      <Heading heading="Interview Details" />
      <div className="formRow">
        <div className="left">
          <Label
            lable="Interview Type"
            isEdited={isEditedFields(editedFields, "interview_type")}
          />
          <Chips
            chips={interviewTypeList}
            activeChips={activeList}
            onClick={handleChips}
            isMulti={false}
            fieldName="interview_type"
            isClose={true}
          />
        </div>
        <div className="left">
          <Label
            lable="Interview Address"
            isEdited={isEditedFields(editedFields, "interview_address")}
          />
          <CustomTextField
            name="interview_address"
            value={interview_address ? interview_address : ""}
            onChange={handleChange}
            maxLength={commonTextFieldLimit}
          />
        </div>
      </div>
      {isConsultant ? (
        <ClientDetails
          clientList={clientList}
          clientId={client_id}
          clientName={company_name}
          onChange={handleClientChange}
          recDetails={recInfo}
        />
      ) : (
        <div className="formRow">
          <div className="left">
            <Label
              lable="Company Name"
              isEdited={isEditedFields(editedFields, "company_name")}
            />
            <CustomTextField
              name="company_name"
              value={company_name ? company_name : ""}
              onChange={handleChange}
              isReadonly={isCompEditable}
              endAdornment={
                isCompEditable ? (
                  <span
                    className="editLink"
                    onClick={() => handleCompEditable(false)}
                  >
                    Edit
                  </span>
                ) : null
              }
              maxLength={commonTextFieldLimit}
            />
            <div className="recDetails">
              Recruiter Company Details: <span>{recInfo}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InterviewDetails;
