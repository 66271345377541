import React, { useEffect, useMemo, useState } from "react";
import { withStyles } from "@material-ui/styles";
import cx from "classnames";
import moment from "moment";
import { styles, getData } from "../CreditWidget/helper";
import LoadingSpinner from "../LoadingSpinner";
import SkeletonLoader from "../SkeletonLoader";
import OrderIdGeneration from "./OrderIdGeneration";
import CreditsAddition from "./CreditsAddition";
import CreditsTransfer from "./CreditsTransfer";
import CreditsPurge from "./CreditsPurge";
import { TRANSACTION_HISTORY } from "../../routes/RouteConstants";
import LinkGenerate from "./LinkGenerate";

const STATES = {
  FETCHING: "FETCHING",
  FETCHED: "FETCHED",
  ERROR: "ERROR",
  PRELIMINARY: "PRELIMINARY",
  FINAL: "FINAL",
  SUCCESS: "SUCCESS"
};

function CreditWidget({ classes, recId }) {
  const [dataState, setDataState] = useState();
  const [data, setData] = useState({});
  const [postingState, setPostingState] = useState(STATES.FETCHED);
  const [showMore, setShowMore] = useState(false);
  const [totalCredits, setTotalCredits] = useState(0);

  useEffect(() => {
    setDataState(STATES.FETCHING);

    if (recId) {
      getData(recId)
        .then(response => {
          setDataState(STATES.FETCHED);
          setTotalCredits(response?.totalAvailableCredits);
          setData(response);
        })
        .catch(() => {
          setDataState(STATES.ERROR);
        });
    }
  }, [recId]);

  const freeJobKeyWord = useMemo(
    () => (data && data.monthlyBasicJobsLeft === 1 ? "job" : "jobs"),
    [data]
  );

  function handleShowMore() {
    setShowMore(!showMore);
  }
  const {
    monthlyBasicJobsLeft,
    showExpiringCredits,
    creditsGettingExpired,
    creditsDateOfExpiry
  } = data;
  return (
    <div className={cx(classes.root)}>
      {dataState === STATES.FETCHING && (
        <div className="loading">
          <LoadingSpinner />
        </div>
      )}
      {dataState === STATES.FETCHED && (
        <>
          <div className={cx(classes.padding, classes.component1)}>
            {postingState !== STATES.FETCHING ? (
              <div className={cx("flex", "center", "bottomSpacing")}>
                <img
                  src="/images/free_job.svg"
                  alt="Add"
                  width="12"
                  height="12"
                />
                <b>
                  {monthlyBasicJobsLeft} Free {freeJobKeyWord}
                </b>{" "}
                <span>&nbsp; left</span>
              </div>
            ) : (
              <SkeletonLoader customClass={classes.jobLoader} />
            )}
            {postingState !== STATES.FETCHING ? (
              <div className="flex">
                <img
                  src="/images/credit.svg"
                  alt="Add"
                  width="18"
                  height="18"
                />
                <div>
                  <div className="bottomSpacing">
                    <b>{totalCredits}</b>
                    <span>&nbsp; Credit available</span>
                    <span className="viewall">
                      <a
                        href={`${TRANSACTION_HISTORY}?recruiterId=${recId}`}
                        target="_blank"
                      >
                        View All
                      </a>
                    </span>
                  </div>
                  {showExpiringCredits && (
                    <div>
                      {creditsGettingExpired} Expiring on{" "}
                      {moment(creditsDateOfExpiry).format("DD MMM, YYYY")}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <SkeletonLoader customClass={classes.jobLoader} />
            )}
          </div>

          {data.visible ? (
            <div
              className={cx(
                classes.header,
                classes.padding,
                classes.container2,
                "flexCol",
                "container2Padding"
              )}
            >
              <LinkGenerate admin={data.adminVisible} recId={recId} />
              {data.adminVisible && (
                <>
                  <span
                    className={cx("viewall", "showAll")}
                    onClick={handleShowMore}
                  >
                    {showMore ? `Hide ` : `Show `} More Options
                  </span>
                  {showMore ? (
                    <>
                      {/* <OrderIdGeneration recId={recId} /> */}

                      <hr />
                      <CreditsAddition
                        recId={recId}
                        setTotalCredits={setTotalCredits}
                      />
                      <hr />
                      <CreditsTransfer
                        recId={recId}
                        setTotalCredits={setTotalCredits}
                      />
                      <hr />
                      <CreditsPurge
                        recId={recId}
                        setTotalCredits={setTotalCredits}
                      />
                    </>
                  ) : null}
                </>
              )}
            </div>
          ) : null}
        </>
      )}
      {dataState === STATES.ERROR && (
        <div>Error Loading Job Count Data.....</div>
      )}
    </div>
  );
}

export default withStyles(styles)(React.memo(CreditWidget));
