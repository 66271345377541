import JobHai from "../../api/JobHai";
import { getCustomErrorMessage, getErrorMessage } from "../utils";

export const getCampaignList = ({ pageSize = 50, page = 1 }) => {
  return new Promise((resolve, reject) => {
    JobHai.get(`get-lcs-campaign?page=${page}&size=${pageSize}`)
      .then(response => {
        resolve(response.data.data); // Resolve with the data
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        reject(msg); // Reject with the error message
      });
  });
};

export const getCampaignDetails = compaignId => {
  return new Promise((resolve, reject) => {
    JobHai.get(`get-campaign-info?campaign_id=${compaignId}`)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        reject(msg);
      });
  });
};

export const getCampaignQuestions = compaignId => {
  return new Promise((resolve, reject) => {
    JobHai.get(`get-lcs-campaign-questions?campaign_id=${compaignId}`)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        reject(msg);
      });
  });
};
export const getLcsMetdadata = () => {
  return new Promise((resolve, reject) => {
    JobHai.get(`../v2/metadata/lcs-campaign-creation-fields`)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        reject(msg);
      });
  });
};

export const saveCampaignDetails = data => {
  return new Promise((resolve, reject) => {
    JobHai.post(`save-lcs-campaign`, data)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        let msg = getCustomErrorMessage(error);
        if (typeof msg === "object") {
          reject(Object.values(msg).join(""));
        }
        reject(msg);
      });
  });
};

export const saveBannerData = data => {
  return new Promise((resolve, reject) => {
    JobHai.post(`upload-lcs-image`, data)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        reject(msg);
      });
  });
};

export const saveCampaignQuestion = data => {
  return new Promise((resolve, reject) => {
    JobHai.post(`save-lcs-questions`, data)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        reject(msg);
      });
  });
};

export const copyCampaign = async item => {
  try {
    const { qns, campaignId } = item;
    if (campaignId) {
      // Fetch campaign details
      const campaignDetails = await getCampaignDetails(campaignId);
      const newDataObj = {
        title: `Copy - ${campaignDetails.title}`,
        default_language: campaignDetails.default_language,
        is_active: campaignDetails.is_active,
        is_active_in_screening_flow:
          campaignDetails.is_active_in_screening_flow,
        is_active_for_job_mapping: campaignDetails.is_active_for_job_mapping,
        allowed_languages: campaignDetails.allowed_languages,
        priority: campaignDetails.priority,
        campaign_group: campaignDetails.campaign_group,
        description: `Copy - ${campaignDetails.description}`,
        city_ids: campaignDetails.city_ids,
        allowed_categories: campaignDetails.allowed_categories,
        allowed_assets: campaignDetails.allowed_assets,
        allowed_skills: campaignDetails.allowed_skills,
        allowed_prior_experiences: campaignDetails.allowed_prior_experiences,
        excluded_campaigns: campaignDetails.excluded_campaigns,
        form_impression_limit: campaignDetails.form_impression_limit,
        banner_impression_limit: campaignDetails.banner_impression_limit,
        allowed_source: campaignDetails.allowed_source,
        client_redirection_url_visible:
          campaignDetails.client_redirection_url_visible,
        client_redirection_url: campaignDetails.client_redirection_url,
        skip_questionniare_redirect:
          campaignDetails.skip_questionniare_redirect,
        is_conditional: campaignDetails.is_conditional,
        is_whatsapp_campaign: campaignDetails.is_whatsapp_campaign,
        start_date: campaignDetails.start_date,
        end_date: campaignDetails.end_date,
        job_ids: campaignDetails.job_ids,
        client_id: campaignDetails.client_id,
        client_name: "NA",
        terms_and_condition_visible:
          campaignDetails.terms_and_condition_visible,
        terms_and_condition_text: campaignDetails.terms_and_condition_text,
        banner_vernac_url: campaignDetails.banner_vernac_url,
        srp_banner_url_languages: campaignDetails.srp_banner_url_languages,
        vernac_banner_heading: campaignDetails.vernac_banner_heading,
        logo_url: campaignDetails.logo_name,
        vernac_post_apply_title: campaignDetails.vernac_post_apply_title,
        multibanner_urls: campaignDetails.multibanner_urls
      };
      // ToDo - Campaign Data Copy Issue
      // Save duplicated campaign details
      const newCampaignResponse = await saveCampaignDetails(newDataObj);
      const newCampaignId = newCampaignResponse?.id;

      // If qns is true, copy campaign questions
      if (newCampaignId && qns) {
        const campaignQuestions = await getCampaignQuestions(campaignId);
        const { questions } = campaignQuestions;
        if (questions.length) {
          await saveCampaignQuestion({
            ...campaignQuestions,
            campaign_id: newCampaignId,
            campaign_status: undefined
          });
        }
      }
      return newCampaignId;
    }
  } catch (error) {
    console.error("Error copying campaign:", error);
    throw error; // Re-throw the error for the caller to handle
  }
};

export const updateCampaignStatus = ({ campaignId, status }) => {
  return new Promise((resolve, reject) => {
    JobHai.post(`change-campaign-status`, {
      campaign_id: campaignId,
      state: status
    })
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        let msg = getCustomErrorMessage(error);
        reject(msg);
      });
  });
};

export const uploadImage = data => {
  return new Promise((resolve, reject) => {
    JobHai.post(`upload-image`, data)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        reject(msg);
      });
  });
};
