import React from "react";
import {
  getErrorMessage,
  setResponse,
  getResponse,
  removeItem,
  replaceTagWithLineBreak,
  getActiveItemList,
  getOtherMethodInfo,
  isReloadRfd,
  isRecruiterVerified,
  getCustomErrorMessage,
  callPremiumSaveOnStateVsProp,
  getIntent,
  isValidUrl,
  findChipValue,
  fireGoogleAnalyticsEvent
} from "../../services/utils";
import JobHai from "../../api/JobHai";
import LoadingSpinner from "../LoadingSpinner";
import CompanyDocuments from "./CompanyDocuments";
import CompanyDetails from "./CompanyDetails";
import RecruiterDetails from "./RecruiterDetails";
import SaveChanges from "./SaveChanges";
import ViewLogs from "./ViewLogs";
import RightSideBar from "./RightSideBar";
import EditedFieldList from "./EditedFieldList";
import { logEvents } from "../../services/eventsLogs";
import {
  MARK_REVIEWED_CLICK,
  RECRUITER_SAVE_CHANGES_CLICK
} from "../../services/eventsLogs/eventConstants";

class RecruiterVerification extends React.Component {
  state = {
    errorMessage: "",
    companyData: {},
    companyDocuments: [],
    recruiterEditedField: [],
    companyId: "",
    isLoading: true,
    isEmailBounced: false,
    bounceReason: "",
    isSaveDisabled: false,
    isSaved: true,
    isDataSaved: false,
    recEmail: "",
    entityDocStatus: "",
    isDocUpdated: false,
    file: "",
    entityDocFile: "",
    onboardingMethod: "",
    documentType: "",
    rfdFields: {},
    premiumProductIntent: null,
    isMarkedRviewedClick: false,
    recentDocData: {},
    isOrgReviewed: false
  };

  componentDidMount() {
    // const { recId } = this.props;
    // const data = getResponse(recId);
    // if (data && data.recruiter_id) {
    //   this.setInitialState(data);
    // } else this.getCompanyDetails();
    this.getCompanyDetails();
  }

  setInitialState = responseData => {
    const { recId, handleRecDetails } = this.props;
    setResponse(recId, responseData);
    const {
      document_url = "",
      document_type = "",
      document_no = "",
      document_status = "",
      doc_remarks = "",
      reason = "",
      entity_doc_status = "",
      recruiter_edited_field = [],
      bounce_reason = "",
      email = "",
      company_id = "",
      entity_document_url = "",
      recruiter_onboarding_method = "",
      denial_reason = "",
      company_website = "",
      company_name = "",
      recent_doc_data = {},
      is_org_reviewed = "",
      is_rec_decision_maker = null,
      decision_maker_name = "",
      decision_maker_number = ""
    } = responseData;
    delete responseData.verified_email;
    delete responseData.bounce_reason;
    delete responseData.is_email_bounced;
    let recruiterEditedFields = [];
    if (!is_org_reviewed) {
      recruiterEditedFields.push("Company name needs to be cleaned");
    }
    if (recruiter_edited_field.length) {
      recruiterEditedFields.push(...recruiter_edited_field);
    }

    const isRecEdited = recruiter_edited_field.length;
    handleRecDetails({ isRecEdited });

    responseData.locality_id = responseData.company_locality_id;
    responseData.city_id = responseData.company_city_id;
    this.setState({
      companyData: responseData,
      recruiterEditedField: recruiterEditedFields,
      bounceReason: bounce_reason,
      recEmail: email,
      companyId: company_id,
      entityDocStatus: entity_doc_status,
      isLoading: false,
      file: "",
      isSaved: !isRecEdited,
      documentType: document_type,
      companyDocuments: {
        document_url,
        document_type,
        document_no,
        document_status,
        doc_remarks,
        reason,
        entity_doc_status,
        entity_document_url
      },
      rfdFields: {
        denial_reason,
        company_website,
        email,
        document_no,
        company_name
      },
      onboardingMethod: recruiter_onboarding_method.length
        ? recruiter_onboarding_method[0]
        : recruiter_onboarding_method,

      premiumProductIntent: getIntent(
        responseData?.interested_in_premium_offering
      ),
      recentDocData: recent_doc_data,
      isOrgReviewed: is_org_reviewed,
      is_rec_decision_maker,
      decision_maker_name,
      decision_maker_number
    });
  };

  updateMessage = (msg, flag, relaod = false) => {
    if (flag) this.setErrorMessage(msg);
    else this.props.statusMessage(msg, flag);
    if (relaod) {
      this.getCompanyDetails();
    }
  };

  getCompanyDetails = () => {
    const { recId } = this.props;
    JobHai.post("/recruiter-details", { recruiter_id: recId })
      .then(response => {
        let responseData = response.data.data;
        this.setInitialState(responseData);
      })
      .catch(error => {
        let errorMessage = "";
        errorMessage = getErrorMessage(error);
        this.updateMessage(errorMessage, 1);
        this.setState({
          isLoading: false
        });
      });
  };

  getDocFieldData = (fieldName, value) => {
    const docInfo = this.state.companyDocuments;
    let isDocUpdated = false;
    if (fieldName === "file") {
      this.setState({ file: value });
      isDocUpdated = true;
    } else if (fieldName === "entityDocFile") {
      this.setState({ entityDocFile: value });
      isDocUpdated = !!value;
    } else {
      docInfo[fieldName] = value;
      this.setState({ companyDocuments: docInfo });
      isDocUpdated = true;
    }
    this.setState({
      isSaveDisabled: false,
      isDocUpdated,
      isSaved: false
    });
    this.updateLocalStorage(fieldName, value);
  };

  handleIndustryClick = () => {
    this.setState({
      isSaved: false
    });
  };

  getFieldData = (fieldName, value) => {
    const tempCompanyData = this.state.companyData;
    if (fieldName === "city_id") {
      tempCompanyData.locality_id = "";
    }
    tempCompanyData[fieldName] = value;
    this.setState(
      {
        companyData: tempCompanyData,
        isSaveDisabled: false,
        isSaved: false
      },
      () => this.updateLocalStorage(fieldName, value)
    );
  };

  updateLocalStorage = (fieldName, value) => {
    const { recId } = this.props;
    const tempCompanyData = this.state.companyData;
    tempCompanyData[fieldName] = value;
    setResponse(recId, tempCompanyData);
  };

  setErrorMessage = errorMessage => {
    this.setState({ errorMessage });
  };

  handleSaveChanges = async () => {
    const reg = /\S+@\S+\.\S+/;
    const {
      companyData = {},
      recEmail,
      isDocUpdated,
      onboardingMethod,
      isMarkedRviewedClick
    } = this.state;
    const { jobId, rfdData, recId } = this.props;
    const { onboardingCalcKeys = {}, callRecruiter } = rfdData;
    const { onboarding_score = 0 } = onboardingCalcKeys;
    const {
      phone = "",
      email = "",
      recruiter_type,
      remarks,
      company_website = "",
      is_branded_company = "",
      is_rec_decision_maker = null,
      decision_maker_name = "",
      decision_maker_number = ""
    } = companyData;
    let isConsulatant = "";
    if (recruiter_type === "CONSULTANT" || recruiter_type === "BOTH")
      isConsulatant = true;
    else if (recruiter_type === "COMPANY") isConsulatant = false;
    companyData.job_id = jobId;
    companyData.is_consultant = isConsulatant;
    companyData.remarks = replaceTagWithLineBreak(remarks);
    companyData.onboarding_score = onboarding_score;
    companyData.onboardingCalcKeys = onboardingCalcKeys;
    companyData.callRecruiter = callRecruiter;
    companyData.onboardingMethod = onboardingMethod;

    if (is_rec_decision_maker !== null) {
      companyData.is_rec_decision_maker = is_rec_decision_maker;
    }
    if (is_rec_decision_maker === false) {
      companyData.decision_maker_name = decision_maker_name || undefined;
      companyData.decision_maker_number = decision_maker_number || undefined;
    } else {
      companyData.decision_maker_name = null;
      companyData.decision_maker_number = null;
    }

    if (isMarkedRviewedClick) companyData.isReviewedCompany = true;
    if (!phone) {
      this.setErrorMessage("Please enter 10 digit valid recruiter number");
      return;
    }

    if (email && !reg.test(email)) {
      this.setErrorMessage("Please enter a valid recruiter email");
      return;
    }

    if (recEmail && !email) {
      this.setErrorMessage("Please don't delete existing email");
      return;
    }
    if (company_website && !isValidUrl(company_website)) {
      this.setErrorMessage("Please enter valid website url");
      return;
    }
    if (!findChipValue(is_branded_company)) {
      this.setErrorMessage("Please select the branded company status");
      return;
    }
    if (isDocUpdated) {
      const isError = await this.documentValidation(); // To Validate documents field
      if (isError) {
        return;
      }
    }

    delete companyData.is_org_reviewed;

    Object.keys(companyData).forEach(function (key) {
      if (companyData[key] === "") {
        companyData[key] = null;
      }
    });

    this.setState({ isSaveDisabled: true, errorMessage: "" });
    this.resetJobDetails(); // To Reset job details from session storage
    callPremiumSaveOnStateVsProp({
      statePremium: this.state.premiumProductIntent,
      propPremium: this.state.companyData?.interested_in_premium_offering,
      jobId: this.props.jobId,
      recId: this.props.recId
    });
    this.handleEvents(isMarkedRviewedClick);
    JobHai.post("/v2/save_recruiter", companyData)
      .then(response => {
        if (isDocUpdated) {
          this.saveFiles();
        } else {
          this.setState(
            {
              isDataSaved: true,
              isMarkedRviewedClick: false
            },
            () => this.callBack()
          );
          this.reloadRfdAPI();
        }
      })
      .catch(error => {
        let errorMessage = "";
        errorMessage = getCustomErrorMessage(error);
        this.updateMessage(errorMessage, 1);
        this.setState({ isSaveDisabled: false });
      });
  };

  /* Start
   * This function will call rfd api
   * after checking the changes in related fields values
   * Rfd related fields are:
   * denial_reason,company_website
   * email,document_no,company_name
   */

  reloadRfdAPI = () => {
    const { rfdFields, companyData } = this.state;
    const { handleRecDetails } = this.props;
    if (isReloadRfd(rfdFields, companyData))
      handleRecDetails({ isReloadRfd: true });
  };

  /* End */

  documentValidation = () => {
    const { file, companyDocuments, entityDocFile, entityDocStatus } =
      this.state;
    const {
      document_url,
      document_type,
      document_no,
      document_status,
      reason,
      entity_doc_status,
      entity_document_url
    } = companyDocuments;
    let docList = {};

    if (localStorage.getItem("crmAllowDocument")) {
      docList = JSON.parse(localStorage.getItem("crmAllowDocument"));
    }
    const selectedItem =
      getActiveItemList(docList, document_type, "type") || {};
    const { validation = [], title = "" } = selectedItem;
    const isDocNumberRequired =
      validation.length || document_type === "OTHER_COMPANY_DOCUMENT";
    if (!file && !document_url) {
      this.setErrorMessage("Please upload file");
      return true;
    }
    if (!document_type) {
      this.setErrorMessage("Please select document type");
      return true;
    }
    if (!document_no && isDocNumberRequired) {
      this.setErrorMessage("Please enter document number");
      return true;
    } else {
      if (
        document_type !== "SHOP_IMAGE" &&
        document_type !== "OTHER_COMPANY_DOCUMENT" &&
        isDocNumberRequired
      ) {
        const regex =
          validation && validation.length
            ? new RegExp(validation[0].slice(1, -1))
            : / /;
        const isRegex = regex.test(document_no);
        if (!document_no || !isRegex) {
          this.setErrorMessage(`Please enter valid ${title}`);
          return true;
        }
      }
    }

    if (!document_status || document_status === "no_document") {
      this.setErrorMessage("Please select document status");
      return true;
    }
    if (document_status === "verified" && document_type === "SHOP_IMAGE") {
      this.setErrorMessage("Shop Image is not verified document");
      return true;
    }
    if (document_status === "rejected" && !reason) {
      this.setErrorMessage("Please select document reject reason");
      return true;
    }
    if (
      entityDocStatus !== entity_doc_status &&
      !entityDocFile &&
      !entity_document_url
    ) {
      if (
        entity_doc_status === "Approved" ||
        entity_doc_status === "Rejected"
      ) {
        this.setErrorMessage("Please upload employment proof");
        return true;
      }
    }
    if (entityDocFile && !entity_doc_status) {
      this.setErrorMessage("Please select employment proof status");
      return true;
    }
  };

  callBack = () => {
    this.setState({
      isSaved: true,
      isSaveDisabled: false
    });
    this.getCompanyDetails();
  };

  saveFiles = () => {
    const { file, entityDocFile } = this.state;
    if (file || entityDocFile) {
      if (file) this.uploadDocuments();
      if (entityDocFile) this.uploadEntityDocuments();
    } else {
      this.updateStatus();
    }
  };

  uploadDocuments = () => {
    const { recId } = this.props;
    const { file, companyDocuments, companyId } = this.state;
    const { document_type, document_no } = companyDocuments;
    let data = new FormData();
    data.append("file", file);
    data.append("company_id", companyId);
    data.append("document_type", document_type);
    if (document_no) data.append("document_no", document_no);
    JobHai.post("/company-verification-document", data)
      .then(response => {
        const data = response.data.data || [];
        const docId = data.id ? data.id : null;
        this.updateStatus(docId);
        fireGoogleAnalyticsEvent({
          eventName: "DOC_VERIFICATION_UPLOAD_SUCCESS",
          recId
        });
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setState({
          isUploadError: false,
          errorMessage: msg
        });
      });
  };

  uploadEntityDocuments = () => {
    const { entityDocFile, companyDocuments, companyId } = this.state;
    const { document_type, document_no, entity_doc_status } = companyDocuments;
    const entityDocStatus = entity_doc_status
      ? entity_doc_status
      : "Not required";
    let data = new FormData();
    data.append("file", entityDocFile);
    data.append("company_id", companyId);
    data.append("document_type", document_type);
    data.append("entity_doc_status", entityDocStatus);
    if (document_no) data.append("document_no", document_no);
    JobHai.post("/entity-document", data)
      .then(response => {
        this.updateEntityDoc(entityDocStatus);
        this.callBack();
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setState({
          isUploadError: false,
          errorMessage: msg
        });
      });
  };

  updateStatus = (docId = false) => {
    const { companyId, companyDocuments, companyData, entityDocStatus } =
      this.state;
    const {
      document_type,
      document_no,
      document_status,
      doc_remarks,
      reason,
      entity_doc_status
    } = companyDocuments;
    const eDocStatus = entity_doc_status ? entity_doc_status : "Not required";
    const { is_email_verified, document_id } = companyData;
    const obj = {
      company_id: companyId,
      document_id: docId ? docId : document_id,
      status: document_status,
      document_type,
      document_no: document_no ? document_no : undefined,
      reason: reason ? reason : null,
      remarks: doc_remarks ? doc_remarks : null,
      is_email_verified
    };
    if (entityDocStatus !== eDocStatus) {
      this.updateEntityDoc(eDocStatus);
    }
    JobHai.post("/v2/update-document-status", obj)
      .then(response => {
        // Call RFD API
        this.props.handleRecDetails({ isReloadRfd: true });
        this.callBack();
        this.setState({ isDocUpdated: false });
      })
      .catch(error => {
        this.setState({
          isSaveDisabled: false
        });
        let msg = getErrorMessage(error);
        this.updateMessage(msg, 1);
      });
  };

  updateEntityDoc = eDocStatus => {
    const { companyId } = this.state;
    const obj = {
      company_id: companyId,
      entity_doc_status: eDocStatus
    };
    JobHai.post("/v2/update-entitydoc-status", obj).catch(error => {
      let msg = getErrorMessage(error);
      this.updateMessage(msg);
    });
  };

  hanldeVerifyJobs = type => {
    this.props.handleTabs(type);
    this.resetJobDetails();
  };

  resetJobDetails = () => {
    const { jobId } = this.props;
    removeItem(jobId);
  };
  handlePremiumIntentCapture = ({ active }) => {
    this.setState({ premiumProductIntent: active.label, isSaved: false });
  };

  handleEvents = (markReview = false) => {
    // Add Event
    const eventName = markReview
      ? MARK_REVIEWED_CLICK
      : RECRUITER_SAVE_CHANGES_CLICK;
    const { jobId, recId } = this.props;
    logEvents(eventName, { job_id: jobId, recruiter_id: recId });
  };

  handleReviewedCompany = () => {
    this.setState({ isMarkedRviewedClick: true }, () =>
      this.handleSaveChanges()
    );
  };
  render() {
    const {
      isLoading,
      companyData,
      recruiterEditedField,
      companyDocuments,
      isSaveDisabled,
      isSaved,
      errorMessage,
      companyId,
      recEmail,
      isDataSaved,
      onboardingMethod,
      documentType,
      premiumProductIntent,
      recentDocData,
      isMarkedRviewedClick,
      isOrgReviewed
    } = this.state;
    const {
      company_details_updated_at,
      unverified_moderator,
      remarks,
      recruiter_status,
      source,
      createdAt,
      is_email_verified,
      interested_in_premium_offering
    } = companyData;

    const moderatorName = unverified_moderator;
    const {
      jobId,
      recId,
      isAllowEdit,
      documentRrejectedReason,
      rfdData,
      handleRfdClick,
      handleOnBoradingScore,
      relaodRfd
    } = this.props;
    const tagList = {
      companyId,
      jobId,
      recId,
      updatedAt: company_details_updated_at,
      createdAt,
      moderatorName,
      source
    };
    const otherMethodDetails = getOtherMethodInfo(
      recEmail,
      is_email_verified,
      documentType
    );
    const sidebarInfo = {
      remarks,
      recruiterStatus: recruiter_status,
      isDataSaved,
      isRecruiterVerified: isRecruiterVerified(recruiter_status),
      onboardingMethod: {
        method: onboardingMethod,
        otherMethodDetails,
        documentType
      },
      interested_in_premium_offering,
      recId,
      jobId,
      premiumProductIntent,
      handlePremiumIntentCapture: this.handlePremiumIntentCapture,
      isPartOfCreditSystem: companyData?.is_part_of_credit_system
    };
    return (
      <div className="rowContainer">
        <div className="leftBar">
          {recruiterEditedField.length ? (
            <EditedFieldList
              editedField={recruiterEditedField}
              showEditedLabel={isOrgReviewed}
            />
          ) : null}
          <div className="containerCard">
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                <div className="company-details">
                  <RecruiterDetails
                    data={companyData}
                    recEmail={recEmail}
                    editedFields={recruiterEditedField}
                    statusMessage={this.updateMessage}
                    getFieldData={this.getFieldData}
                  />
                  <div className="seperator" />
                  {companyId ? (
                    <CompanyDocuments
                      companyDocuments={companyDocuments}
                      statusMessage={this.updateMessage}
                      documentRrejectedReason={documentRrejectedReason}
                      getDocFieldData={this.getDocFieldData}
                      editedFields={recruiterEditedField}
                      recentDocData={recentDocData}
                    />
                  ) : null}
                  <div className="seperator" />
                  <CompanyDetails
                    companyData={companyData}
                    editedFields={recruiterEditedField}
                    getFieldData={this.getFieldData}
                    statusMessage={this.updateMessage}
                    handleIndustryClick={this.handleIndustryClick}
                    isSaved={isSaved}
                    recId={recId}
                    reviewedCompany={this.handleReviewedCompany}
                    isMarkedRviewedClick={isMarkedRviewedClick}
                    isOrgReviewed={isOrgReviewed}
                  />
                </div>
                <SaveChanges
                  onClick={this.handleSaveChanges}
                  disabled={isAllowEdit || isSaveDisabled}
                  isSaved={isSaved}
                  errorMessage={errorMessage}
                />
              </>
            )}
          </div>
          <ViewLogs
            recId={recId}
            type="company"
            statusMessage={this.updateMessage}
          />
        </div>
        <div className="rightBar">
          <div>
            <RightSideBar
              tagList={tagList}
              type="company"
              getFieldData={this.getFieldData}
              onClick={this.hanldeVerifyJobs}
              sidebarInfo={sidebarInfo}
              rfdData={rfdData}
              handleRfdClick={handleRfdClick}
              handleOnBoradingScore={handleOnBoradingScore}
              relaodRfd={relaodRfd}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default RecruiterVerification;
