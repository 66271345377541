import React, { useState, useEffect } from "react";
import PopupMessage from "../PopupMessage";
import {
  CAMPAIGN_DASHBOARD,
  CREATE_CAMPAIGN
} from "../../routes/RouteConstants";
import { checkPermission } from "../../services/utils";
import LoadingSpinner from "../LoadingSpinner";
import NoAccess from "../Common/NoAccess";
import { getCampaignList } from "../../services/lcsCampaign";
import CampaignList from "./CampaignList";
import withRouter from "../../services/withRouter";

const ViewCampaign = () => {
  const isAccess =
    checkPermission("CampaignManagement") ||
    checkPermission("CampaignManagementAdmin");
  const pageSize = 20;
  const [isLoading, setLoader] = useState(true);
  const [campaignList, setCampaignList] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState(0);
  const [msgData, setMessage] = useState({
    msg: "",
    showMsg: false,
    msgType: "error"
  });
  useEffect(() => {
    if (isAccess) {
      //
      getCampaignList({ page: activePage - 1, pageSize })
        .then(data => {
          const { campaign_data = [], count = 0 } = data;
          setCampaignList(campaign_data);
          setCount(count);
          setLoader(false);
        })
        .catch(error => {
          setLoader(false);
          setMessage({ msg: error, showMsg: true, msgType: "error" });
        });
    }
  }, [isAccess, activePage]);

  const handleClosePopup = () => {
    setMessage({ msg: "", showMsg: false });
  };

  function onPageChange(page) {
    setActivePage(page);
  }

  if (isLoading && isAccess) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <div className="clr gap-20" />
      <PopupMessage data={msgData} click={handleClosePopup} />
      <div className="pageHeading row">
        <h2>
          View Campaigns
          <div className="float-right">
            <a href={CREATE_CAMPAIGN}>
              <i className="fa fa-plus" aria-hidden="true"></i> Create New
              Campaign
            </a>
            <span className="sperator">|</span>
            <a href={CAMPAIGN_DASHBOARD}>Go Back</a>
          </div>
        </h2>
      </div>
      <div className="clr gap-20" />
      <div className={"whitebox"}>
        {isAccess ? (
          <CampaignList
            dataList={campaignList}
            activePage={activePage}
            itemsCountPerPage={pageSize}
            totalCount={count}
            onPageChange={onPageChange}
          />
        ) : (
          <>
            <div className="clr gap-20" />
            <NoAccess />
          </>
        )}
        <div className="clr gap-20" />
      </div>
    </>
  );
};

export default withRouter(ViewCampaign);
