import React from "react";
import { getErrorMessage, getJobBackURL } from "../../services/utils";
import JobHai from "../../api/JobHai";
import PopupMessage from "../PopupMessage";
import RecruiterVerification from "./RecruiterVerification";
import Tabs from "./Tabs";
import { checkPermission, removeItem } from "../../services/utils";
import { logEvents } from "../../services/eventsLogs/";
import GoBack from "../Common/GoBack";
import JobVerification from "./JobVerification";
import CustomPagination from "./CustomPagination";
import {
  RFD_API_CALLED,
  RFD_CLICK
} from "../../services/eventsLogs/eventConstants";
import { getLocalStorage, setLocalStorage } from "../../services/localStorage";
import LoadingSpinner from "../LoadingSpinner";
import { getCrmMetaData } from "../../services/metadataApiServices";

class JobEditPage extends React.Component {
  constructor(props) {
    super();
    const { editParams } = props;
    const queryParams = new URLSearchParams(window.location.search);
    const returnURL = queryParams.get("returnURL") || "";
    const {
      page = "organic",
      role = "CLEANER",
      id = "",
      type = "jobs",
      recId
    } = editParams;
    this.state = {
      role,
      page,
      type,
      jobId: id,
      recId,
      msgData: { msg: "", showMsg: false, msgType: "error" },
      documentRrejectedReason: [],
      crmMetaData: [],
      isUpArrow: false,
      isSticky: false,
      recruiterStatus: "",
      otherJobsList: [],
      isRecEdited: false,
      jobEdited: false,
      rfdList: {},
      isRefLoading: true,
      onboardingCalcKeys: {},
      isScoreCalculated: false,
      callRecruiter: false,
      returnURL,
      jobPostMetaData: {},
      restrictedCityCatData: {},
      isLoading: true
    };
  }

  handleClosePopup = () => {
    this.setState({ msgData: [{ msg: "", showMsg: false }] });
  };

  statusMessage = (msg, msgType) => {
    this.setState({
      msgData: {
        msg: msg,
        showMsg: true,
        msgType: !msgType ? "success" : "error"
      },
      isLoading: false
    });
    this.timeoutId = setTimeout(() => {
      this.handleClosePopup();
    }, 5000);
    window.scrollTo(0, 0);
  };

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  componentDidMount() {
    this.getMetadata();
    document.addEventListener("scroll", () => {
      const isSticky = window.scrollY < 121 ? false : true;
      this.setState({ isSticky });
    });
    this.getRFD();
    this.getJobPostMetadata();
  }

  getMetadata = () => {
    getCrmMetaData()
      .then(response => {
        const {
          categories = [],
          perks = [],
          document_rejected_reason = [],
          job_rejected_reasons = [],
          job_rejected_subreasons = []
        } = response;
        this.setState({
          crmMetaData: {
            categories,
            perks,
            jobRejectedReasons: job_rejected_reasons,
            jobRejectedSubReasons: job_rejected_subreasons
          },
          documentRrejectedReason: document_rejected_reason
        });
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.statusMessage(msg, 1);
      });
  };

  getRFD = () => {
    const { jobId, recId, type } = this.state;
    removeItem(jobId); // To Remove Session storage on page refresh
    removeItem(recId); // To Remove Session storage on page refresh

    /* Added Event To Check API Call*/
    logEvents(RFD_API_CALLED, {
      tabType: type,
      recruiter_id: recId,
      job_id: jobId
    });

    /* End Event */
    JobHai.post("/get_rfd_flags", {
      job_id: jobId,
      recruiter_id: recId
    })
      .then(response => {
        let data = response.data.data;
        const {
          blue_flags = [],
          red_flags = [],
          auto_job_live_reject_reasons = [],
          onboardingCalcKeys = {},
          restricted_city_category_data = {}
        } = data;
        let rfdList = [],
          redList = [],
          blueList = [],
          autoList = [];
        let callRecruiter = false;
        if (red_flags.length) {
          redList = red_flags.map(item => ({
            msg: item.msg,
            type: "redFlag"
          }));
          let redFlagList = red_flags.filter(
            item =>
              item.type === "RFD_DEVICE_ID" ||
              item.type === "RFD_COMPANY_DOC" ||
              item.type === "RFD_JOB_CONTACT"
          );
          callRecruiter = redFlagList.length > 0;
        }
        if (blue_flags.length) {
          blueList = blue_flags.map(item => ({
            msg: item.msg,
            type: "blueFlag"
          }));
        }
        if (auto_job_live_reject_reasons.length) {
          autoList = auto_job_live_reject_reasons.map(item => ({
            msg: item,
            type: "autoLive"
          }));
        }
        rfdList = autoList.concat(redList).concat(blueList);
        this.setState({
          rfdList,
          isRefLoading: false,
          onboardingCalcKeys,
          callRecruiter,
          restrictedCityCatData: restricted_city_category_data
        });
      })
      .catch(error => {
        let errorMessage = "";
        errorMessage = getErrorMessage(error);
        this.setState({
          msgData: { msg: errorMessage, showMsg: true, msgType: "error" },
          isRefLoading: false
        });
      });
  };

  getJobPostMetadata = () => {
    const jobPostFieldV3 = `jobPostFieldV3`;
    let jobPostMetaData = JSON.parse(getLocalStorage(jobPostFieldV3));
    if (jobPostMetaData) {
      this.setState({
        jobPostMetaData,
        isLoading: false
      });
    } else {
      JobHai.get("../v2/metadata/v3/job_post")
        .then(metadata => {
          let data = metadata.data.data;
          const {
            job_fee_deposit_reasons: {
              options: feeDepositReasonList = []
            } = {},
            job_fee_deposit_time: { options: feeDepostTimeList = [] } = {},
            job_type_location: { options: jobTypeLocation = [] } = {}
          } = data;
          const jobPostMetaData = {
            feeDepositReasonList,
            feeDepostTimeList,
            jobTypeLocation
          };
          setLocalStorage(jobPostFieldV3, JSON.stringify(jobPostMetaData));
          this.setState({
            isLoading: false,
            jobPostMetaData
          });
        })
        .catch(error => {
          let errorMessage = "";
          errorMessage = getErrorMessage(error);
          this.statusMessage(errorMessage, 1);
        });
    }
  };

  handleTabs = type => {
    const { isScoreCalculated } = this.state;
    if (isScoreCalculated) this.relaodRfd();
    this.setState({ type, recruiterStatus: "", isScoreCalculated: false });
  };

  handleJobDetails = jobInfo => {
    const { recruiterStatus, isRecEdited, other_jobs, jobEdited } = jobInfo;
    this.setState({
      recruiterStatus,
      isRecEdited,
      otherJobsList: other_jobs,
      jobEdited
    });
  };

  relaodRfd = () => {
    this.getRFD();
  };

  handleRecDetails = obj => {
    const { isRecEdited = false, isReloadRfd = false } = obj;
    this.setState({ isRecEdited });
    if (isReloadRfd) this.getRFD();
  };

  handleRfdClick = (e, item) => {
    let { href = "" } = e.target;
    const { type, recId } = this.state;
    // Event will call in case of anchor tag clicked
    if (href) {
      logEvents(RFD_CLICK, { ...item, tabType: type, recruiter_id: recId });
    }
  };

  handleOnBoradingScore = () => {
    this.setState({ isScoreCalculated: true });
  };

  render() {
    const {
      jobId,
      recId,
      page,
      type,
      role,
      msgData,
      crmMetaData,
      documentRrejectedReason,
      isSticky,
      recruiterStatus,
      otherJobsList,
      isRecEdited,
      rfdList,
      isRefLoading,
      jobEdited,
      onboardingCalcKeys,
      callRecruiter,
      returnURL,
      jobPostMetaData,
      restrictedCityCatData,
      isLoading
    } = this.state;
    let accessPage = role === "QA" ? "QA" : "Cleaning / Calling";
    if (page === "live") accessPage = "Live Jobs";
    if (page === "edited") accessPage = "Edited Jobs";
    const isAllowEdit = !checkPermission(accessPage, true);
    let goBackUrl = getJobBackURL(page, role, recId, returnURL);
    const rfdData = {
      isRefLoading,
      rfdList,
      onboardingCalcKeys,
      callRecruiter,
      restrictedCityCatData
    };

    if (isLoading) return <LoadingSpinner />;
    return (
      <>
        {isSticky ? (
          <div className="topSticky">
            <Tabs
              type={type}
              isSticky={isSticky}
              recruiterStatus={recruiterStatus}
              href={goBackUrl}
              onClick={this.handleTabs}
              isRecEdited={isRecEdited}
              jobEdited={jobEdited}
            />
          </div>
        ) : null}

        <div className="form-container">
          <PopupMessage data={msgData} click={this.handleClosePopup} />
          {!isSticky ? (
            <GoBack href={goBackUrl} lable={"Go back to main list"} />
          ) : null}
          {jobId || recId ? (
            <>
              <div className="topContainer">
                {!isSticky ? (
                  <>
                    <Tabs
                      type={type}
                      recruiterStatus={recruiterStatus}
                      href={goBackUrl}
                      onClick={this.handleTabs}
                      isRecEdited={isRecEdited}
                      jobEdited={jobEdited}
                    />
                    {type === "jobs" && otherJobsList.length ? (
                      <CustomPagination
                        otherJobsList={otherJobsList}
                        jobId={jobId}
                        recId={recId}
                        page={page}
                        role={role}
                      />
                    ) : null}
                  </>
                ) : null}
              </div>
              {type === "jobs" ? (
                <JobVerification
                  jobId={jobId}
                  recId={recId}
                  page={page}
                  role={role}
                  statusMessage={this.statusMessage}
                  isAllowEdit={isAllowEdit}
                  crmMetaData={crmMetaData}
                  jobPostMetaData={jobPostMetaData}
                  isSticky={isSticky}
                  handleJobDetails={this.handleJobDetails}
                  relaodRfd={this.relaodRfd}
                  rfdData={rfdData}
                  handleRfdClick={this.handleRfdClick}
                  handleOnBoradingScore={this.handleOnBoradingScore}
                />
              ) : (
                <RecruiterVerification
                  jobId={jobId}
                  page={page}
                  recId={recId}
                  isAllowEdit={isAllowEdit}
                  documentRrejectedReason={documentRrejectedReason}
                  statusMessage={this.statusMessage}
                  isSticky={isSticky}
                  handleTabs={this.handleTabs}
                  handleRecDetails={this.handleRecDetails}
                  rfdData={rfdData}
                  handleRfdClick={this.handleRfdClick}
                  handleOnBoradingScore={this.handleOnBoradingScore}
                  relaodRfd={this.relaodRfd}
                />
              )}
            </>
          ) : null}
        </div>
      </>
    );
  }
}

export default JobEditPage;
