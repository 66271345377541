import { useEffect, useState } from "react";
import { VIEW_CAMPAIGN } from "../../routes/RouteConstants";
import { getCampaignDetails } from "../../services/lcsCampaign";
import { checkPermission } from "../../services/utils";
import withRouter from "../../services/withRouter";
import NoAccess from "../Common/NoAccess";
import LoadingSpinner from "../LoadingSpinner";
import CampaignCreation from "./CampaignCreation";

const AddCampaign = () => {
  const [isLoading, setLoader] = useState(true);
  const [campaignData, setCampaignData] = useState({});
  const queryParams = new URLSearchParams(window.location.search);
  const duplicateCampaignId = queryParams.get("duplicateCampaignId");

  function getCampaignInfo(duplicateCampaignId) {
    getCampaignDetails(duplicateCampaignId)
      .then(data => {
        const jobIds = data?.job_ids?.length ? data.job_ids.join(",") : "";
        const newData = { ...data, jobIds };
        setCampaignData(newData);
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
      });
  }
  useEffect(() => {
    if (duplicateCampaignId) {
      getCampaignInfo(duplicateCampaignId);
    } else {
      setLoader(false);
    }
  }, [duplicateCampaignId]);

  const isAccess =
    checkPermission("CampaignManagement") ||
    checkPermission("CampaignManagementAdmin");

  if (!isAccess)
    return (
      <>
        <div className="clr gap-20" />
        <NoAccess />
      </>
    );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="clr gap-20" />
      <div className="pageHeading row">
        <h2>
          {duplicateCampaignId
            ? `Create Duplicate Campaign From ${duplicateCampaignId}`
            : `Create New Campaign`}
          <div className="float-right">
            <a href={VIEW_CAMPAIGN}>Go Back</a>
          </div>
        </h2>
      </div>
      <div className="gap-20" />
      <div className="whitebox">
        <CampaignCreation data={campaignData} />
      </div>
    </>
  );
};

export default withRouter(AddCampaign);
