import React from "react";
import { CRAWLED_RECRUITER } from "../../../routes/RouteConstants";

function CrawledJobTab(props) {
  const { role = "QA", active = "jobs" } = props;
  return (
    <div className="organic-tab">
      <ul className="nav">
        <li className="nav-item">
          <a
            className={active === "jobs" ? "nav-link active" : "nav-link"}
            aria-current="page"
            href={`/jobs/crawled/${role}`}
          >
            Crawled Job
          </a>
        </li>
        <li className="nav-item">
          <a
            className={active === "recruiter" ? "nav-link active" : "nav-link"}
            href={`${CRAWLED_RECRUITER}/${role}`}
          >
            Crawled Recruiter
          </a>
        </li>
      </ul>
    </div>
  );
}
export default CrawledJobTab;
