import { Card ,CardHeader, CardTitle, CardContent} from '../../ui/card';
// import { Button } from '@/components/ui/button';
import { Sparkle } from 'lucide-react';
// import { Badge } from '@/components/ui/badge';
import { Badge } from '../../ui/badge';
import { Button } from '../../ui/button';
import useLeadRecruiterDetailStore from '../../../zustandService/useLeadRecruiterDetailStore';
import StateRenderer from './stateRenderer';
import moment from 'moment';
import { lmsBuckets } from '../../../services/constant';

// const leadDetails = {
//   leadID: '2750',
//   bucket:"Bucket name",
//   marked:"3",
//   connected:"12",
//   pitch:"Yes",
//   interested:"Yes",
//   linkshared:"Yes",
//   allocated:"12-07-2024",
//   unallocated:"18-09-2024"
 
// };

let LeadDetails = () => {
  const {

    leadDetailsState,leadDetails={}
  } = useLeadRecruiterDetailStore(state => ({
    
    leadDetails:state.leadDetails,leadDetailsState:state.leadDetailsState
  }));
  const {isInterested,isPitchComplete,isPaymentLinkShared,assignedOn,unassignedOn,lastAttempted,lastConnected,status,isReassigned}=leadDetails;
  return (
    <StateRenderer state={leadDetailsState}>
    <Card className="">
      <CardHeader>
      <div className='flex justify-between  items-center '>
        <div className='flex items-center space-x-3'>

        
          <div className=' size-10 bg-blue-50 flex items-center text-blue-500 rounded-md   justify-center'>
            <Sparkle  />
          </div>
          <CardTitle className="text-xl">Lead Details</CardTitle>
          </div>
        {isReassigned?  <Badge  className={ " bg-green-500 hover:bg-green-500"} >Reassigned</Badge>:null}
          </div>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-1  md:grid-cols-4 gap-x-6 gap-y-6">
          <div>
            <p className="text-sm  text-gray-500">Lead ID</p>
            <p className=" font-medium">{leadDetails.leadId}</p>
          </div>
          <div>
            <p className="text-sm  text-gray-500">Bucket</p>
            <p className=" font-medium">{lmsBuckets[leadDetails.bucketId]?.value}</p>
          </div>
          <div>
            <p className="text-sm  text-gray-500">Attempted</p>
            <p className=" font-medium">{leadDetails.markedCount}</p>
          </div>
          <div>
            <p className="text-sm  text-gray-500">Connected</p>
            <p className=" font-medium">{leadDetails.connectedCount}</p>
          </div>
          <div>
            <p className="text-sm  text-gray-500">Pitch Completed</p>
            <p className=" font-medium">{isPitchComplete==true||isPitchComplete==1?"Yes":"No"}</p>
          </div>
          <div>
            <p className="text-sm  text-gray-500">Interested</p>
            <p className=" font-medium">{isInterested==true||isInterested==1?"Yes":"No"}</p>
          </div>
          <div>
            <p className="text-sm  text-gray-500">Payment Link Shared</p>
            <p className=" font-medium">{isPaymentLinkShared==true||isPaymentLinkShared==1?"Yes":"No"}</p>
          </div>
          <div>
            <p className="text-sm  text-gray-500">Assigned on</p>
            <p className=" font-medium">{moment(assignedOn).format("DD-MM-YYYY")}</p>
          </div>
          <div>
            <p className="text-sm  text-gray-500">Expiry</p>
            <p className=" font-medium">{unassignedOn?moment(unassignedOn).format("DD-MM-YYYY"):"-"}</p>
          </div>
          <div>
            <p className="text-sm  text-gray-500">Last attempted</p>
            <p className=" font-medium">{lastAttempted?moment(lastAttempted).format("DD-MM-YYYY HH:mm"):"-"}</p>
          </div>
          <div>
            <p className="text-sm  text-gray-500">Last connected</p>
            <p className=" font-medium">{lastConnected?moment(lastConnected).format("DD-MM-YYYY HH:mm"):"-"}</p>
          </div>
          
          {/* <div>
            <p className="text-sm  text-gray-500">Moderator</p>
            <Button  variant="link" className="p-0">Assign Moderator</Button>
          </div>
           */}

          
        </div>
      </CardContent>
    </Card>
    </StateRenderer>
  );
};
export default LeadDetails;
