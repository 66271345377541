import { Link } from "react-router-dom";
import {
  customDateFormat,
  isLmsAdmin,
  isLmsModerator
} from "../../../services/utilsV2";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from "../../ui/table";
import { LFMS_LEAD_DETAILS } from "../../../routes/RouteConstants";
const showAssignTo = !isLmsModerator();
const isLMSAdmin = isLmsAdmin();
const SalesList = ({ data }) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Order Id</TableHead>
          <TableHead>Recruiter</TableHead>
          <TableHead>Phone</TableHead>
          <TableHead>Organisation Id</TableHead>
          <TableHead>Company Name</TableHead>
          <TableHead>Credits</TableHead>
          <TableHead>Basic Value</TableHead>
          <TableHead>Purchase Date</TableHead>
          {showAssignTo && <TableHead>Executive</TableHead>}
          {isLMSAdmin && <TableHead>Team Lead</TableHead>}
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.length ? (
          <>
            {data.map(item => (
              <TableRow key={item.orderId}>
                <TableCell>{item.orderId}</TableCell>
                <TableCell>
                  {item.recruiterName} ·{" "}
                  {item.leadId ? (
                    <Link
                      to={`${LFMS_LEAD_DETAILS}?leadId=${item.leadId}&recruiterId=${item.recruiterId}`}
                    >
                      <span className="text-gray-400 ">{item.recruiterId}</span>
                    </Link>
                  ) : (
                    <span className="text-gray-400 ">{item.recruiterId}</span>
                  )}
                </TableCell>
                <TableCell>{item.recruiterPhone || "NA"}</TableCell>
                <TableCell>{item.orgId}</TableCell>
                <TableCell>{item.orgName}</TableCell>
                <TableCell>{item.creditsPurchased}</TableCell>
                <TableCell>{item.value}</TableCell>
                <TableCell>
                  {customDateFormat(item.creditPurchaseDate, "D MMMM")}
                </TableCell>
                {showAssignTo && (
                  <TableCell>{item.moderatorName || "NA"}</TableCell>
                )}
                {isLMSAdmin && (
                  <TableCell>{item.moderatorParentName || "NA"}</TableCell>
                )}
              </TableRow>
            ))}
          </>
        ) : (
          <TableRow>
            <TableCell colspan={10} align="center" className="text-red-500">
              No Data Found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
export default SalesList;
