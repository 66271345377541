import React from "react";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import { findChipValue, getDocumentStatus } from "../../services/utils";
import ViewRemarks from "./ViewRemarks";

const ViewRecruiterLogs = props => {
  const { logHistory = [] } = props;
  logHistory.map((d, index) => {
    const {
      document_status,
      recruiter_verification_status,
      moderator_name = "",
      actioned_by = "",
      createdAt = "",
      remarks = "",
      is_branded_company
    } = d;
    d.srNo = index + 1;
    d.createdDate = moment(createdAt).format("DD/MM/YYYY h:m:s");
    d.moderatorName = moderator_name ? moderator_name : actioned_by;
    d.recStatus =
      recruiter_verification_status === "NEW"
        ? "Under Review"
        : recruiter_verification_status;

    d.docStatus = getDocumentStatus(document_status);
    d.isBrandedCompany = findChipValue(is_branded_company)
      ? findChipValue(is_branded_company)
      : "NA";
    d.moderatorRemarks = <ViewRemarks remarks={remarks} />;
    return d;
  });

  const columns = [
    {
      text: "S.No.",
      dataField: "srNo"
    },
    {
      text: "Date & Time",
      dataField: "createdDate"
    },
    {
      text: "Moderator Name",
      dataField: "moderatorName",
      sort: true
    },
    {
      text: "Rec Calling Status",
      dataField: "recruiter_calling_status"
    },
    {
      text: "Remarks",
      dataField: "moderatorRemarks"
    },
    {
      text: "Rec Status",
      dataField: "recStatus"
    },
    {
      text: "Document Status",
      dataField: "docStatus"
    },
    {
      text: "Document Number",
      dataField: "document_no"
    },
    {
      text: "Employment proof status",
      dataField: "entity_doc_status"
    },
    {
      text: "Bucket",
      dataField: "recruiter_bucket"
    },
    {
      text: "Onboarding Score",
      dataField: "onboarding_score"
    },
    {
      text: "Branded Company",
      dataField: "isBrandedCompany"
    },
    {
      text: "Verification Status",
      dataField: "score_verification_status"
    }
  ];
  const NoDataIndication = () => <div className="spinner">No Data Found</div>;
  return (
    <div className="view-history">
      <BootstrapTable
        keyField="srNo"
        data={logHistory}
        columns={columns}
        hover
        condensed
        bordered={false}
        loading={true}
        rowClasses="listvalue"
        headerClasses="listHeding"
        classes="borderless"
        noDataIndication={() => <NoDataIndication />}
      />
    </div>
  );
};

export default ViewRecruiterLogs;
