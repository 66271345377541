export const crmFeaturesList = [
  {
    id: "jobs",
    label: "Jobs",
    options: [
      {
        id: "Cleaning / Calling",
        label: "Cleaning / Calling",
        access_level: 2
      },
      { id: "QA", label: "QA", access_level: 2 },
      { id: "Live Jobs", label: "Live Jobs", access_level: 2 },
      {
        id: "Fix Duplicate Profile",
        label: "Fix Duplicate Profile",
        access_level: 2
      },
      { id: "Agent Leads", label: "Agent Leads", access_level: 2 },
      { id: "Edited Jobs", label: "Edited Jobs", access_level: 2 },
      { id: "Manual JD Auditing", label: "Manual JD Auditing", access_level: 2 }
    ]
  },
  {
    id: "recruiters",
    label: "Recruiters",
    options: [
      { id: "Recruiters", label: "Recruiters", access_level: 2 },
      { id: "Leads", label: "Leads", access_level: 2 },
      { id: "Recommended Job", label: "Recommended Job", access_level: 2 },
      { id: "Restrict Value", label: "Restrict Value", access_level: 2 }
    ]
  },
  {
    id: "job_seeker",
    label: "Job Seeker",
    options: [{ id: "Job Seeker", label: "Job Seeker", access_level: 2 }]
  },
  {
    id: "analytic",
    label: "Analytic",
    options: [
      { id: "Moderator Report", label: "Moderator Report", access_level: 2 },
      {
        id: "Moderator Assignment",
        label: "Moderator Assignment",
        access_level: 2
      },
      {
        id: "Moderator Attendance",
        label: "Moderator Attendance",
        access_level: 2
      },
      {
        id: "CAT Assignment",
        label: "CAT Assignment",
        access_level: 2
      }
    ]
  },
  {
    id: "complaints",
    label: "Complaints",
    options: [
      {
        id: "Reported Jobs",
        label: "Reported Jobs / Compliance & Protection",
        title: "Reported Jobs / Compliance & Protection",
        access_level: 2
      }
    ]
  },
  {
    id: "enterprise_leads",
    label: "Enterprise Leads",
    options: [
      { id: "Enterprise Leads", label: "Enterprise Leads", access_level: 2 },
      {
        id: "EnterpriseLeadsAdmin",
        label: "Enterprise Leads Admin",
        access_level: 2
      }
    ]
  },
  {
    id: "campaign_management",
    label: "Campaign Management",
    options: [
      {
        id: "CampaignManagement",
        label: "Campaign Management",
        access_level: 2
      },
      {
        id: "CampaignManagementAdmin",
        label: "Campaign Management Admin",
        access_level: 2
      }
    ]
  },
  {
    id: "tools",
    label: "Tools",
    options: [
      { id: "Job URL Shortener", label: "Job URL Shortener", access_level: 2 },
      { id: "LearnTabVideo", label: "Learn Tab Video", access_level: 2 },
      { id: "Workbench", label: "Workbench", access_level: 2 },
      { id: "WorkbenchDownload", label: "Workbench Download", access_level: 2 },
      {
        id: "Dynamic Dag Builder",
        label: "Dynamic Dag Builder",
        access_level: 2
      },
      { id: "LcsBannerGen", label: "LCS Banner Gen", access_level: 2 }
    ]
  },
  {
    id: "widget",
    label: "Special Permissions",
    options: [
      { id: "Purchase Allowed", label: "Purchase Allowed", access_level: 2 },
      { id: "Sales User", label: "Sales User", access_level: 2 },
      { id: "Sales User Admin", label: "Sales User Admin", access_level: 2 },
      { id: "Job_Boost", label: "Job Boost", access_level: 2 },
      {
        id: "Sales User Super Admin",
        label: "Sales User Super Admin",
        access_level: 2
      },
      { id: "Sales User TL", label: "Sales User TL", access_level: 2 }
    ]
  }
];

// Function to get IDs list of options
export function getFeaturesOptionIds(crmFeaturesList) {
  const optionIds = [];
  crmFeaturesList.forEach(feature => {
    feature.options.forEach(option => {
      optionIds.push(option.id);
    });
  });
  return optionIds;
}

export function getLabelFromId(id, array) {
  for (const feature of array) {
    for (const option of feature.options) {
      if (option.id === id) {
        return option.label;
      }
    }
  }
  return null; // Return null if ID is not found
}
