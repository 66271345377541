import { getFormattedValue } from "../../services/utils";
import withRouter from "../../services/withRouter";

function TransactionHistorySearch(props) {
  const { onSearch, formData, setFormData } = props;

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    let state = formData || {};
    state = { ...formData, [name]: getFormattedValue(value, true, true) };
    setFormData(state);
  };

  const search = e => {
    e.preventDefault();
    onSearch(formData);
  };

  return (
    <form className="d-flex flex-row search mt-2 align-items-center">
      <div className="col-md-2">
        <div className="form-group">
          <input
            className="form-control"
            type="text"
            name="recruiterId"
            placeholder="Recruiter Id "
            value={formData.recruiterId}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-md-1 text-center">OR</div>
      <div className="col-md-2">
        <div className="form-group">
          <input
            className="form-control"
            type="text"
            name="recruiterPhone"
            placeholder="Recruiter Phone "
            value={formData.recruiterPhone}
            onChange={handleChange}
            maxLength={10}
          />
        </div>
      </div>
      <button className="btn btn-primary" onClick={search}>
        SEARCH
      </button>
    </form>
  );
}

export default withRouter(TransactionHistorySearch);
