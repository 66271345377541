import React from "react";
import { useSearchParams } from "react-router-dom";
import "../styleNew.css";
import Header from "./Componets/Header";
import JobEditPage from "../components/JobsNew/";
import withRouter from "../services/withRouter";

const Layout = props => {
  const { params = {} } = props;
  const [searchParams] = useSearchParams();
  const recId = searchParams.get("recId");
  const editParams = { ...params, recId };
  return (
    <div className="job-edit-container">
      <div className="main-container">
        <Header />
        <JobEditPage editParams={editParams} />
      </div>
    </div>
  );
};

export default withRouter(Layout);
