import React, { useRef } from "react";
import { TextField, FormControl } from "@material-ui/core";
import { useController } from "react-hook-form";
import classes from "./materialTextField.module.css";
import LcsFormErrorHook from "../../customHooks/LcsFormErrorHook";

const MaterialInputTextField = ({
  name,
  label,
  control,
  rules,
  error,
  helperText,
  fullWidth = true,
  formControlClasses,
  index,
  defaultValue,
  validateArray,
  ...rest
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules: {
      ...rules,
      validate: validateArray
    },
    defaultValue
  });
  const inputRef = useRef(null);

  LcsFormErrorHook({
    fieldState,
    inputRef
  });
  return (
    <>
      <FormControl
        fullWidth={fullWidth}
        error={!!fieldState.error || !!error}
        classes={formControlClasses}
        innerRef={inputRef}
      >
        <TextField
          {...field}
          id={name}
          label={label}
          variant="outlined"
          InputLabelProps={{
            classes: {
              outlined: classes.label
            }
          }}
          {...rest}
          error={!!fieldState.error || !!error}
          helperText={fieldState.error ? fieldState.error.message : helperText}
        />
      </FormControl>
    </>
  );
};

export default MaterialInputTextField;
