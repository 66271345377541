import MaterialInputTextField from "../materialInputTextField";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import cx from "classnames";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext
} from "react-hook-form";
import classes from "./lcsCampaignCreation.module.css";
import MaterialDropdown from "../materialDropDown";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  CircularProgress,
  Radio
} from "@material-ui/core";
import MaterialSwitch from "../materialSwitch";
import {
  getCampaignQuestions,
  saveCampaignQuestion
} from "../../services/lcsCampaign";
import { isEmpty, isObjectEmpty } from "../../services/utils";
import useLcsFormSubmitState from "../../zustandService/useLcsFormSubmitState";
import ComponentWrappedBackdrop from "../ComponentWrappedBackdrop";
import { useParams } from "react-router-dom";
import { EDIT_CAMPAIGN } from "../../routes/RouteConstants";
import useLcsCampaignEditPermission from "../../zustandService/useLcsCampaignEditPermission";

const getLangLabel = (lang, allLanguages) => {
  return allLanguages.find(item => item.id === lang);
};

const maxOptionsAllowed = 6;
const minOptionsReq = 2;
const maxQuestionsAllowed = 15;
const minQuestionReq = 1;

const LcsCampaignQueComponent = props => {
  const {
    questionIndex,
    lang,
    removeQuestion,
    nextQuestionIdOptions,
    update,
    isDefaultLang,
    priorityFieldArray,
    isConditional,
    showActiveSwitch,
    defaultOptionObj,
    numberOfActiveQuestions
  } = props;
  const { control, watch } = useFormContext();
  const questionWatcher = watch(`questions.${questionIndex}`);
  const questionTypeOptions = [
    { value: "single_chip", label: "Single select" },
    { value: "multiple_chip", label: "Multi select" }
  ];
  const questionTypeDefaultValue =
    questionWatcher.question_type ?? "single_chip";

  const nextQueIdFilteredOptions = nextQuestionIdOptions.filter(item => {
    if (item.value < 0) return item;
    if (item.value > questionWatcher.question_id) return item;
  });
  let numberOfActiveOptions = 0;
  questionWatcher.options.forEach(option => {
    numberOfActiveOptions += Number(!!option.is_active);
  });

  const optionRemoveClick = optionItem => {
    const optionId = optionItem.option_id;
    const obj = JSON.parse(JSON.stringify(questionWatcher));
    const options = [...obj.options];
    const optionsCopy = options.filter(opt => opt.option_id !== optionId);
    obj.options = optionsCopy.map((item, index) => {
      return {
        ...item,
        option_id: index // revalidate optionId
      };
    });
    update(questionIndex, obj);
  };

  const addOptionClick = () => {
    const optionId =
      questionWatcher.options[questionWatcher.options.length - 1]?.option_id +
      1;
    const obj = JSON.parse(JSON.stringify(questionWatcher));
    const options = [...obj.options];
    options.push({
      ...defaultOptionObj,
      option_id: optionId,
      option_label: {
        [lang]: `Option ${questionWatcher.options.length + 1}`
      }
    });
    obj.options = options;
    update(questionIndex, obj);
  };

  const isOptionActiveSwitchDisabled = item =>
    (!item.is_active && numberOfActiveOptions === maxOptionsAllowed) ||
    (item.is_active && numberOfActiveOptions === minOptionsReq);

  const isQueActiveSwitchDisabled = () =>
    (!questionWatcher.is_active &&
      numberOfActiveQuestions === maxQuestionsAllowed) ||
    (questionWatcher.is_active && numberOfActiveQuestions === minQuestionReq);

  return (
    <>
      <div className={classes.questioncnt}>
        <div className={classes.questionTitleCnt}>
          <MaterialInputTextField
            name={`questions.${questionIndex}.question_label.${lang}`}
            label="Question"
            control={control}
            rules={{
              required: "Question title is required",
              maxLength: {
                message: "Max 120 character allowed",
                value: 120
              }
            }}
            formControlClasses={{ root: classes.formControlClassQue }}
            fullWidth={false}
          />
          <MaterialDropdown
            name={`questions.${questionIndex}.question_type`}
            label="Select Question Type"
            control={control}
            rules={{ required: "Please select an option" }} // Validation rules
            options={questionTypeOptions}
            fullWidth={false}
            formControlClasses={{ root: classes.formControlClassQueType }}
            defaultValue={questionTypeDefaultValue}
            disabled={!isDefaultLang}
          />
        </div>
        <div className={classes.helpTextCnt}>
          <MaterialInputTextField
            name={`questions.${questionIndex}.help_text.${lang}`}
            label="Helptext"
            control={control}
            formControlClasses={{ root: classes.formControlClassQue }}
            fullWidth={false}
          />
        </div>
        <div>
          {questionWatcher?.options.map((item, index) => {
            return (
              <div key={index} className={classes.optionField}>
                {questionWatcher.question_type === "single_chip" ? (
                  <Radio checked={false} />
                ) : (
                  <Checkbox checked={false} />
                )}
                <MaterialInputTextField
                  name={`questions.${questionIndex}.options.${index}.option_label.${lang}`}
                  label="Option"
                  control={control}
                  rules={{
                    required: "Option is required",
                    maxLength: {
                      message: "Max 16 character allowed",
                      value: 16
                    }
                  }}
                  formControlClasses={{ root: classes.formControlClassOptions }}
                  fullWidth={false}
                  defaultValue={item.option_label?.[lang]}
                />
                {isConditional ? (
                  <MaterialDropdown
                    name={`questions.${questionIndex}.options.${index}.next_question_id`}
                    label="Select Next Question"
                    control={control}
                    rules={{ required: "Please select an option" }}
                    options={nextQueIdFilteredOptions}
                    fullWidth={false}
                    formControlClasses={{ root: classes.formNextQuestionId }}
                    disabled={!isDefaultLang}
                    defaultValue={item.next_question_id}
                  />
                ) : null}
                {isDefaultLang ? (
                  <MaterialSwitch
                    name={`questions.${questionIndex}.options.${index}.is_lead_success`}
                    label="Lead Success"
                    control={control}
                    defaultChecked={item.is_lead_success ?? false}
                    formControlClasses={{ root: classes.formSwitch }}
                    size={"small"}
                  />
                ) : null}
                {isDefaultLang && showActiveSwitch ? (
                  <MaterialSwitch
                    name={`questions.${questionIndex}.options.${index}.is_active`}
                    label="Active"
                    control={control}
                    defaultChecked={item.is_active ?? true}
                    formControlClasses={{ root: classes.formSwitch }}
                    size={"small"}
                    disabled={isOptionActiveSwitchDisabled(item)}
                  />
                ) : null}
                {isDefaultLang &&
                numberOfActiveOptions > minOptionsReq &&
                !showActiveSwitch ? (
                  <i
                    onClick={() => optionRemoveClick(item)}
                    className="fa-solid fa-x"
                  />
                ) : null}
              </div>
            );
          })}
          {isDefaultLang && numberOfActiveOptions < maxOptionsAllowed ? (
            <div onClick={addOptionClick} className={classes.addOption}>
              Add option
            </div>
          ) : null}
        </div>
        {isDefaultLang ? (
          <div className={classes.footerCnt}>
            {!showActiveSwitch && numberOfActiveQuestions > minQuestionReq ? (
              <i
                onClick={() => removeQuestion(questionIndex)}
                className="fa-solid fa-trash"
              />
            ) : null}

            {showActiveSwitch ? (
              <MaterialSwitch
                name={`questions.${questionIndex}.is_active`}
                label="Active"
                control={control}
                defaultChecked={questionWatcher.is_active ?? true}
                formControlClasses={{ root: classes.formSwitch }}
                size={"small"}
                disabled={isQueActiveSwitchDisabled()}
              />
            ) : null}

            <MaterialDropdown
              name={`questions.${questionIndex}.priority_order`}
              label="Priority"
              control={control}
              rules={{ required: "Select Priority" }}
              options={priorityFieldArray}
              fullWidth={false}
              formControlClasses={{ root: classes.formPriorityField }}
              disabled={!isDefaultLang}
              defaultValue={questionWatcher.priority_order ?? questionIndex}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

const LcsCampaignLangForm = props => {
  const {
    defaultLang,
    lang,
    formQuestionsData,
    addDuplicateLangForm,
    duplicateLangList,
    invalidateFormData,
    allLanguages,
    isConditional,
    showActiveSwitch,
    disableSaveQuestionCta,
    removeDuplicateLangForm,
    expandedAccordionLang
  } = props;

  const { updateFormState } = useLcsFormSubmitState(state => ({
    updateFormState: state.updateFormState
  }));

  const { canEditCampaign } = useLcsCampaignEditPermission(state => ({
    canEditCampaign: state.canEditCampaign
  }));

  const defaultOptionObj = {
    option_id: 0,
    option_label: { [lang]: "Option 1" },
    is_lead_success: false,
    is_active: true,
    next_question_id: null
  };
  const defaultOptions = [
    {
      ...defaultOptionObj
    },
    {
      ...defaultOptionObj,
      option_id: 1,
      option_label: { [lang]: "Option 2" }
    }
  ];
  const defaultQuestionObj = {
    question_id: 0,
    priority_order: 0,
    options: defaultOptions,
    question_type: "single_chip",
    question_label: { [defaultLang]: "" },
    help_text: { [defaultLang]: "" },
    is_active: true
  };
  const defaultQuestionsFormData = formQuestionsData?.questions?.length
    ? formQuestionsData.questions
    : [
        {
          ...defaultQuestionObj
        }
      ];

  const postSubmitTextFailure =
    formQuestionsData.post_submit_text_failure ?? {};
  const postSubmitTextSuccess =
    formQuestionsData.post_submit_text_success ?? {};
  const postSubmitTitleFailure =
    formQuestionsData.post_submit_title_failure ?? {};
  const postSubmitTitleSucces =
    formQuestionsData.post_submit_title_success ?? {};

  const methods = useForm({
    defaultValues: {
      questions: defaultQuestionsFormData,
      post_submit_text_failure: isObjectEmpty(postSubmitTextFailure)
        ? { [defaultLang]: "" }
        : postSubmitTextFailure,
      post_submit_text_success: isObjectEmpty(postSubmitTextSuccess)
        ? { [defaultLang]: "" }
        : postSubmitTextSuccess,
      post_submit_title_failure: isObjectEmpty(postSubmitTitleFailure)
        ? { [defaultLang]: "" }
        : postSubmitTitleFailure,
      post_submit_title_success: isObjectEmpty(postSubmitTitleSucces)
        ? { [defaultLang]: "" }
        : postSubmitTitleSucces
    }
  });
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { isDirty, dirtyFields }
  } = methods;
  const anyFieldChanged = !isEmpty(Object.keys(dirtyFields));
  useEffect(() => {
    if (isDirty && anyFieldChanged) {
      disableSaveQuestionCta(true);
    }
  }, [anyFieldChanged]); // only runs when one field gets dirty and doesn't run when we change other fields after it
  const {
    fields: questionFields,
    append,
    update,
    remove
  } = useFieldArray({
    name: "questions",
    control,
    rules: { maxLength: maxQuestionsAllowed, minLength: minQuestionReq }
  });
  const postTextArr = [
    {
      lead: "SUCCESS",
      id: "post_submit_title_success",
      label: "Post submit title success",
      maxLength: {
        message: "Max 24 character allowed",
        value: 24
      }
    },
    {
      lead: "SUCCESS",
      id: "post_submit_text_success",
      label: "Post submit description success",
      maxLength: {
        message: "Max 120 character allowed",
        value: 120
      }
    },
    {
      lead: "FAILURE",
      id: "post_submit_title_failure",
      label: "Post submit title failure",
      maxLength: {
        message: "Max 24 character allowed",
        value: 24
      }
    },
    {
      lead: "FAILURE",
      id: "post_submit_text_failure",
      label: "Post submit description failure",
      maxLength: {
        message: "Max 120 character allowed",
        value: 120
      }
    }
  ];

  const revalidateQuestionIds = () => {
    let updatedForm = getValues("questions");
    updatedForm = updatedForm.map((item, index) => {
      return {
        ...item,
        question_id: index,
        priority_order:
          item.priority_order >= updatedForm.length
            ? null
            : item.priority_order,
        options: item.options.map((option, index) => {
          return {
            ...option,
            next_question_id: null
          };
        })
      };
    });
    setValue("questions", updatedForm);
  };

  const removeQuestion = index => {
    remove(index);
    revalidateQuestionIds();
  };

  const onSubmit = async data => {
    invalidateFormData(data, lang);
  };
  let nextQuestionIdOptions = isConditional
    ? [
        {
          value: -1,
          label: "Submit Form"
        }
      ]
    : [];
  let priorityFieldArray = [];
  questionFields.forEach((question, index) => {
    // as long as we are using length property of field array we will get the updated data
    if (isConditional)
      nextQuestionIdOptions.push({
        value: question.question_id,
        label: `Question ${index + 1}`
      });
    priorityFieldArray.push({ value: index, label: `${index + 1}` });
  });
  const onDuplicateForm = ({ duplicatedLang, defaultLang = "en" }) => {
    const formData = JSON.parse(JSON.stringify(getValues())); // added for deep copy of getvalues
    formData.questions = formData.questions.map(item => {
      item.question_label = {
        ...item.question_label,
        [duplicatedLang]: item.question_label[defaultLang]
      };
      item.help_text = {
        ...item.help_text,
        [duplicatedLang]: item.help_text[defaultLang]
      };
      item.options = item.options.map(option => {
        return {
          ...option,
          option_label: {
            ...option.option_label,
            [duplicatedLang]: option.option_label?.[defaultLang]
          }
        };
      });
      return item;
    });
    postTextArr.map((item, index) => {
      formData[item.id] = {
        ...formData[item.id],
        [duplicatedLang]: formData[item.id][defaultLang]
      };
    });
    return formData;
  };

  const onDuplicateFormDeletion = duplicatedLang => {
    const formData = JSON.parse(JSON.stringify(getValues())); // added for deep copy of getvalues
    formData.questions = formData.questions.map(item => {
      delete item.question_label[duplicatedLang];
      delete item.help_text[duplicatedLang];
      item.options = item.options.map(option => {
        delete option.option_label[duplicatedLang];
        return option;
      });
      return item;
    });
    postTextArr.map((item, index) => {
      delete formData[item.id][duplicatedLang];
    });
    return formData;
  };

  const isDefaultLang = defaultLang === lang;
  const langObj = getLangLabel(lang, allLanguages) ?? {};
  let numberOfActiveQuestions = 0;
  const questions = getValues("questions"); // here we could not use questionFields as we will get the stale value is_active from it hence we used getValues
  questions.forEach(question => {
    numberOfActiveQuestions += Number(!!question.is_active);
  });

  const validatePostText = (value, item, leadsObj) => {
    if (!value && leadsObj[item.lead] > 0) return `${item.label} is required`;
    return true;
  };

  const evaluateNumberOfLeadSuccessOptions = questionsArray => {
    const leadsObj = {
      SUCCESS: 0,
      FAILURE: 0
    };
    questionsArray.forEach(question => {
      question.options.forEach(option => {
        const key = option.is_lead_success ? "SUCCESS" : "FAILURE";
        leadsObj[key] += 1;
      });
    });
    return leadsObj;
  };

  const leadsObj = evaluateNumberOfLeadSuccessOptions(questions);
  // whereever we want to use any field value from questionFields we should use getValues as it gived latest data
  // although if we need to use length propery then directy using questionFields will work fine
  // we are accessign quesition_id from questionFields as it is a derived value of questionField's length.

  const onLangDeleteClick = () => {
    const newFormData = onDuplicateFormDeletion(langObj.id);
    removeDuplicateLangForm(newFormData, langObj.id);
  };

  const onDuplicateLangClick = langObj => {
    const newFormData = onDuplicateForm({
      duplicatedLang: langObj.id,
      defaultLang
    });
    addDuplicateLangForm(newFormData, langObj.id);
  };

  const onAddQuestionClick = e => {
    if (e) e.preventDefault();
    const questionId =
      questionFields[questionFields.length - 1]?.question_id + 1;
    append({
      ...defaultQuestionObj,
      question_id: questionId,
      priority_order: questionFields.length
    });
  };

  const onError = error => {
    updateFormState("ERROR");
  };

  return (
    <>
      {defaultLang === lang ? (
        <div className={classes.duplicateDropdown}>
          <div>
            <label htmlFor={"language_to_duplicate"}>
              Select language to duplicate the Questionnaire
            </label>
            <Select
              name={"language_to_duplicate"}
              onChange={langObj => {
                onDuplicateLangClick(langObj);
              }}
              options={duplicateLangList}
              getOptionLabel={option => option["label"]}
              getOptionValue={option => option["id"]}
              isMulti={false}
              isSearchable={false}
              placeholder="Duplicate for language"
              isDisabled={!canEditCampaign}
            />
          </div>
        </div>
      ) : null}
      <Accordion
        defaultExpanded={lang === expandedAccordionLang}
        classes={{ root: classes.accordionClass }}
      >
        <AccordionSummary
          expandIcon={
            <img src="/images/arrow_down.svg" height={14} width={14} />
          }
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className={classes.languageHeading}>
            {langObj.label} language
            {isDirty && anyFieldChanged ? (
              <i className="fa-regular fa-circle-check"></i>
            ) : (
              <i
                className="fa-solid fa-circle-check"
                style={{ color: "#63E6BE" }}
              ></i>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.languageFormCnt}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <div className={classes.formCnt}>
                  <ComponentWrappedBackdrop
                    isVisible={!canEditCampaign}
                    onClick={null}
                  >
                    <div className="text-center">
                      <i className="fas fa-lock fa-3x" />
                      <p className="mt-2">Cannot Edit</p>
                    </div>
                  </ComponentWrappedBackdrop>
                  <div className={classes.fieldCnt}>
                    {questionFields.map((item, index) => (
                      <React.Fragment key={item.id}>
                        <h4>Question{index + 1}</h4>
                        <LcsCampaignQueComponent
                          lang={lang}
                          key={index}
                          questionIndex={index}
                          removeQuestion={removeQuestion}
                          nextQuestionIdOptions={nextQuestionIdOptions}
                          update={update}
                          isDefaultLang={isDefaultLang}
                          priorityFieldArray={priorityFieldArray}
                          isConditional={isConditional}
                          showActiveSwitch={showActiveSwitch}
                          defaultOptionObj={defaultOptionObj}
                          numberOfActiveQuestions={numberOfActiveQuestions}
                        />
                      </React.Fragment>
                    ))}
                    <div className={classes.postTextCnt}>
                      {postTextArr.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            display:
                              leadsObj[item.lead] === 0 ? "none" : "block"
                          }}
                        >
                          <MaterialInputTextField
                            fullWidth={true}
                            name={`${item.id}.${lang}`}
                            label={`${item.label}`}
                            control={control}
                            rules={{
                              maxLength: {
                                ...item.maxLength
                              }
                            }}
                            validateArray={value =>
                              validatePostText(value, item, leadsObj)
                            }
                            defaultValue={getValues(item.id)?.[lang]}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={classes.actionCnt}>
                    <button
                      className="submitBtn"
                      title="Delete"
                      onClick={onLangDeleteClick}
                      disabled={isDefaultLang}
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </button>
                    <button
                      className="submitBtn"
                      title="Add Question"
                      onClick={onAddQuestionClick}
                      disabled={
                        !isDefaultLang ||
                        numberOfActiveQuestions === maxQuestionsAllowed
                      }
                    >
                      <i className="fa-solid fa-circle-plus" />
                    </button>
                    <button
                      title="Save details"
                      className={cx("btn btn-primary", classes.saveDtlsButton)}
                      type="submit"
                      disabled={!(isDirty && anyFieldChanged)}
                    >
                      <i className="fa-regular fa-circle-check"></i>
                    </button>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const LcsCampaignQuestions = props => {
  const {
    allLanguages = [],
    campaignData = {},
    removeBeforeUnloadHandler
  } = props;
  const queryParams = new URLSearchParams(window.location.search);
  const duplicateCampaignId = queryParams.get("duplicateCampaignId");
  const { id: newCampaignId } = useParams();
  const isDuplicateCampaignQuestions = !!duplicateCampaignId;
  const {
    default_language: defaultLang = "en",
    allowed_languages: allowedLanguages = [],
    is_conditional: isConditional = false
  } = campaignData;
  const [formQuestionsDataState, setFormQuestionsData] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [campaignLangList, addCampaignLangList] = useState([defaultLang]);
  const [saveQuestionCtaDisabled, setSaveQuestionCtaDisabled] = useState(
    !isDuplicateCampaignQuestions
  );
  const [showActiveSwitch, setShowActiveSwitch] = useState(false);
  const [saveQuestionApiError, setSaveQuestionApiError] = useState(null);

  const [expandedAccordionLang, setExpandedAccordionLang] =
    useState(defaultLang);
  const getQuestionsData = () => {
    getCampaignQuestions(
      isDuplicateCampaignQuestions ? duplicateCampaignId : newCampaignId
    )
      .then(data => {
        setFormQuestionsData({ ...data, campaign_id: newCampaignId });
        setShowLoader(false);
        const campaignLanguages = Object.keys(
          data.questions?.[0]?.question_label ?? {}
        );
        campaignLanguages.sort((a, b) => {
          if (a === defaultLang) return -1;
          if (b === defaultLang) return 1;
          return 0;
        });

        addCampaignLangList(
          campaignLanguages.length ? campaignLanguages : [defaultLang]
        );
        if (!isDuplicateCampaignQuestions)
          setShowActiveSwitch(data?.questions?.length ?? false);
      })
      .catch(error => {
        setShowLoader(false);
      });
  };
  useEffect(() => {
    getQuestionsData();
  }, []);

  const invalidateFormData = (formData, langSubmitted) => {
    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
      setFormQuestionsData({
        ...formQuestionsDataState,
        ...formData
      });
      disableSaveQuestionCta(false);
      if (langSubmitted) setExpandedAccordionLang(langSubmitted);
    }, 500);
  };

  const removeDuplicateLangForm = (formData, duplicatedLang) => {
    addCampaignLangList(
      campaignLangList.filter(lang => lang !== duplicatedLang)
    );
    invalidateFormData(formData);
  };

  const addDuplicateLangForm = (formData, duplicatedLang) => {
    addCampaignLangList([...campaignLangList, duplicatedLang]);
    invalidateFormData(formData, duplicatedLang);
  };
  const allowedLangList = allLanguages.filter(
    langObj => allowedLanguages.indexOf(langObj.id) !== -1
  );
  const duplicateLangList = allowedLangList.filter(
    langObj => campaignLangList.indexOf(langObj.id) === -1
  );
  const disableSaveQuestionCta = value => {
    setSaveQuestionCtaDisabled(value);
  };
  const onSaveClick = e => {
    if (e) e.preventDefault();
    setShowLoader(true);
    saveCampaignQuestion({
      ...formQuestionsDataState
    })
      .then(() => {
        if (isDuplicateCampaignQuestions) {
          removeBeforeUnloadHandler();
          window.location.replace(`${EDIT_CAMPAIGN}/${newCampaignId}`);
          return;
        }
        getQuestionsData();
        setSaveQuestionCtaDisabled(true);
      })
      .catch(error => {
        setShowLoader(false);
        setSaveQuestionApiError(error);
        setTimeout(() => {
          setSaveQuestionApiError(null);
        }, 3000);
      });
  };

  return (
    <>
      {showLoader ? (
        <div className={classes.loaderCnt}>
          <CircularProgress
            classes={{ root: classes.loaderStyles }}
            color="inherit"
          />
        </div>
      ) : (
        <>
          {campaignLangList.map((lang, index) => (
            <LcsCampaignLangForm
              key={index}
              lang={lang}
              defaultLang={defaultLang}
              formQuestionsData={formQuestionsDataState}
              allLanguages={allLanguages}
              isConditional={isConditional}
              addDuplicateLangForm={addDuplicateLangForm}
              duplicateLangList={duplicateLangList}
              invalidateFormData={invalidateFormData}
              showActiveSwitch={showActiveSwitch}
              disableSaveQuestionCta={disableSaveQuestionCta}
              removeDuplicateLangForm={removeDuplicateLangForm}
              expandedAccordionLang={expandedAccordionLang}
            />
          ))}
          <div className={classes.saveQueCnt}>
            {saveQuestionApiError ? (
              <span className="text-danger">{saveQuestionApiError}</span>
            ) : null}
            <button
              className="submitBtn"
              disabled={saveQuestionCtaDisabled}
              onClick={onSaveClick}
            >
              Submit Questionnaire
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default LcsCampaignQuestions;
