import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress
} from "@material-ui/core";
import classes from "./lcsCampaignDetails.module.css";
import MaterialInputTextField from "../../materialInputTextField";
import MaterialDropdown from "../../materialDropDown";
import Heading from "../../Common/Form/Heading";
import UploadImage from "../../UploadImage";
import DashboardBanner from "./DashboardBanner";
import MaterialSwitch from "../../materialSwitch";
import { saveCampaignDetails } from "../../../services/lcsCampaign";
import PostApplyBanner from "./PostApplyBanner";
import { EDIT_CAMPAIGN } from "../../../routes/RouteConstants";
import CampaignStatus from "./CampaignStatus";
import { findValueInArrayObject, isObjectEmpty } from "../../../services/utils";
import SelectDropdown from "../../ReactSelect";
import CampaignFilters from "./CampaignFilters";
import SelectCreateNew from "../../Common/Form/SelectCreateNew";
import useLcsFormSubmitState from "../../../zustandService/useLcsFormSubmitState";
import QualificationFormV2 from "./QualificationFormV2";
import ComponentWrappedBackdrop from "../../ComponentWrappedBackdrop";
import { useNavigate } from "react-router-dom";
import useLcsCampaignEditPermission from "../../../zustandService/useLcsCampaignEditPermission";

const LcsCampaignDetails = props => {
  const [message, setMessage] = useState({ error: "", success: "" });
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    metaDataList,
    data,
    campaignId,
    callBack,
    removeBeforeUnloadHandler
  } = props;
  const { canEditCampaign } = useLcsCampaignEditPermission(state => ({
    canEditCampaign: state.canEditCampaign
  }));
  const {
    title = "",
    priority = "",
    default_language = "en",
    campaign_group = "LCS",
    description = "",
    allowed_categories = [],
    city_ids = [],
    allowed_skills = [],
    allowed_assets = [],
    allowed_prior_experiences = [],
    allowed_languages = ["en"],
    start_date = "",
    end_date = null,
    excluded_campaigns = [],
    campaign_post_apply_banner = [],
    campaign_type_banner = [],
    banner_impression_limit = "",
    form_impression_limit = "",
    client_redirection_url_visible = false,
    client_redirection_url = "",
    skip_questionniare_redirect = false,
    allowed_source = ["APP"],
    logo_url = "",
    is_conditional = false,
    is_active_for_job_mapping = false,
    is_active_in_screening_flow = false,
    is_active = false,
    jobIds = "",
    is_whatsapp_campaign = false,
    campaign_status = "",
    client_id = "",
    terms_and_condition_visible = false,
    terms_and_condition_text = "",
    logo_name = "",
    header = {},
    banner_title = {},
    content = {}
  } = data;
  const [status, setStatus] = useState(campaign_status || "INACTIVE");
  const queryParams = new URLSearchParams(window.location.search);
  const duplicateCampaignId = queryParams.get("duplicateCampaignId");

  const validateQualificationFormData = ({ content, header }) => {
    const languages = Object.keys(header);
    let prevLangHeaderPresent = null;
    let prevLangContentCount = null;
    for (let lang of languages) {
      if (prevLangHeaderPresent !== null) {
        if (prevLangHeaderPresent !== !!header[lang]) {
          return false;
        }
      }
      if (prevLangContentCount !== null) {
        if (prevLangContentCount !== content[lang].split("#")?.length) {
          return false;
        }
      }
      prevLangContentCount = content[lang].split("#")?.length;
      prevLangHeaderPresent = !!header[lang];
    }
    return true;
  };

  const formatQualificationFormData = data => {
    let header = {};
    let bannerTitle = {};
    let content = {};
    data.forEach(element => {
      header[element.language] = element.header;
      bannerTitle[element.language] = element.bannerTitle;
      content[element.language] = element.content
        .filter(item => item !== "")
        .join("#");
    });
    return { header, bannerTitle, content };
  };

  const onSubmit = async data => {
    setMessage({ error: "", success: "" });
    try {
      // Handle form submission
      const errorMessages = {
        noActiveCampaign: "Please activate at least one campaign type",
        nodashboardBanner: "Please add dashboard banner",
        noPostApplyBanner: "Please add post apply banner",
        jobIds: "Please enter comma-separated integer values only",
        dashboardBannerIssue:
          "Please select default language for dashboard banner",
        postApplyBannerIssue:
          "Please select default language for qualification form banner",
        qualificationFormClientDetailsInconsistence:
          "Please fill correct Client Details in the Qualification Form"
      };
      const clientDetails = data.client_id
        ? data.client_id
        : { value: client_id, label: "" };
      const allowedSource =
        data?.allowed_source && data?.allowed_source.length
          ? data?.allowed_source.map(option => option.value)
          : [];
      const isActive = data?.is_active;
      const isActivePostApply = data?.is_active_in_screening_flow;
      const isActiveJobMapping = data?.is_active_for_job_mapping;
      const dashboardBannerData = data?.dashboardBanner;
      const postApplyBannerData = data?.postApplyBanner;
      const { content, bannerTitle, header } = formatQualificationFormData(
        data?.qualificationForm
      );

      if (!validateQualificationFormData({ content, header })) {
        setMessage({
          error: errorMessages.qualificationFormClientDetailsInconsistence
        });
        return;
      }
      const jobIds = data?.jobIds;
      const defaultLanguage = data?.default_language
        ? data?.default_language["value"]
        : data?.default_language;
      const allowedLanguages = data.allowed_languages.map(item => item.value);
      const isValidIds = jobIds
        ? jobIds.split(",").every(id => /^\d+$/.test(id.trim()))
        : true;
      if (!isActive && !isActivePostApply && !isActiveJobMapping) {
        setMessage({ error: errorMessages.noActiveCampaign });
        return;
      }
      if (isActive && !dashboardBannerData.length) {
        setMessage({ error: errorMessages.nodashboardBanner });
        return;
      }
      if (
        isActive &&
        dashboardBannerData.length &&
        !findValueInArrayObject(dashboardBannerData, defaultLanguage)
      ) {
        setMessage({ error: errorMessages.dashboardBannerIssue });
        return;
      }
      if (!postApplyBannerData.length) {
        setMessage({ error: errorMessages.noPostApplyBanner });
        return;
      }
      if (
        postApplyBannerData.length &&
        !findValueInArrayObject(postApplyBannerData, defaultLanguage)
      ) {
        setMessage({ error: errorMessages.postApplyBannerIssue });
        return;
      }
      if (!isValidIds) {
        setMessage({ error: errorMessages.jobIds });
        return;
      }

      let postApplyBannerURL = {};
      const postApplyBannerHeading = {};
      let srpBannerUrl = {};
      let srpBannerHeading = {};
      const srpCarouselUrl = {};
      // banner data if available
      if (isActive && dashboardBannerData?.length > 0) {
        for (const item of dashboardBannerData) {
          srpBannerUrl[item.language] = item?.file
            ? item.file
            : item.image_name;
          srpBannerHeading[item.language] = item.heading;
          srpCarouselUrl[item.language] = item?.carousel_file
            ? item.carousel_file
            : item.carousel_image_name;
        }
      }
      // Save post apply banner data if available
      if (postApplyBannerData?.length > 0) {
        for (const item of postApplyBannerData) {
          postApplyBannerURL[item.language] = item?.file
            ? item.file
            : item.image_name;
          postApplyBannerHeading[item.language] = item.heading;
        }
      }
      setLoading(true);
      const logoUrl = data?.logoUrl;
      const stringToRemove = "All";
      const cityIds = data.city_ids.map(item => item.value);
      const categories = data.allowed_categories.map(item => item.value);
      const skills = data.allowed_skills.map(item => item.value);
      const assets = data.allowed_assets.map(item => item.value);
      const priorExperiences = data.allowed_prior_experiences.map(
        item => item.value
      );
      const excludedCampaigns = data.excluded_campaigns.map(item => item.value);
      let formData = {
        ...data,
        end_date: data.end_date ? data.end_date : null,
        content,
        banner_title: bannerTitle,
        header,
        allowed_languages: allowedLanguages,
        default_language: defaultLanguage,
        excluded_campaigns: excludedCampaigns,
        city_ids: cityIds.filter(item => item !== stringToRemove),
        allowed_categories: categories.filter(item => item !== stringToRemove),
        allowed_skills: skills.filter(item => item !== stringToRemove),
        allowed_assets: assets.filter(item => item !== stringToRemove),
        allowed_prior_experiences: priorExperiences.filter(
          item => item !== stringToRemove
        ),
        job_ids: jobIds ? jobIds.split(",") : [],
        client_redirection_url_visible: data.client_redirection_url_visible,
        banner_vernac_url: postApplyBannerURL, // post apply banner
        vernac_post_apply_title: postApplyBannerHeading, // Post Apply heading
        srp_banner_url_languages: srpBannerUrl, //SRP and Banner images
        vernac_banner_heading: srpBannerHeading, // SRP Heading
        multibanner_urls: srpCarouselUrl, // srp carousel banner
        logo_url: logoUrl ? logoUrl : logo_name,
        id: campaignId ? campaignId : undefined,
        allowed_source: allowedSource,
        client_name: clientDetails?.__isNew__
          ? clientDetails?.value
          : clientDetails?.label,
        client_id: !clientDetails?.__isNew__ ? clientDetails?.value : undefined
      };
      delete formData.dashboardBanner;
      delete formData.postApplyBanner;
      delete formData.logoUrl;
      delete formData.jobIds;
      delete formData.qualificationForm;

      // Save campaign details
      const response = await saveCampaignDetails(formData);

      const id = response?.id ? response?.id : campaignId;
      // If no errors, set success message
      setMessage({ error: "", success: "Data Saved" });
      if (campaignId) {
        setLoading(false);
        if (callBack) callBack();
      } else if (id) {
        removeBeforeUnloadHandler();
        // In case of create campaign after save redirect to edit page
        setTimeout(() => {
          const path =
            !!duplicateCampaignId && !formData.skip_questionniare_redirect
              ? `${EDIT_CAMPAIGN}/${id}?duplicateCampaignId=${duplicateCampaignId}`
              : `${EDIT_CAMPAIGN}/${id}`;
          navigate(path, { replace: !!duplicateCampaignId });
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      // If any error occurs, set error message
      setMessage({ error });
      setLoading(false);
    }
  };

  const validateDefaultLanguage = obj => {
    const allowedLanguages = getValues("allowed_languages");
    const findVal = obj?.value || "";
    const isValid = allowedLanguages.find(
      language => language.value === findVal
    );
    if (!isValid) {
      return "Please select from allowed languages";
    }
    return true;
  };

  const {
    default_form_impression_limit = 0,
    default_banner_impression_limit = 0,
    sourceList = [],
    current_clients = []
  } = metaDataList;
  let languageList = [];
  if (metaDataList?.language) {
    languageList = metaDataList?.language.map(item => ({
      value: item.id,
      label: item.label
    }));
  }

  const getQualificationFormDefaultValue = () => {
    const allowedLang = allowed_languages;
    let result = [];
    allowedLang.forEach(element => {
      result.push({
        language: element,
        header: header?.[element] ?? "Company Benefits",
        bannerTitle: banner_title?.[element] ?? "",
        content: content?.[element] ? content?.[element].split("#") : [""]
      });
    });
    return result;
  };
  const defaultBanner = campaign_type_banner.length
    ? [...campaign_type_banner]
    : [{ language: "en", heading: "", file: "" }];
  const defaultPostApplyBanner = campaign_post_apply_banner.length
    ? [...campaign_post_apply_banner]
    : [{ language: "en", heading: "", file: "" }];
  const defaultQualificationForm = getQualificationFormDefaultValue();
  const allowedLangList = languageList.filter(item =>
    allowed_languages.includes(item.value)
  );
  let activeCampaignList = [];
  if (metaDataList?.current_campaigns) {
    activeCampaignList = metaDataList?.current_campaigns.map(item => ({
      value: item.id,
      label: item.title
    }));
  }
  let clientsList = [];
  if (current_clients.length) {
    clientsList = current_clients.map(({ id, title }) => ({
      value: id,
      label: title
    }));
  }
  const defaultExcludedCampagins = activeCampaignList.filter(
    item => excluded_campaigns?.indexOf(item.value) !== -1
  );
  const defaultClient = clientsList.find(({ value }) => value === client_id);
  const defaultLanguage = allowedLangList.find(
    ({ value }) => value === default_language
  );

  const methods = useForm({
    defaultValues: {
      dashboardBanner: defaultBanner,
      postApplyBanner: defaultPostApplyBanner,
      qualificationForm: defaultQualificationForm,
      allowed_languages: allowedLangList,
      excluded_campaigns: defaultExcludedCampagins,
      client_id: defaultClient,
      default_language: defaultLanguage
    }
  });
  const {
    handleSubmit,
    control,
    setError,
    setValue,
    getValues,
    watch,
    trigger,
    formState: { isSubmitSuccessful, errors }
  } = methods;

  let campaignGroupList = [];
  if (metaDataList?.campaignGroup) {
    campaignGroupList = metaDataList?.campaignGroup.map(item => ({
      value: item,
      label: item
    }));
  }

  // const sourceList = metaDataList?.sourceList || [];
  const today = new Date().toISOString().split("T")[0]; // Get today's date in "YYYY-MM-DD" format

  const selectedLanguageList = useWatch({
    control,
    name: "allowed_languages"
  });
  const clientRedirectionUrlVisible = useWatch({
    control,
    name: "client_redirection_url_visible",
    defaultValue: client_redirection_url_visible
  });

  const srpDashboardBannerSelected =
    useWatch({ control, name: "is_active" }) || is_active;

  const jidSelected =
    useWatch({
      control,
      name: "is_active_for_job_mapping"
    }) || is_active_for_job_mapping;

  const minEndDate =
    useWatch({
      control,
      name: "start_date"
    }) || today;

  const termsAndConditionVisible =
    useWatch({
      control,
      name: "terms_and_condition_visible"
    }) || terms_and_condition_visible;

  const { updateFormState } = useLcsFormSubmitState(state => ({
    updateFormState: state.updateFormState
  }));

  const onError = error => {
    updateFormState("ERROR");
  };

  return (
    <div className={classes.campaignContainer}>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className={campaignId ? classes.leftConatiner : null}>
          <ComponentWrappedBackdrop isVisible={!canEditCampaign} onClick={null}>
            <div className="text-center">
              <i className="fas fa-lock fa-3x"></i>
              <p className="mt-2">Cannot Edit</p>
            </div>
          </ComponentWrappedBackdrop>
          {isLoading ? (
            <div className={classes.loaderCnt}>
              <CircularProgress
                classes={{ root: classes.loaderStyles }}
                color="inherit"
              />
            </div>
          ) : null}

          <Accordion
            defaultExpanded={true}
            classes={{ root: classes.accordionClass }}
          >
            <AccordionSummary
              expandIcon={
                <img src="/images/arrow_down.svg" height={14} width={14} />
              }
              aria-controls="panel1-content"
              id="panel-header1"
            >
              <Heading heading="Campaign Details" customClass={classes.h2}>
                {campaignId ? (
                  <CampaignStatus
                    campaignData={data}
                    setStatus={setStatus}
                    status={status}
                    callBack={callBack}
                  />
                ) : null}
              </Heading>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.questioncnt}>
                <div className={classes.questionTitleCnt}>
                  <SelectDropdown
                    name={`allowed_languages`}
                    label="Allowed Languages"
                    control={control}
                    options={languageList}
                    customClass={classes.allowedLangCnt}
                    fullWidth={false}
                    idKey="value"
                    rules={{ required: "Allowed languages is required" }}
                    closeMenuOnSelect={false}
                    isMulti
                    isSearchable
                  />
                  <SelectDropdown
                    name={`default_language`}
                    label="Default Language"
                    control={control}
                    options={selectedLanguageList}
                    customClass={classes.formControlClassQueType}
                    fullWidth={false}
                    idKey="value"
                    rules={{ required: "Default language is required" }}
                    closeMenuOnSelect={false}
                    isSearchable
                    validateArray={validateDefaultLanguage}
                  />
                </div>
                <div className={classes.questionTitleCnt}>
                  <MaterialInputTextField
                    name={`title`}
                    label="Campaign Name"
                    control={control}
                    rules={{
                      required: "Campaign name is required",
                      maxLength: {
                        value: 48,
                        message: "Maximum length is 48 characters"
                      }
                    }}
                    formControlClasses={{
                      root: classes.formControlClassQue
                    }}
                    fullWidth={true}
                    defaultValue={title}
                  />
                </div>
                <div className={classes.questionTitleCnt}>
                  <MaterialSwitch
                    name={`is_active`}
                    label="Active for Dashboard/SRP"
                    control={control}
                    defaultChecked={is_active ?? true}
                    formControlClasses={{ root: classes.formSwitch }}
                    size={"small"}
                  />
                  <MaterialSwitch
                    name={`is_active_in_screening_flow`}
                    label="Active for Post Apply"
                    control={control}
                    defaultChecked={is_active_in_screening_flow ?? false}
                    formControlClasses={{ root: classes.formSwitch }}
                    size={"small"}
                  />
                  <MaterialSwitch
                    name={`is_active_for_job_mapping`}
                    label="Active for Job Level"
                    control={control}
                    defaultChecked={is_active_for_job_mapping ?? false}
                    formControlClasses={{ root: classes.formSwitch }}
                    size={"small"}
                  />
                </div>

                <div className={classes.questionTitleCnt}>
                  <MaterialInputTextField
                    name={`priority`}
                    label="Priority Order"
                    control={control}
                    rules={{
                      required: "Priority order is required",
                      pattern: {
                        value: /^[0-9]\d*(\d+)?$/i,
                        message: "Please enter a number"
                      },
                      min: {
                        value: 0,
                        message: "Value should be atleast 0"
                      },
                      max: {
                        value: 99999,
                        message: "Max value should be 99999"
                      }
                    }}
                    formControlClasses={{
                      root: classes.formControlClassQue
                    }}
                    fullWidth={false}
                    type="number"
                    defaultValue={priority}
                  />
                  <MaterialDropdown
                    name={`campaign_group`}
                    label="Campaign Group"
                    control={control}
                    rules={{ required: "Campaign group is required" }}
                    options={campaignGroupList}
                    fullWidth={false}
                    formControlClasses={{
                      root: classes.formControlClassQueType
                    }}
                    defaultValue={campaign_group}
                  />
                </div>
                <div className={classes.questionTitleCnt}>
                  <MaterialInputTextField
                    name={`description`}
                    label="Campaign Objective"
                    rules={{
                      maxLength: {
                        value: 180,
                        message: "Maximum length is 180 characters"
                      }
                    }}
                    control={control}
                    formControlClasses={{ root: classes.fullWidth }}
                    defaultValue={description}
                    fullWidth={true}
                  />
                </div>
                <CampaignFilters
                  name="city_ids"
                  label="Users Cities"
                  control={control}
                  dataList={metaDataList?.city}
                  preSelectedDataList={city_ids}
                  rules={{ required: "Cities is required" }}
                  allNaOption={[{ value: "ALL_LOCATION", label: "All" }]}
                  allNaId={"ALL_LOCATION"}
                  itemId="_id"
                  itemLabel="label"
                />
                <CampaignFilters
                  name={`allowed_categories`}
                  label="Categories"
                  control={control}
                  dataList={metaDataList?.category}
                  preSelectedDataList={allowed_categories}
                  rules={{ required: "Categories is required" }}
                  itemId="id"
                  itemLabel="title"
                />
                <CampaignFilters
                  name={`allowed_assets`}
                  label="Assets"
                  control={control}
                  dataList={metaDataList?.assets}
                  preSelectedDataList={allowed_assets}
                  allNaOption={[{ value: "All", label: "Not Applicable" }]}
                  allNaId={"All"}
                  keepAllNaDefaultValue={true}
                />
                <CampaignFilters
                  name={`allowed_skills`}
                  label="Skills"
                  control={control}
                  dataList={metaDataList?.skills}
                  preSelectedDataList={allowed_skills}
                  allNaOption={[{ value: "All", label: "Not Applicable" }]}
                  allNaId={"All"}
                  itemId="skill_id"
                  itemLabel="title"
                  keepAllNaDefaultValue={true}
                />
                <CampaignFilters
                  name={`allowed_prior_experiences`}
                  label="Prior Experiences"
                  control={control}
                  dataList={metaDataList?.priorExperience}
                  preSelectedDataList={allowed_prior_experiences}
                  allNaOption={[{ value: "All", label: "Not Applicable" }]}
                  allNaId={"All"}
                  keepAllNaDefaultValue={true}
                />
                <div className={classes.questionTitleCnt}>
                  <SelectDropdown
                    name={`excluded_campaigns`}
                    label="Excluded Campaign"
                    control={control}
                    options={activeCampaignList}
                    idKey="value"
                    closeMenuOnSelect={false}
                    isMulti
                    isSearchable
                  />
                </div>
                <div className={classes.questionTitleCnt}>
                  <SelectCreateNew
                    name={`client_id`}
                    label="Select Client"
                    control={control}
                    options={clientsList}
                    rules={{ required: "Client is required" }}
                    idKey="value"
                  />
                </div>
                <div className={classes.questionTitleCnt}>
                  <MaterialInputTextField
                    name={`form_impression_limit`}
                    label="Post Apply Impressions Allowed"
                    rules={{
                      required: "Post apply impressions limit is required",
                      pattern: {
                        value: /^[0-9]\d*(\d+)?$/i,
                        message: "Please enter a number"
                      },
                      min: {
                        value: 0,
                        message: "Value should be atleast 0"
                      },
                      max: {
                        value: 99999,
                        message: "Max value should be 99999"
                      }
                    }}
                    type="number"
                    control={control}
                    formControlClasses={{ root: classes.formControlDate }}
                    fullWidth={false}
                    defaultValue={
                      form_impression_limit
                        ? form_impression_limit
                        : default_form_impression_limit
                    }
                  />
                  <div className={classes.formControlDate}>
                    <MaterialInputTextField
                      name={`banner_impression_limit`}
                      label="Banner Impressions Limit"
                      rules={{
                        required: "Banner impressions limit is required",
                        pattern: {
                          value: /^[0-9]\d*(\d+)?$/i,
                          message: "Please enter a number"
                        },
                        min: {
                          value: 0,
                          message: "Value should be atleast 0"
                        },
                        max: {
                          value: 99999,
                          message: "Max value should be 99999"
                        }
                      }}
                      type="number"
                      control={control}
                      formControlClasses={{ root: classes.fullWidth }}
                      fullWidth={true}
                      defaultValue={
                        banner_impression_limit
                          ? banner_impression_limit
                          : default_banner_impression_limit
                      }
                    />
                  </div>
                </div>
                <div className={classes.questionTitleCnt}>
                  <div className={classes.formControlDate}>
                    <CampaignFilters
                      name={`allowed_source`}
                      label="Platform"
                      control={control}
                      dataList={sourceList}
                      preSelectedDataList={allowed_source}
                      rules={{ required: "Platform is required" }}
                      itemId="value"
                      itemLabel="label"
                    />
                  </div>
                  <div className={classes.formControlDate}>
                    <UploadImage
                      control={control}
                      customClass={classes.uploadImage}
                      label="Header Logo"
                      name="logoUrl"
                      rules={
                        !logo_url && {
                          required: "Header logo is required"
                        }
                      }
                      defaultValue={""}
                      url={logo_url || ""}
                      setError={setError}
                    />
                  </div>
                </div>

                <div className={classes.questionTitleCnt}>
                  <MaterialSwitch
                    name={`client_redirection_url_visible`}
                    label="Client Redirect URL Visible"
                    control={control}
                    defaultChecked={clientRedirectionUrlVisible}
                    formControlClasses={{ root: classes.formSwitch }}
                    size={"small"}
                  />
                  {clientRedirectionUrlVisible ? (
                    <div className={classes.formControlDate}>
                      <MaterialInputTextField
                        name={`client_redirection_url`}
                        label="Client Redirect URL"
                        rules={{
                          required: "Client Redirect URL is required"
                        }}
                        control={control}
                        formControlClasses={{ root: classes.fullWidth }}
                        fullWidth={true}
                        defaultValue={client_redirection_url}
                      />
                    </div>
                  ) : null}
                </div>
                <div className={classes.questionTitleCnt}>
                  <div className={classes.formControlDate}>
                    <MaterialSwitch
                      name={`skip_questionniare_redirect`}
                      label="Skip questionnaire"
                      control={control}
                      defaultChecked={skip_questionniare_redirect ?? false}
                      formControlClasses={{ root: classes.formSwitch }}
                      size={"small"}
                    />
                  </div>
                </div>
                <div className={classes.questionTitleCnt}>
                  <div className={classes.formControlDate}>
                    <MaterialSwitch
                      name={`is_whatsapp_campaign`}
                      label="Is Whatsapp Campaign"
                      control={control}
                      defaultChecked={is_whatsapp_campaign ?? false}
                      formControlClasses={{ root: classes.formSwitch }}
                      size={"small"}
                    />
                  </div>
                  <div className={classes.formControlDate}>
                    <MaterialSwitch
                      name={`is_conditional`}
                      label="Is Conditional"
                      control={control}
                      defaultChecked={is_conditional ?? false}
                      formControlClasses={{ root: classes.formSwitch }}
                      size={"small"}
                    />
                  </div>
                </div>
                {jidSelected ? (
                  <div className={classes.questionTitleCnt}>
                    <MaterialInputTextField
                      name={`jobIds`}
                      label="Job Ids (Add comma seperated job id's)"
                      control={control}
                      rules={{
                        required: "Job ids is required",
                        pattern: {
                          value: /^\d+(,\d+)*$/,
                          message: "Please enter comma seperated number"
                        }
                      }}
                      formControlClasses={{ root: classes.fullWidth }}
                      fullWidth={true}
                      defaultValue={jobIds}
                    />
                  </div>
                ) : null}
                <div className={classes.questionTitleCnt}>
                  <MaterialSwitch
                    name={`terms_and_condition_visible`}
                    label="Term and Condition Visible"
                    control={control}
                    defaultChecked={terms_and_condition_visible ?? false}
                    formControlClasses={{ root: classes.formSwitch }}
                    size={"small"}
                  />
                  {termsAndConditionVisible ? (
                    <div className={classes.formControlDate}>
                      <MaterialInputTextField
                        name={`terms_and_condition_text`}
                        label="Term and Condition Text"
                        rules={{
                          required: "Term and Condition text is required"
                        }}
                        control={control}
                        formControlClasses={{ root: classes.fullWidth }}
                        fullWidth={true}
                        defaultValue={terms_and_condition_text}
                      />
                    </div>
                  ) : null}
                </div>
                <div className={classes.questionTitleCnt}>
                  <MaterialInputTextField
                    name={`start_date`}
                    label="Campaign Start Date"
                    control={control}
                    formControlClasses={{ root: classes.formControlDate }}
                    rules={{ required: "Start date is required" }}
                    fullWidth={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      min: start_date || today
                    }}
                    defaultValue={start_date}
                  />
                  <MaterialInputTextField
                    name={`end_date`}
                    label="Campaign End Date"
                    control={control}
                    formControlClasses={{ root: classes.formControlDate }}
                    fullWidth={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      min: minEndDate
                    }}
                    defaultValue={end_date}
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          {selectedLanguageList.length ? (
            <>
              {srpDashboardBannerSelected ? (
                <>
                  <div className="seperator" />
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={
                        <img
                          src="/images/arrow_down.svg"
                          height={14}
                          width={14}
                        />
                      }
                      aria-controls="panel2-content"
                      id="panel-header2"
                    >
                      <Heading
                        heading="Dashboard Banner"
                        customClass={classes.h2}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <DashboardBanner
                        languageList={selectedLanguageList}
                        control={control}
                        setError={setError}
                        name="dashboardBanner"
                        watch={watch}
                      />
                    </AccordionDetails>
                  </Accordion>
                </>
              ) : null}
              <div className="seperator" />
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={
                    <img src="/images/arrow_down.svg" height={14} width={14} />
                  }
                  aria-controls="panel3-content"
                  id="panel-header3"
                >
                  <Heading
                    heading="Old Qualification Form Details"
                    customClass={classes.h2}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <PostApplyBanner
                    languageList={selectedLanguageList}
                    control={control}
                    setError={setError}
                    name="postApplyBanner"
                    watch={watch}
                  />
                </AccordionDetails>
              </Accordion>
              <div className="seperator" />
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={
                    <img src="/images/arrow_down.svg" height={14} width={14} />
                  }
                  aria-controls="panel3-content"
                  id="panel-header3"
                >
                  <Heading
                    heading="New Qualification Form Details"
                    customClass={classes.h2}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <QualificationFormV2
                    languageList={selectedLanguageList}
                    control={control}
                    setError={setError}
                    name="qualificationForm"
                    watch={watch}
                    trigger={trigger}
                  />
                </AccordionDetails>
              </Accordion>
            </>
          ) : null}
        </div>
        {campaignId ? (
          <div className={classes.rightConatiner}>
            <CampaignStatus
              campaignData={data}
              isSideBar={true}
              status={status}
              setStatus={setStatus}
              callBack={callBack}
            />
          </div>
        ) : null}
        <div className={classes.btnContainer}>
          {!isObjectEmpty(errors) ? (
            <span className="text-danger">Please fill mandatory fields</span>
          ) : (
            <>
              {message.error ? (
                <span className="text-danger">{message.error}</span>
              ) : null}
              {message.success ? (
                <span className="text-success">{message.success}</span>
              ) : null}
            </>
          )}
          <input
            type="submit"
            className="submitBtn"
            value={"Save Campaign"}
            disabled={isLoading || !canEditCampaign}
          />
        </div>
      </form>
    </div>
  );
};

export default LcsCampaignDetails;
