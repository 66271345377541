import { useEffect, useRef, useState } from "react";
import Heading from "../Common/Form/Heading";
import LcsCampaignDetails from "./lcsCampaignDetails";
import { getLcsMetdadata } from "../../services/lcsCampaign";
import LoadingSpinner from "../LoadingSpinner";
import LcsCampaignQuestions from "../lcsCampaignQuestions";
import { scrollToElementUsingRef } from "../../services/utils";
import Modal from "react-bootstrap/Modal";
import useLcsCampaignEditPermission from "../../zustandService/useLcsCampaignEditPermission";

function handleBeforeUnload(e) {
  e.preventDefault();
  e.returnValue = "";
}

const CampaignCreation = props => {
  const { campaignId = "", data = {}, callBack } = props;
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [lcsMetaDataList, setMetdaDataList] = useState({});
  const { updateEditPermission } = useLcsCampaignEditPermission(state => ({
    updateEditPermission: state.updateEditPermission
  }));
  const [showDuplicateCampaignEdu, setShowDuplicateCampaignEdu] =
    useState(false);
  const { campaign_status: campaignStatus = "" } = data;
  const queryParams = new URLSearchParams(window.location.search);
  const duplicateCampaignId = queryParams.get("duplicateCampaignId");
  const questionsRef = useRef(null);

  useEffect(() => {
    if (campaignStatus === "ACTIVE" && !duplicateCampaignId) {
      updateEditPermission(false);
    }
    getLcsMetdadata()
      .then(data => {
        setMetdaDataList(data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(error);
      });
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload, false);
    };
  }, [duplicateCampaignId]);
  const removeBeforeUnloadHandler = () => {
    window.removeEventListener("beforeunload", handleBeforeUnload, false);
  };

  useEffect(() => {
    if (!isLoading && duplicateCampaignId && campaignId) {
      setShowDuplicateCampaignEdu(true);
    }
  }, [isLoading]);

  const handleDuplicateEduClose = () => {
    setShowDuplicateCampaignEdu(false);
    scrollToElementUsingRef(questionsRef);
  };

  return (
    <div className="company-details">
      <Modal show={showDuplicateCampaignEdu} onHide={handleDuplicateEduClose}>
        <Modal.Body>
          <div class="alert alert-success" role="alert">
            The campaign details have been successfully saved.
          </div>
          <div class="my-4"></div>
          <b>Remember to save the questionnaire to finish.</b>
          <div className="clr" />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={handleDuplicateEduClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {errorMessage ? (
        <span className="text-danger">{errorMessage}</span>
      ) : null}
      <div className="gap-20" />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <LcsCampaignDetails
            metaDataList={lcsMetaDataList}
            data={data}
            campaignId={campaignId}
            callBack={callBack}
            removeBeforeUnloadHandler={removeBeforeUnloadHandler}
          />
          <div className="gap-20" />
          {campaignId ? (
            <div ref={questionsRef}>
              <div className="seperator" />
              <Heading heading="Add Questions & Options" />
              <LcsCampaignQuestions
                campaignData={data}
                allLanguages={lcsMetaDataList.language}
                removeBeforeUnloadHandler={removeBeforeUnloadHandler}
              />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default CampaignCreation;
