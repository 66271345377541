import React from "react";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import CustomePagination from "../Common/Pagination";
import { CREATE_CAMPAIGN, EDIT_CAMPAIGN } from "../../routes/RouteConstants";

const CampaignList = props => {
  const {
    dataList = [],
    activePage,
    itemsCountPerPage,
    totalCount,
    onPageChange
  } = props;

  function onDuplicateCampaignClick(e) {
    if (
      !window.confirm(
        "Are you sure you want to duplicate the campaign? Please confirm."
      )
    ) {
      e.preventDefault();
    }
  }

  const columns = [
    {
      text: "Id",
      dataField: "campaign_id",
      sort: true
    },
    {
      text: "Campaign Name",
      dataField: "title",
      sort: true
    },
    {
      text: "Campaign Type",
      dataField: "campaign_type",
      sort: true
    },
    {
      text: "Campaign Group",
      dataField: "campaign_group",
      sort: true
    },
    // {
    //   text: "Created By",
    //   dataField: "created_by",
    //   sort: true
    // },
    {
      text: "Start Date",
      dataField: "start_date",
      sort: true,
      formatter: (cellContent, row) => {
        return moment(row.start_date).format("DD-MM-YYYY");
      }
    },
    {
      text: "End Date",
      dataField: "end_date",
      sort: true,
      formatter: (cellContent, row) => {
        if (!row.end_date) return null;
        return moment(row.end_date).format("DD-MM-YYYY");
      }
    },
    {
      text: "Priority Order",
      dataField: "priority_order",
      sort: true
    },
    {
      text: "Status",
      dataField: "campaign_status",
      sort: true,
      formatter: (cellContent, row) => {
        const status = row?.campaign_status;
        const circleClass =
          status === "ACTIVE" || status === "TEST" ? "greenText" : "redText";
        return (
          <span className={circleClass}>
            {status === "TEST" ? "ACTIVE FOR TESTING" : status}
          </span>
        );
      }
    },
    {
      text: "#",
      dataField: "a",
      formatter: (cellContent, row) => {
        const campaignId = row?.campaign_id;
        return (
          <div className="campaignAction">
            <a
              href={`${EDIT_CAMPAIGN}/${campaignId}`}
              target="_blank"
              rel="noopener noreferrer"
              title="Edit campaign"
            >
              <i className="fas fa-pencil-alt" />
            </a>
            <a
              onClick={onDuplicateCampaignClick}
              href={`${CREATE_CAMPAIGN}?duplicateCampaignId=${campaignId}`}
              target="_blank"
              rel="noopener noreferrer"
              title="Duplicate campaign"
            >
              <i className="fa-solid fa-copy" />
            </a>
          </div>
        );
      }
    }
  ];
  const NoDataIndication = () => <div className="spinner">No Data Found</div>;
  return (
    <>
      <BootstrapTable
        keyField="campaign_id"
        data={dataList}
        columns={columns}
        hover
        condensed
        bordered={false}
        loading={true}
        rowClasses="listvalue"
        headerClasses="listHeding"
        classes="borderless"
        noDataIndication={() => <NoDataIndication />}
      />
      <CustomePagination
        activePage={activePage}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalCount}
        onPageChange={onPageChange}
        showPagination={totalCount > 0}
      />
    </>
  );
};

export default CampaignList;
