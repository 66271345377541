import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import JobHai from "../../../api/JobHai";
import PopupMessage from "../../PopupMessage";
import { getErrorMessage, checkPermission } from "../../../services/utils";
import { TOOLS } from "../../../routes/RouteConstants";
import LoadingSpinner from "../../LoadingSpinner";
import CustomePagination from "../../Common/Pagination";
import { LEARN_TAB } from "../../../routes/RouteConstants";
import EditLink from "../../Common/EditLink";
import PlayVideo from "../../Common/PlayVideo";
import NoAccess from "../../Common/NoAccess";

class LearnTabVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMsg: "",
      msgData: { msg: "", showMsg: false, msgType: "" },
      isLoading: true,
      videoList: [],
      itemsCountPerPage: 30,
      activePage: 1,
      count: 0
    };
  }

  async componentDidMount() {
    this.getVideo();
  }

  componentDidUpdate(prevProps, prevState) {
    const { activePage } = this.state;
    if (activePage !== prevState.activePage) {
      this.getVideo();
      window.scroll({ top: 0, behavior: "smooth" });
    }
  }

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
  };

  getVideo() {
    const { activePage, itemsCountPerPage } = this.state;
    JobHai.post(`/get-all-learn-data`, {
      page: activePage - 1,
      size: itemsCountPerPage
    })
      .then(response => {
        let data = response.data.data;
        const { video_info = [], count = 0 } = data;
        this.setState({
          videoList: video_info,
          count: count,
          isLoading: false,
          msgData: { msg: "", showMsg: false, msgType: "error" }
        });
      })
      .catch(error => {
        let msg = getErrorMessage(error);
        this.setState({
          isLoading: false,
          msgData: { msg: msg, showMsg: true, msgType: "error" }
        });
        window.scrollTo(0, 0);
      });
  }

  handleClosePopup = () => {
    this.setState({ msgData: [{ msg: "", showMsg: false }] });
  };

  render() {
    const {
      msgData,
      isLoading,
      videoList,
      activePage,
      count,
      itemsCountPerPage
    } = this.state;
    const isAccess = checkPermission("LearnTabVideo");
    videoList.map(d => {
      const {
        media_url = "",
        is_vertical,
        is_active,
        tags = [],
        categories = {},
        thumbnail = "",
        content_id,
        highlight_content_rank
      } = d;
      d.isVertical = is_vertical ? "Yes" : "No";
      d.isActive = is_active ? "Yes" : "No";
      d.contentRank =
        highlight_content_rank === 99999 ? "" : highlight_content_rank;
      d.tagsList = tags
        .map(function (val) {
          return val.label;
        })
        .join(", ");
      d.catList = categories
        .map(function (val) {
          return val.label;
        })
        .join(", ");
      d.edit_link = (
        <>
          {media_url ? <PlayVideo video_id={media_url} /> : null}{" "}
          {thumbnail ? (
            <a
              href={thumbnail}
              title={"View Thumbnail Image"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa-solid fa-image" />
            </a>
          ) : null}
          <EditLink url={`${LEARN_TAB}/${content_id}`} id={content_id} />
        </>
      );
      return d;
    });
    const columns = [
      {
        text: "ID",
        dataField: "content_id",
        sort: true
      },
      {
        text: "Media URL",
        dataField: "media_url"
      },
      {
        text: "Active",
        dataField: "isActive",
        sort: true
      },
      {
        text: "Vertical",
        dataField: "isVertical",
        sort: true
      },
      {
        text: "Likes",
        dataField: "likes",
        sort: true
      },
      {
        text: "views",
        dataField: "views",
        sort: true
      },

      {
        text: "Content Title",
        dataField: "label",
        sort: true
      },
      {
        text: "Tags",
        dataField: "tagsList"
      },
      {
        text: "Blue/Grey Flag",
        dataField: "blue_grey_flag"
      },
      {
        text: "#",
        dataField: "edit_link"
      }
    ];
    const NoDataIndication = () => <div className="spinner">No Data Found</div>;

    if (isLoading && isAccess) {
      return <LoadingSpinner key="loading" />;
    } else {
      return (
        <div className="job_seeker">
          <div className="gap-20" />
          <PopupMessage data={msgData} click={this.handleClosePopup} />
          <div className="pageHeading row">
            <h2>
              Learn Tab Video
              <div className="float-right">
                <a href={`${LEARN_TAB}/tag-category`}>Tag Category Mapping</a>
                <span className="sperator">|</span>
                <a href={`${LEARN_TAB}/add`}>
                  <i className="fa fa-plus" aria-hidden="true"></i> Add New
                  Video
                </a>
                <span className="sperator">|</span>
                <a href={TOOLS}>Go Back</a>
              </div>
            </h2>
          </div>
          <div className="gap-20" />
          <div className="whitebox video-list">
            {isAccess ? (
              <>
                <BootstrapTable
                  keyField="content_id"
                  data={videoList}
                  columns={columns}
                  hover
                  condensed
                  bordered={false}
                  loading={true}
                  rowClasses="listvalue"
                  headerClasses="listHeding"
                  classes="borderless"
                  noDataIndication={() => <NoDataIndication />}
                />
                <CustomePagination
                  activePage={activePage}
                  itemsCountPerPage={itemsCountPerPage}
                  totalItemsCount={count}
                  onPageChange={this.handlePageChange}
                  showPagination={count > 0 ? true : false}
                />
                <div className="clr gap-20"></div>
              </>
            ) : (
              <NoAccess />
            )}
          </div>
        </div>
      );
    }
  }
}
export default LearnTabVideo;
