import React, { useState } from "react";
import { withStyles } from "@material-ui/styles";
import { styles, initiateOrder, generateLink } from "../CreditWidget/helper";
import {
  getErrorMessage,
  getFormattedValue,
  linkGeneratorUtils,
  validityMapper
} from "../../services/utils";

import ConfirmationCTA from "./ConfirmationCTA";
import AfterSuccessCTA from "./AfterSuccessCTA";
import LinkGenerationForm from "./LinkGenerationForm";

const STATES = {
  FETCHING: "FETCHING",
  FETCHED: "FETCHED",
  ERROR: "ERROR",
  PRELIMINARY: "PRELIMINARY",
  FINAL: "FINAL",
  SUCCESS: "SUCCESS"
};

function LinkGenerate({ classes, recId, admin, showTitle = true }) {
  const [currentState, setCurrentState] = useState(STATES.PRELIMINARY);
  const [fieldItems, setFieldItems] = useState({
    jobs: "",
    basicPrice: "",
    validity: ""
  });
  const [postingState, setPostingState] = useState(STATES.FETCHED);
  const [errorMessage, setErrorMessage] = useState("");
  const [copySuccess, setCopySuccess] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [flow, setCurrentFlow] = useState("link");

  const { jobs, basicPrice, validity } = fieldItems;

  async function onSave(st, action) {
    setErrorMessage("");
    if (action === "createOrder") {
      setCurrentFlow("createOrder");
    } else {
      setCurrentFlow("link");
    }
    if (st === STATES.PRELIMINARY) {
      if (handleFormValidation()) {
        return;
      }
      setCurrentState(STATES.FINAL);
    }
    if (st === STATES.FINAL) {
      setPostingState(STATES.FETCHING);
      const obj = {
        noOfJobs: fieldItems.jobs,
        sellingPrice: fieldItems.basicPrice,
        validity: fieldItems.validity,
        recruiterId: recId
      };
      try {
        if (flow === "createOrder") {
          const res = await initiateOrder(obj);
          const { orderId = "" } = res;
          setPostingState(STATES.SUCCESS);
          setOrderId(orderId);
          return;
        }
        const res = await generateLink(obj);
        const { url = "" } = res;
        setPostingState(STATES.SUCCESS);
        setOrderId(url);
      } catch (error) {
        const err = getErrorMessage(error);
        setPostingState(`${STATES.ERROR}:${err}`);
      }
    }
  }
  function onCancel() {
    setCurrentState(STATES.PRELIMINARY);
    setPostingState(STATES.FETCHED);
    resetFieldItems();
  }

  function resetFieldItems() {
    setFieldItems({ jobs: "", basicPrice: "", validity: "" });
    setCurrentFlow("link");
  }

  function copyOrderId() {
    try {
      navigator.clipboard.writeText(orderId);
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  }

  function handleChange({ target: { name, value } }) {
    if (name === "jobs") {
      value = getFormattedValue(value, true, true);
    }
    if (name === "basicPrice") {
      value = getFormattedValue(value, true);
    } else {
      value = getFormattedValue(value);
    }
    setFieldItems(prevValues => ({ ...prevValues, [name]: value }));
  }

  function handleFormValidation() {
    return false;
  }
  return (
    <>
      <div className={showTitle ? "separate" : null}>
        {showTitle && <div className="heading">Payment Link Generator</div>}
        {currentState === STATES.PRELIMINARY ? (
          <LinkGenerationForm
            fieldItems={fieldItems}
            onChange={handleChange}
            errorMessage={errorMessage}
            onSave={onSave}
            admin={admin}
          />
        ) : (
          <>
            {`${flow === "link" ? `Payment Link` : `Order Id`} 
         
            for ${
              linkGeneratorUtils({ basicPrice, jobs }).credits
            } credits(good for ${jobs}  Premium job${
              jobs > 1 ? `s` : ``
            }) @INR ${basicPrice}(${(function () {
              return linkGeneratorUtils({ basicPrice, jobs }).pricePerJob;
            })()}/Job) +taxes with validity of ${validityMapper(
              validity
            )} will be generated
            
            `}

            {postingState === STATES.SUCCESS ? (
              <div className={classes.successState}>
                {flow === "link" ? `Link: ` : `OrderId: `}
                <span>
                  {orderId}
                  <i className="fa-solid fa-copy" onClick={copyOrderId} />
                </span>
                {copySuccess ? (
                  <span className="text-success"> Copied </span>
                ) : null}
              </div>
            ) : null}
            {postingState.includes(STATES.ERROR) ? (
              <div className={classes.errorState}>
                <i className="fa fa-x" aria-hidden="true" />
                <span>
                  &nbsp; {postingState?.split(":")?.[1] || "Unable to save"}
                </span>
              </div>
            ) : null}
            {postingState === STATES.SUCCESS ? (
              <AfterSuccessCTA
                onCancel={() => onCancel()}
                label={`${
                  flow === "link"
                    ? `Create another link`
                    : `Create another order`
                }`}
              />
            ) : (
              <ConfirmationCTA
                disabled={postingState === STATES.FETCHING}
                onSave={() => onSave(STATES.FINAL, flow)}
                onCancel={() => onCancel()}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default withStyles(styles)(React.memo(LinkGenerate));
